<template>
  <div class="message5GPlatform">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>5G消息平台</span>
          <p>
            基于RCS
            UP国际标准建设的消息能力增强开放平台，平台适配移动、联通、电信三网运营商5G消息能力；提供Chatbot接入和管理服务；对接5G消息系统，提供API接口快速接入实现消息下发。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/5GMessagePlatform/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <div class="part2_main">
        <span class="part-title">5G消息平台核心</span>
        <div class="p2m-major">
          <div class="p2m-major-left">
            <div
              class="p2m-major-item reveal-bottom active"
              @mousemove="p2Move($event)"
            >
              <div class="item-img">
                <img
                  src="../../assets/img/product/5GMessagePlatform/part2-icon1.png"
                  alt=""
                />
              </div>
              <div class="item-div">
                <div>
                  <span>Chatbot接入管理</span>
                  <p>
                    高效创建Chatbot，快速接入白名单管理体系，免去Native鉴权方式；开放多层级人员管理权限，高效协同管理。
                  </p>
                </div>
              </div>
            </div>
            <div
              class="p2m-major-item reveal-bottom"
              @mousemove="p2Move($event)"
            >
              <div class="item-img">
                <img
                  src="../../assets/img/product/5GMessagePlatform/part2-icon2.png"
                  alt=""
                />
              </div>
              <div class="item-div">
                <div>
                  <span>消息接入</span>
                  <p>
                    多维度消息控制，支持多种消息逐级回落，支持内容存储与垃圾消息处理。
                  </p>
                </div>
              </div>
            </div>
            <div
              class="p2m-major-item reveal-bottom"
              @mousemove="p2Move($event)"
            >
              <div class="item-img">
                <img
                  src="../../assets/img/product/5GMessagePlatform/part2-icon3.png"
                  alt=""
                />
              </div>
              <div class="item-div">
                <div>
                  <span>API接入</span>
                  <p>API接口快速接入，提供高并发消息下发通道，直达终端。</p>
                </div>
              </div>
            </div>
          </div>
          <img
            class="reveal-bottom p2m-majorImg"
            src="../../assets/img/product/5GMessagePlatform/part2-right-bg.png"
            alt=""
          />
        </div>
      </div>
    </section>
    <section class="part3">
      <span class="part-title">5G消息平台功能</span>
      <div class="part3_main reveal-bottom2">
        <div
          class="part3-item"
          v-for="(item, index) in part3Data"
          :key="index"
          @mousemove="part3Item(index, item.imgOver)"
          @mouseout="part3Item(index, item.img)"
        >
          <div>
            <img :src="item.img" alt="" />
          </div>
          <span v-html="item.title"></span>
          <p>{{ item.content }}</p>
        </div>
      </div>
      <div class="part3_main p3-swiper messagePlatform5GP3-swiper">
        <swiper :options="swiperOption">
          <swiper-slide
            v-for="(item, i) in part3Datas"
            :key="i"
            class="swiper-item"
          >
            <div
              class="part3-item"
              v-for="(item2, i2) in item"
              :key="i2"
              @click="swiperP3Item($event, item2)"
            >
              <div>
                <img :src="item2.img" alt="" />
              </div>
              <span v-html="item2.title"></span>
              <p>{{ item2.content }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-pagination p3m-pagination swiper_bg_blue"
          slot="pagination"
        ></div>
      </div>
    </section>
    <section class="part4">
      <span class="part-title">5G消息平台特殊优势</span>
      <div class="part4_main reveal-bottom2">
        <div class="part4-item" v-for="(item, index) in part4Data" :key="index">
          <div>
            <img :src="item.img" alt="" />
          </div>
          <p>{{ item.content }}</p>
        </div>
      </div>
      <div class="part4_main messagePlatform5GP4-swiper">
        <el-carousel
          trigger="click"
          :interval="5000"
          arrow="always"
          ref="p4m2Carousel"
        >
          <el-carousel-item
            class="part4-item"
            v-for="(item, index) in part4Data"
            :key="index"
          >
            <div>
              <img :src="item.img" alt="" />
            </div>
            <p>{{ item.content }}</p>
          </el-carousel-item>
        </el-carousel>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      part3Data: [
        {
          img: require('../../assets/img/product/5GMessagePlatform/part3-icon1.png'),
          imgOver: require('../../assets/img/product/5GMessagePlatform/part3-icon1-over.png'),
          title: '素材库<br>海量素材管理',
          content:
            '图片、音频、视频等文件一键上传保存至素材库，可进行重新命名、添加分组等，方便查看管理',
        },
        {
          img: require('../../assets/img/product/5GMessagePlatform/part3-icon2.png'),
          imgOver: require('../../assets/img/product/5GMessagePlatform/part3-icon2-over.png'),
          title: '消息模板<br>快速搭建5G消息',
          content:
            '从素材库中选取所需素材，组合卡片、文本、图片、音频或视频消息，设置底部菜单和悬浮菜单，创建便捷式入口',
        },
        {
          img: require('../../assets/img/product/5GMessagePlatform/part3-icon3.png'),
          imgOver: require('../../assets/img/product/5GMessagePlatform/part3-icon3-over.png'),
          title: '卡片内容自定义<br>自主调整卡片样式',
          content:
            '卡片的布局、高度、顺序、颜色等均可按需调整；卡片可复制与粘贴，避免反复操作',
        },
        {
          img: require('../../assets/img/product/5GMessagePlatform/part3-icon4.png'),
          imgOver: require('../../assets/img/product/5GMessagePlatform/part3-icon4-over.png'),
          title: '一个按钮<br>多样交互',
          content:
            '卡片按钮可添加拨打电话、跳转网页、跳转App、打开地图、创建日程、发送建议消息等动作，实现多维度交互操作。',
        },
      ],
      part3Datas: [],
      part4Data: [
        {
          img: require('../../assets/img/product/5GMessagePlatform/part4-icon1.png'),
          content:
            '整合5G消息基础业务，汇聚多种场景需求，助力行业用户快速接入5G消息。',
        },
        {
          img: require('../../assets/img/product/5GMessagePlatform/part4-icon2.png'),
          content:
            '集成移动、联通、电信三网5G消息能力，可无缝切换至运营商商用环境。',
        },
        {
          img: require('../../assets/img/product/5GMessagePlatform/part4-icon3.png'),
          content: '消息能力与交互通道统一标准规范，保证信息高质量传递。',
        },
      ],
      // 轮播图参数
      swiperOption: {
        pagination: {
          el: '.p3-swiper .p3m-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
    };
  },
  mounted() {
    // 内容初始化
    this.part3Datas = [
      JSON.parse(JSON.stringify(this.part3Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.part3Data.slice(-2))),
    ];
  },
  methods: {
    part3Item(index, img) {
      $('.part3-item').eq(index).find('img').attr('src', img);
    },
    // 移动端
    swiperP3Item(e, item) {
      $('.p3-swiper .part3-item').each((index, item2) => {
        this.part3Data.find((item3) => {
          if (item3.content == $(item2).children('p').text()) {
            $(item2).find('img').attr('src', item3.img);
          }
        });
      });
      $('.p3-swiper .part3-item').removeClass('active');
      $(e.currentTarget).addClass('active');
      $(e.currentTarget).find('img').attr('src', item.imgOver);
    },
    p2Move(e) {
      $('.p2m-major-item').removeClass('active');
      $(e.currentTarget).addClass('active');
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 734px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      position: absolute;
      width: 570px;
      height: 722px;
      z-index: -1;
      top: 28px;
      right: -82px;
    }
  }
  .part1-title {
    width: 526px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 160px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
/* 第二个部分 */
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 817px;
  margin-bottom: 48px;
  background: #ffffff;
  .part2_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 60px;
    span {
      margin-bottom: 107px;
    }
    .p2m-major {
      width: 1100px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .p2m-major > img {
        width: 446px;
        height: 580px;
        margin-top: 35px;
        margin-right: -21px;
      }
      .p2m-major-left {
        width: 570px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .p2m-major-item {
          box-sizing: border-box;
          width: 100%;
          height: 178px;
          display: flex;
          flex-direction: row;
          padding: 26px 0px;
          padding-left: 25px;
          .item-img {
            box-sizing: border-box;
            width: 70px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-right: 24px;
          }
          .item-div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }
          span {
            display: block;
            font-family: FontExcellent;
            font-size: 20px;
            color: #333333;
            margin-bottom: 7px;
          }
          p {
            display: block;
            width: 413px;
            font-size: 16px;
            color: #666666;
            line-height: 27px;
            text-align: justify;
          }
        }
        .p2m-major-item.active {
          background: url('../../assets/img/product/5GMessagePlatform/part2-item-bg.png')
            no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
/* 第三个部分 */
.part3 {
  box-sizing: border-box;
  width: 100%;
  height: 768px;
  background: url('../../assets/img/product/5GMessagePlatform/part3-bg.png')
    no-repeat;
  background-size: 100% 100%;
  padding-top: 112px;
  .part-title {
    color: white;
    margin-bottom: 92px;
  }
  .part3_main {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .part3-item {
      box-sizing: border-box;
      width: 250px;
      height: 439px;
      background: #ffffff;
      box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 52px;
      div {
        width: 100%;
        height: 103px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 21px;
      }
      span {
        font-size: 18px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #333333;
        margin-bottom: 14px;
        text-align: center;
        line-height: 32px;
      }
      p {
        display: block;
        width: 176px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        text-align: justify;
        line-height: 25px;
      }
    }
    .part3-item:hover {
      background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
      border: 2px solid #ffffff;
      box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
      span,
      p {
        color: #ffffff;
      }
    }
  }
  .part3_main:nth-of-type(2) {
    display: none;
  }
}
/* 第四个部分 */
.part4 {
  box-sizing: border-box;
  width: 100%;
  height: 692px;
  padding-top: 67px;
  .part-title {
    margin-bottom: 70px;
  }
  .part4_main {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .part4-item {
      box-sizing: border-box;
      width: 260px;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 0px;
      div {
        width: 100%;
        height: 212px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 78px;
      }
      p {
        display: block;
        width: 208px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 27px;
        text-align: justify;
      }
    }
  }
  .part4_main:nth-of-type(2) {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  /* 第一部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        content: url('../../assets/img/product/5GMessagePlatform/mobile/part1-icon.png');
        width: 3.23rem;
        height: 5.21rem;
        top: 0rem;
        right: -0.01rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
        margin-top: -0.62rem;
      }
      p,
      a {
        display: none;
      }
    }
  }
  /* 第二个部分 */
  .part2 {
    height: 6.73rem;
    background: #ffffff;
    margin-bottom: 0.15rem;
    .part2_main {
      width: 5.7rem;
      padding-top: 0.32rem;
      > span {
        margin-bottom: 0.38rem;
      }
      .p2m-major {
        width: 100%;
        .p2m-majorImg {
          display: none;
        }
        .p2m-major-left {
          width: 100%;
          .p2m-major-item {
            height: 1.78rem;
            padding: 0.3rem 0px;
            padding-left: 0.28rem;
            .item-img {
              width: 0.7rem;
              margin-right: 0.26rem;
              img {
                width: 100%;
              }
            }
            span {
              font-size: 0.2rem;
              font-weight: 400;
              margin-bottom: 0.05rem;
            }
            p {
              width: 4.13rem;
              font-size: 0.16rem;
              font-weight: 400;
              line-height: 0.24rem;
            }
          }
        }
      }
    }
  }
  /* 第三个部分 */
  .part3 {
    height: 7.15rem;
    padding-top: 0.56rem;
    background: url('../../assets/img/product/whatsAppBusiness/mobile/part3-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 0.58rem;
    }
    .part3_main {
      width: 5.68rem;
      .swiper-item {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 0.2rem;
      }
      .part3-item {
        width: 2.5rem;
        height: 4.39rem;
        box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.1);
        border-radius: 0.1rem;
        padding-top: 0.52rem;
        div {
          height: 1.03rem;
          margin-bottom: 0.23rem;
          img {
            max-height: 100%;
          }
        }
        span {
          font-size: 0.18rem;
          line-height: 0.3rem;
          margin-bottom: 0.15rem;
        }
        p {
          width: 1.76rem;
          font-size: 0.16rem;
          line-height: 0.25rem;
        }
      }
      .part3-item:hover {
        background: #ffffff;
        border: 0rem solid #ffffff;
        box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0);
        span,
        p {
          color: #333333;
        }
      }
      .part3-item.active {
        background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
        border: 0.02rem solid #ffffff;
        box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.1);
        span,
        p {
          color: #ffffff;
        }
      }
    }
    .part3_main:nth-of-type(1) {
      display: none;
    }
    .part3_main:nth-of-type(2) {
      display: block;
    }
  }
  /* 第四个部分 */
  .part4 {
    height: 5.53rem;
    padding-top: 0.62rem;
    .part-title {
      margin-bottom: 0.28rem;
    }
    .part4_main {
      width: 6.5rem;
      .part4-item {
        width: 3.94rem;
        height: 3.6rem;
        padding-top: 0.23rem;
        margin-left: 1.28rem;
        div {
          width: 100%;
          height: 2.04rem;
          margin-bottom: 0.52rem;
          img {
            height: 100%;
          }
        }
        span {
          font-size: 0.24rem;
          margin-bottom: 0.23rem;
          color: #333333;
        }
        p {
          width: 2.04rem;
          font-size: 0.16rem;
          line-height: 0.28rem;
        }
      }
    }
    .part4_main:nth-of-type(1) {
      display: none;
    }
    .part4_main:nth-of-type(2) {
      display: block;
    }
  }
}
</style>
<style lang="less">
// 轮播图
// p3
.messagePlatform5GP3-swiper .swiper_bg_blue {
  width: 5.68rem;
  margin-top: 0.62rem;
}
.messagePlatform5GP4-swiper {
  .el-carousel__container {
    height: 3.6rem;
    .el-carousel__arrow {
      width: 0.58rem;
      height: 0.58rem;
      background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
    }
    .el-icon-arrow-right::before,
    .el-icon-arrow-left::before {
      font-size: 0.3rem;
    }
  }
}
</style>
