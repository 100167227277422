<template>
  <div class="voice">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>95语音</span>
          <p>
            95码号是直属于工信部的特殊码号，具有全国唯一性，没有区域、城市限制，三网合一的全国统一热线。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/voice/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <div class="part2_main">
        <span class="part-title">95语音功能介绍</span>
        <p class="reveal-bottom">
          为商家企业实现全国或本地统一号码接入，并实现IVR自动语音服务、智能话务分配、语音信箱及公共座席服务，快速搭建24小时客服中心，百悟科技可提供云总机，云呼叫中心，自建呼叫中心等多种接入方式，打造企业服务品牌，全面提升服务形象。
        </p>
        <div class="p2m_bottom reveal-bottom2 p2m_pc">
          <div class="p2m_item">
            <img src="../../assets/img/product/voice/part2-icon1.png" alt="" />
            <p>语音服务</p>
          </div>
          <div class="p2m_item">
            <img src="../../assets/img/product/voice/part2-icon2.png" alt="" />
            <p>智能话务分配</p>
          </div>
          <div class="p2m_item">
            <img src="../../assets/img/product/voice/part2-icon3.png" alt="" />
            <p>语音信箱</p>
          </div>
          <div class="p2m_item">
            <img src="../../assets/img/product/voice/part2-icon4.png" alt="" />
            <p>公共坐席服务</p>
          </div>
        </div>
        <div class="p2m_bottom reveal-bottom2 p2m_mobile">
          <swiper :options="swiperOption">
            <swiper-slide class="swiper-item">
              <div class="p2m_item">
                <img
                  src="../../assets/img/product/voice/part2-icon1.png"
                  alt=""
                />
                <p>语音服务</p>
              </div>
              <div class="p2m_item">
                <img
                  src="../../assets/img/product/voice/part2-icon2.png"
                  alt=""
                />
                <p>智能话务分配</p>
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-item">
              <div class="p2m_item">
                <img
                  src="../../assets/img/product/voice/part2-icon3.png"
                  alt=""
                />
                <p>语音信箱</p>
              </div>
              <div class="p2m_item">
                <img
                  src="../../assets/img/product/voice/part2-icon4.png"
                  alt=""
                />
                <p>公共坐席服务</p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>
    <section class="part3">
      <div class="part3_main">
        <span class="part-title">组网架构</span>
        <img
          class="reveal-bottom2"
          src="../../assets/img/product/voice/part3-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part4">
      <div class="part4_main">
        <span class="part-title">特殊优势</span>
        <p class="reveal-bottom">
          通过百悟云平台进行话务的统一调度、统一管理、达到保障话务质量的前提下，节省成本
        </p>
        <p class="reveal-bottom2">支持私有化平台部署</p>
        <p class="reveal-bottom3">支持双平台部署，全容灾架部署</p>
        <div class="p4m-major p4m_pc">
          <div class="reveal-bottom2">
            <img src="../../assets/img/product/voice/part4-icon1.png" alt="" />
            <span>落地经验丰富</span>
            <p>
              多年落地经验，提供教科书式落地全包服务，确保按时按量完成落地任务。
            </p>
          </div>
          <div class="reveal-bottom2">
            <img src="../../assets/img/product/voice/part4-icon2.png" alt="" />
            <span>落地资源充足</span>
            <p>
              丰富落地资源选择，百悟科技提供优势落地局运营商，保障全流程95服务。
            </p>
          </div>
          <div class="reveal-bottom2">
            <img src="../../assets/img/product/voice/part4-icon3.png" alt="" />
            <span>语音团队专业</span>
            <p>
              配置专门的市场、客服、技术人员进行服务。7*24业务服务，保障业务持续稳定。
            </p>
          </div>
          <div class="reveal-bottom3">
            <img src="../../assets/img/product/voice/part4-icon4.png" alt="" />
            <span>运营商全国保障</span>
            <p>
              运营商问题全国无盲区覆盖处理，支持故障快速排除，地市运营商直线沟通，提升问题处理效率。
            </p>
          </div>
          <div class="reveal-bottom3">
            <img src="../../assets/img/product/voice/part4-icon5.png" alt="" />
            <span>定制化组网</span>
            <p>
              百悟科技提供灵活组网方案，为企业量身定制配套对接方案；避免单一组网方式出现的信息安全性问题
            </p>
          </div>
          <div class="reveal-bottom3">
            <img src="../../assets/img/product/voice/part4-icon6.png" alt="" />
            <span>增值服务完善</span>
            <p>
              百悟科技提供号码年审、续期、变更、投诉、骚扰标记等一体化解决方案。
            </p>
          </div>
        </div>
        <div class="p4m-major2 p4m_mobile">
          <swiper :options="swiperOption2">
            <swiper-slide
              class="swiper-item"
              v-for="(item, i) in p4Data"
              :key="i"
            >
              <div class="p4m2_item">
                <div>
                  <img :src="item.img" alt="" />
                </div>
                <span v-text="item.span"></span>
                <p v-text="item.p"></p>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-pagination swiper_bg_blue" slot="pagination"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Voice',
  data() {
    return {
      // 轮播图参数
      swiperOption: {
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      // p4
      p4Data: [
        {
          img: require('../../assets/img/product/voice/part4-icon1.png'),
          span: '落地经验丰富',
          p: '多年落地经验，提供教科书式落地全包，确保按时按量完成落地任务。',
        },
        {
          img: require('../../assets/img/product/voice/part4-icon2.png'),
          span: '落地资源充足',
          p: '丰富落地资源选择，百悟科技提供优势落地局运营商，保障全流程95服务。',
        },
        {
          img: require('../../assets/img/product/voice/part4-icon3.png'),
          span: '语音团队专业',
          p: '配置专门的市场，客服，技术人员进行服务。7*24业务服务，保障业务持续稳定性。',
        },
        {
          img: require('../../assets/img/product/voice/part4-icon4.png'),
          span: '运营商全国保障',
          p: '运营商问题全国无盲区覆盖处理，支持故障快速排除，地市运营商直线沟通，提升问题处理效率。',
        },
        {
          img: require('../../assets/img/product/voice/part4-icon5.png'),
          span: '定制化组网',
          p: '百悟科技提供灵活组网方案，为企业量身定制配套对接方案；避免单一组网方式出现的信息安全性问题。',
        },
        {
          img: require('../../assets/img/product/voice/part4-icon6.png'),
          span: '增值服务完善',
          p: '百悟科技提供号码年审、续期、变更、投诉、骚扰标记等一体化解决方案。',
        },
      ],
      // 轮播图参数
      swiperOption2: {
        pagination: {
          el: '.part4 .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
    };
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
/* 头图样式 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      position: absolute;
      top: 57px;
      left: 455px;
      z-index: -1;
      width: 773px;
      height: 640px;
    }
    .part1-title {
      width: 480px;
      height: 280px;
      span {
        font-family: FontExcellent;
        font-size: 40px;
        color: #333333;
        display: block;
        margin-bottom: 22px;
        letter-spacing: 1px;
      }
      p {
        font-size: 16px;
        color: #666666;
        text-align: justify;
        margin-bottom: 78px;
        line-height: 28px;
      }
      a {
        display: block;
        width: 184px;
        height: 53px;
        background-color: rgb(36, 110, 187);
        border-radius: 7px;
        text-align: center;
        line-height: 53px;
        font-size: 16px;
        color: #f9fbfd;
        letter-spacing: 0.5px;
      }
    }
  }
}
/* @import '../../assets/css/product-shared.css';
@import '../../assets/css/voice.css'; */
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 661px;
  background-color: rgb(249, 249, 252);
  .part2_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 78px;
    > span {
      margin-bottom: 58px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 60px;
      line-height: 26px;
    }
    .p2m_bottom {
      width: 1148px;
      margin-left: -19px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .p2m_item {
        width: 297px;
        height: 265px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 68px;
      }
      img {
        height: 130px;
        width: auto;
        margin-bottom: 42px;
      }
      p {
        font-size: 16px;
        color: #34495e;
        display: none;
      }
      .p2m_item:hover {
        background: url('../../assets/img/product/voice/part2-item-bg.png')
          no-repeat;
        background-size: 100% 100%;
        p {
          display: inline;
          text-align: center;
          margin-right: 10px;
        }
      }
    }
    .p2m_mobile {
      display: none;
    }
  }
}
.part3 {
  box-sizing: border-box;
  width: 100%;
  height: 737px;
  .part3_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    text-align: center;
    padding-top: 78px;
    > span {
      margin-bottom: 110px;
    }
    img {
      height: 403px;
      width: auto;
      margin-left: -156px;
    }
  }
}
.part4 {
  width: 100%;
  padding-bottom: 62px;
  .part4_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 78px;
    > span {
      margin-bottom: 40px;
      text-align: center;
    }
    > p {
      text-align: center;
      font-size: 16px;
      color: #666666;
      line-height: 26px;
    }
    > p:nth-of-type(3) {
      margin-bottom: 98px;
    }
    .p4m-major {
      width: 100%;
      display: grid;
      grid-template-columns: 300px 300px 300px;
      grid-template-rows: 400px 400px;
      grid-column-gap: 105px;
      grid-row-gap: 50px;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      img {
        width: 300px;
        height: auto;
      }
      span {
        font-family: FontExcellent;
        font-size: 24px;
        color: #0f5aa8;
        margin-top: 29px;
        letter-spacing: 0.5px;
      }
      p {
        display: block;
        width: 230px;
        font-size: 16px;
        color: #666666;
        line-height: 27px;
        text-align: justify;
        margin-top: 21px;
        letter-spacing: 0.5px;
      }
      > div:nth-of-type(2) span {
        margin-top: 23.5px;
      }
      > div:nth-of-type(3) span {
        margin-top: 36px;
      }
      > div:nth-of-type(4) span {
        margin-top: 20px;
      }
      > div:nth-of-type(5) span {
        margin-top: 31px;
      }
      > div:nth-of-type(6) span {
        margin-top: 16.5px;
      }
      > div:nth-of-type(5) img {
        margin-top: -28px;
      }
      > div:nth-of-type(6) img {
        margin-top: -14px;
      }
    }
    .p4m_mobile {
      display: none;
    }
  }
}
@media screen and (max-width: 768px) {
  .voice {
    /* 第一部分 */
    .part1 {
      height: 4.28rem;
      .part1_main {
        width: 6.14rem;
        > img {
          content: url('../../assets/img/product/voice/mobile/part1-icon.png');
          width: 5.15rem;
          height: 3.91rem;
          top: 0rem;
          left: 1.16rem;
        }
      }
      .part1-title {
        width: auto;
        height: auto;
        span {
          font-size: 0.4rem;
          margin-bottom: 0rem;
          margin-top: -0.36rem;
        }
        p,
        a {
          display: none;
        }
      }
    }
    .part2 {
      height: 5.93rem;
      .part2_main {
        width: 5.5rem;
        padding-top: 0.37rem;
        > span {
          margin-bottom: 0.27rem;
        }
        p {
          font-size: 0.16rem;
          margin-bottom: 0.24rem;
          line-height: 0.26rem;
        }
        .p2m_bottom {
          width: 5.7rem;
          margin-left: 0.1rem;
          display: block;
          > div {
            width: auto;
            height: auto;
            display: block;
            padding-top: 0;
          }
          .swiper-item {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
          }
          .p2m_item {
            box-sizing: border-box;
            width: 2.51rem;
            height: 2.82rem;
            background: #ffffff;
            box-shadow: 0px 0.1rem 0.25rem 0.07rem rgba(21, 21, 21, 0.03);
            border-radius: 0.17rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 0.53rem;
          }
          img {
            height: 1.3rem;
            margin-bottom: 0.42rem;
          }
          p {
            display: inline-block;
            font-size: 0.16rem;
            margin-right: 0.1rem;
          }
          .p2m_item:hover {
            background: #ffffff;
            p {
              margin-right: 0.1rem;
            }
          }
        }
        .p2m_pc {
          display: none;
        }
        .p2m_mobile {
          display: block;
        }
      }
    }
    .part3 {
      height: 4.75rem;
      .part3_main {
        width: 100%;
        padding-top: 0.73rem;
        > span {
          margin-bottom: 0.5rem;
        }
        img {
          content: url('../../assets/img/product/voice/mobile/part3-icon.png');
          height: 2.39rem;
          margin-left: 0px;
        }
      }
    }
    .part4 {
      background: url('../../assets/img/product/voice/mobile/part4-bg.png')
        no-repeat;
      background-size: 100% 100%;
      padding-bottom: 1.14rem;
      .part4_main {
        width: 6.24rem;
        padding-top: 0.33rem;
        > span {
          color: #ffffff;
          margin-bottom: 0.3rem;
        }
        > p {
          color: #ffffff;
          font-size: 0.16rem;
          line-height: 0.27rem;
        }
        > p:nth-of-type(3) {
          margin-bottom: 0.5rem;
        }
        .p4m-major2 {
          width: 100%;
          .p4m2_item {
            box-sizing: border-box;
            width: 3.58rem;
            margin: 0 auto;
            height: 4.38rem;
            background: #ffffff;
            border-radius: 0.14rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 0.57rem;
            div {
              height: 1.6rem;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin-bottom: 0.27rem;
              img {
                max-height: 100%;
              }
            }
            span {
              font-size: 0.24rem;
              font-family: FontExcellent;
              font-weight: 400;
              color: #0f5aa8;
              margin-bottom: 0.25rem;
            }
            p {
              display: block;
              width: 2.26rem;
              font-size: 0.16rem;
              font-weight: 400;
              color: #666666;
            }
          }
        }
        .p4m_pc {
          display: none;
        }
        .p4m_mobile {
          display: block;
        }
      }
    }
  }
}
</style>
<style lang="less">
.voice {
  .swiper_bg_blue {
    width: 6.24rem;
  }
}
</style>
