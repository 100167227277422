<template>
  <div class="noticeSMS">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>通知短信</span>
          <p>
            通知短信：提供终端通知类文字短信发送服务，是商家维系会员最有效且最常见的方式，适用于订单通知、支付通知、告警通知、快递通知等。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/noticeSMS/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <div class="part2_main">
        <span class="part-title">通知短信特殊优势</span>
        <div class="p2m-major">
          <div class="p2m-major-left">
            <div
              class="p2m-major-item active reveal-bottom"
              @mousemove="p2Move($event)"
            >
              <img
                src="../../assets/img/product/noticeSMS/part2-icon1.png"
                alt=""
              />
              <div>
                <span>快速稳定</span>
                <p>
                  耕耘企业通讯行业10多年，自有通道充足，落地辐射地域广、提供一主多备三网通道，提供通道级异地灾备，充足的自定义签名通道，5秒送达,状态回执10s内返回90%+。
                </p>
              </div>
            </div>
            <div
              class="p2m-major-item reveal-bottom"
              @mousemove="p2Move($event)"
            >
              <img
                src="../../assets/img/product/noticeSMS/part2-icon2.png"
                alt=""
              />
              <div>
                <span>海量并发</span>
                <p>
                  10000+条/秒、10亿+条/天的处理能力，架构可横向扩展，支持资源热插拔，安全有保障。
                </p>
              </div>
            </div>
            <div
              class="p2m-major-item reveal-bottom"
              @mousemove="p2Move($event)"
            >
              <img
                src="../../assets/img/product/noticeSMS/part2-icon3.png"
                alt=""
              />
              <div>
                <span>智能管理</span>
                <p>
                  百悟科技自开发平台和监控，支持实时检测，自动化智能管理，实现秒级自动切换，服务可用性99.99%+。
                </p>
              </div>
            </div>
          </div>
          <img
            class="reveal-bottom p2m-majorImg"
            src="../../assets/img/product/noticeSMS/part2-right-bg.png"
            alt=""
          />
        </div>
      </div>
    </section>
    <section class="part3">
      <div class="part3_main">
        <span class="part-title">通知短信应用场景</span>
        <div class="p3m-major">
          <div class="p3m-major-Top reveal-bottom">
            <ul class="topUl">
              <li
                class="ul-item"
                v-for="(item, i) in p3Uldata"
                :key="i"
                @mouseover="topUlliMove(i)"
              >
                <div>{{ item }}</div>
                <span></span>
              </li>
            </ul>
          </div>
          <div class="p3m-major-Buttom reveal-bottom2">
            <div v-for="(item, i) in p3img" :key="i">
              <img :src="item" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'NoticeSMS',
  data() {
    return {
      p3Uldata: ['订单通知', '支付通知', '告警通知', '快递通知'],
      p3img: [
        require('../../assets/img/product/noticeSMS/part3-img1.png'),
        require('../../assets/img/product/noticeSMS/part3-img2.png'),
        require('../../assets/img/product/noticeSMS/part3-img3.png'),
        require('../../assets/img/product/noticeSMS/part3-img4.png'),
      ],
    };
  },
  mounted() {
    // 初始化
    this.topUlliMove(0);
    const that = this;
    $(window).resize(function () {
      that.topUlliMove(0);
    });
  },
  methods: {
    topUlliMove(i) {
      let obj = this.startBottom();
      // 初始化
      if ($(window).width() <= 768) {
        $('.ul-item').find('div').stop().animate({ lineHeight: '.53rem' }, 200);
      }
      $('.ul-item').stop().animate({ paddingTop: '0px' }, 200);
      $('.ul-item').find('span').stop().animate({ top: obj.top }, 200);
      $('.p3m-major-Buttom img').stop().animate(
        {
          left: obj.left,
          width: obj.width,
          height: obj.height,
          opacity: obj.opacity,
        },
        200
      );
      // 执行单一条件
      if ($(window).width() <= 768) {
        $('.ul-item')
          .eq(i)
          .find('div')
          .stop()
          .animate({ lineHeight: '.45rem' }, 200);
      }
      $('.ul-item').eq(i).stop().animate({ paddingTop: '6px' }, 200);
      $('.ul-item').eq(i).stop().animate({ paddingTop: '6px' }, 200);
      $('.ul-item').eq(i).find('span').stop().animate({ top: '0px' }, 200);
      $('.p3m-major-Buttom img').eq(i).stop().animate(
        {
          left: '0px',
          width: obj.bigWidth,
          height: obj.bigHeight,
          opacity: '1',
        },
        200
      );
    },
    startBottom() {
      let obj = {};
      if ($(window).width() > 768) {
        obj.top = '-15px';
        obj.width = '254px';
        obj.height = '494px';
        obj.opacity = '0.5';
        obj.bigWidth = '281px';
        obj.bigHeight = '547px';
        obj.left = '11.5px';
      } else {
        obj.top = '-.08rem';
        obj.width = '1.51rem';
        obj.height = '2.94rem';
        obj.opacity = '0.27';
        obj.bigWidth = '1.68rem';
        obj.bigHeight = '3.26rem';
        obj.left = '0rem';
      }
      return obj;
    },
    p2Move(e) {
      $('.p2m-major-item').removeClass('active');
      $(e.currentTarget).addClass('active');
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一个部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      width: 724px;
      height: 631px;
      margin-right: -108px;
      margin-top: -28px;
    }
    .part1-title {
      width: 485px;
      height: 280px;
      span {
        font-family: FontExcellent;
        font-size: 40px;
        color: #333333;
        display: block;
        margin-bottom: 22px;
        letter-spacing: 1px;
      }
      p {
        font-size: 16px;
        color: #666666;
        text-align: justify;
        margin-bottom: 78px;
        line-height: 28px;
      }
      a {
        display: block;
        width: 184px;
        height: 53px;
        background-color: rgb(36, 110, 187);
        border-radius: 7px;
        text-align: center;
        line-height: 53px;
        font-size: 16px;
        color: #f9fbfd;
        letter-spacing: 0.5px;
      }
    }
  }
}
/* 第二个部分 */
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 825px;
  .part2_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 78px;
    .part-title {
      margin-bottom: 94px;
    }
    .p2m-major {
      width: 1100px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .p2m-major > img {
        width: 452px;
        height: 512px;
        margin-top: 48px;
        margin-right: -11px;
      }
      .p2m-major-left {
        width: 570px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .p2m-major-item {
          box-sizing: border-box;
          width: 100%;
          height: 134px;
          display: flex;
          flex-direction: row;
          padding: 26px 0px;
          span {
            display: block;
            font-family: FontExcellent;
            font-size: 20px;
            color: #333333;
            margin-bottom: 7px;
          }
          p {
            display: block;
            width: 413px;
            font-size: 16px;
            color: #666666;
            line-height: 27px;
            text-align: justify;
          }
        }
        .p2m-major-item:nth-of-type(1) {
          padding-left: 25px;
          img {
            width: 68px;
            height: 73px;
            margin-right: 24px;
            margin-top: 5px;
          }
        }
        .p2m-major-item:nth-of-type(2) {
          padding-left: 26px;
          img {
            width: 71px;
            height: 71px;
            margin-right: 21px;
          }
        }
        .p2m-major-item:nth-of-type(3) {
          padding-left: 32px;
          img {
            width: 69px;
            height: 69px;
            margin-right: 18px;
            margin-top: 5px;
          }
        }
        .p2m-major-item.active {
          background: url('../../assets/img/product/number95/part2-item-bg.png')
            no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
/* 第三个部分 */
.part3 {
  box-sizing: border-box;
  width: 100%;
  height: 1067px;
  background: linear-gradient(-88deg, #ffffff 0%, #fafbfd 52%, #ffffff 100%);
  .part3_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 119px;
    .part-title {
      margin-bottom: 94px;
    }
    .p3m-major {
      width: 1110px;
      margin: 0 auto;
      .p3m-major-Top {
        margin-bottom: 50px;
        .topUl {
          list-style: none;
          box-sizing: border-box;
          overflow: hidden;
          width: 100%;
          height: 64px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-top: 1px solid rgb(225, 228, 230);
          border-bottom: 1px solid rgb(225, 228, 230);
          .ul-item {
            box-sizing: border-box;
            width: 25%;
            height: 100%;
            background: #ffffff;
            border-left: 1px solid rgb(225, 228, 230);
            box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.18);
            font-size: 18px;
            color: #666666;
            font-family: Regular;
            text-align: center;
            cursor: pointer;
            position: relative;
            div {
              box-sizing: border-box;
              width: 100%;
              height: 100%;
              background: #ffffff;
              line-height: 64px;
            }
            span {
              display: block;
              width: 100%;
              height: 15px;
              position: absolute;
              top: -15px;
              left: 0;
              background: #0c63bb;
            }
          }
          .ul-item:last-child {
            border-right: 1px solid rgb(225, 228, 230);
          }
        }
      }
      .p3m-major-Buttom {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        div {
          box-sizing: border-box;
          width: 25%;
          height: 550px;
          position: relative;
          img {
            position: absolute;
            bottom: 0;
            left: 11.5px;
            width: 254px;
            height: 494px;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  /* 第一个部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.8rem;
      > img {
        width: 4.5rem;
        height: 3.92rem;
        margin-right: 0px;
        margin-top: 0px;
      }
      .part1-title {
        width: auto;
        height: auto;
        margin-left: 0.31rem;
        span {
          font-size: 0.4rem;
          font-weight: 400;
          margin-bottom: 0px;
        }
        p,
        a {
          display: none;
        }
      }
    }
  }
  /* 第二个部分 */
  .part2 {
    height: 6.95rem;
    background: linear-gradient(-88deg, #ffffff 0%, #fafafa 52%, #ffffff 100%);
    .part2_main {
      width: 5.7rem;
      padding-top: 0.43rem;
      .part-title {
        margin-bottom: 0.26rem;
      }
      .p2m-major {
        width: 100%;
        .p2m-majorImg {
          display: none;
        }
        .p2m-major-left {
          width: 100%;
          .p2m-major-item {
            height: 1.78rem;
            padding: 0.4rem 0px;
            span {
              font-size: 0.2rem;
              font-weight: 400;
              margin-bottom: 0.15rem;
            }
            p {
              width: 4.13rem;
              font-size: 0.16rem;
              font-weight: 400;
              line-height: 0.24rem;
            }
          }
          .p2m-major-item:nth-of-type(1) {
            background: url('../../assets/img/product/number95/part2-item-bg.png')
              no-repeat;
            background-size: 100% 100%;
            padding-left: 0.37rem;
            img {
              width: 0.67rem;
              height: 0.73rem;
              margin-right: 0.23rem;
            }
          }
          .p2m-major-item:nth-of-type(2) {
            padding-left: 0.3rem;
            img {
              width: 0.7rem;
              height: 0.71rem;
              margin-right: 0.26rem;
            }
          }
          .p2m-major-item:nth-of-type(3) {
            padding-left: 0.39rem;
            img {
              width: 0.69rem;
              height: 0.67rem;
              margin-right: 0.18rem;
              margin-top: 0.07rem;
            }
          }
        }
      }
    }
  }
  /* 第三个部分 */
  .part3 {
    height: 6.47rem;
    .part3_main {
      width: 6.75rem;
      padding-top: 0.63rem;
      .part-title {
        margin-bottom: 0.62rem;
      }
      .p3m-major {
        width: 100%;
        .p3m-major-Top {
          margin-bottom: 0.47rem;
          .topUl {
            height: 0.53rem;
            border-top: 0.01rem solid rgb(225, 228, 230);
            .ul-item {
              box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.18);
              font-size: 0.18rem;
              div {
                line-height: 0.53rem;
              }
              span {
                height: 0.08rem;
                top: -0.08rem;
              }
            }
            .ul-item:last-child {
              border-right: 1px solid rgb(225, 228, 230);
            }
          }
        }
        .p3m-major-Buttom {
          div {
            height: 3.15rem;
            text-align: center;
            img {
              position: absolute;
              bottom: 0;
              left: 0rem;
              width: 1.68rem;
              height: 3.26rem;
              opacity: 0.27;
            }
          }
        }
      }
    }
  }
}
</style>
