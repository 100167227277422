import { render, staticRenderFns } from "./PartImgSwiper.vue?vue&type=template&id=66c04e4e&scoped=true&"
import script from "./PartImgSwiper.vue?vue&type=script&lang=js&"
export * from "./PartImgSwiper.vue?vue&type=script&lang=js&"
import style0 from "./PartImgSwiper.vue?vue&type=style&index=0&id=66c04e4e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66c04e4e",
  null
  
)

export default component.exports