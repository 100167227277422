<template>
  <div class="serve95">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>95码号服务</span>
          <p>
            95码号是直属于工信部的特殊码号，起初主要用于银行、保险等特殊单位，后工信部根据需求，为知名品牌企业开通95*类别号码，具有全国唯一性，没有区域、城市限制，三网合一的全国统一热线。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/serve95/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <span class="part-title">95码号功能介绍</span>
      <div class="part2_main p2_pc">
        <div
          class="part2-item"
          v-for="(item, index) in part2Data"
          :key="index"
          @mousemove="part2ItemMove(item.imgOver, index)"
          @mouseout="part2ItemOut(item.img, index)"
        >
          <div>
            <img :src="item.img" alt="" />
          </div>
          <span>{{ item.span }}</span>
          <p>{{ item.p1 }}</p>
          <p>{{ item.p2 }}</p>
        </div>
      </div>
      <div class="part2_main p2_mobile">
        <swiper :options="swiperOption">
          <swiper-slide
            class="swiper-item"
            v-for="(item, i) in part2Data"
            :key="i"
          >
            <div class="part2-item" @click="p2Click($event, item.imgOver)">
              <div>
                <img :src="item.img" alt="" />
              </div>
              <span>{{ item.span }}</span>
              <p>{{ item.p1 }}</p>
              <p>{{ item.p2 }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination swiper_bg_blue" slot="pagination"></div>
      </div>
    </section>
    <PartAdvantage
      :pAdvantageData="p3Data"
      :pAdvantageDatas="p3Datas"
      :pAdvantageTitle="p3Title"
    ></PartAdvantage>
  </div>
</template>

<script>
import PartAdvantage from '@/components/solution/PartAdvantage.vue';
export default {
  data() {
    return {
      part2Data: [
        {
          span: '跨界支持',
          p1: '内聚电话和短信功能',
          p2: '彰显多价值衍生功能',
          img: require('../../assets/img/product/serve95/part2-icon1.png'),
          imgOver: require('../../assets/img/product/serve95/part2-icon1-over.png'),
        },
        {
          span: '统一号显',
          p1: '支持全国不加区号直接呼入',
          p2: '外呼时直接外显95',
          img: require('../../assets/img/product/serve95/part2-icon2.png'),
          imgOver: require('../../assets/img/product/serve95/part2-icon2-over.png'),
        },
        {
          span: '码号扩展',
          p1: '950/1/2/4/7可扩展',
          p2: '进行运营级码号业务',
          img: require('../../assets/img/product/serve95/part2-icon3.png'),
          imgOver: require('../../assets/img/product/serve95/part2-icon3-over.png'),
        },
      ],
      // 轮播图参数
      swiperOption: {
        pagination: {
          el: '.part2 .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      // p3
      p3Data: [
        {
          span: '办理周期短',
          p: '专业码号申请团队，多年码号申请经验，实时政策跟进，及时调整申请策略。',
        },
        {
          span: '多方案选择',
          p: '码号的申请与获取，结合不同客户的实际情况，提供多种码号申请方案供选择。',
        },
        {
          span: '号码申请成功率高',
          p: '百悟与工信部合作经验丰富，可针对客户意向码号申请提供增值服务。',
        },
        {
          span: '完善的服务流程',
          p: '客户只需配合提供对应资料，无需操心繁琐的申请流程，无需投入人力物力。',
        },
      ],
      p3Datas: [],
      p3Title: '95码号特殊优势',
    };
  },
  components: {
    PartAdvantage,
  },
  mounted() {
    $('.part2-item').eq(0).addClass('reveal-bottom');
    $('.part2-item').eq(1).addClass('reveal-bottom2');
    $('.part2-item').eq(2).addClass('reveal-bottom3');
    // 数据初始化
    this.p3Datas = [
      JSON.parse(JSON.stringify(this.p3Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p3Data.slice(-2))),
    ];
  },
  methods: {
    part2ItemMove(img, index) {
      $('.part2-item').removeClass('active');
      $('.part2-item').eq(index).addClass('active');
      $('.part2-item').eq(index).find('img').attr('src', img);
    },
    part2ItemOut(img, index) {
      $('.part2-item').removeClass('active');
      $('.part2-item').eq(index).find('img').attr('src', img);
    },
    p2Click(e, img) {
      $('.swiper-item').each((index, item) => {
        $(item).find('img').attr('src', this.part2Data[index].img);
      });
      $('.swiper-item .part2-item').removeClass('active');
      $(e.currentTarget).addClass('active');
      $(e.currentTarget).find('img').attr('src', img);
    },
  },
};
</script>

<style lang="less" scoped>
/* 头图样式 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      width: 732px;
      height: 915px;
      margin-right: -105px;
      margin-top: 207px;
      z-index: -1;
    }
  }
  .part1-title {
    width: 480px;
    height: 280px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 78px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 761px;
  background: url('../../assets/img/product/serve95/part2-bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 81px;
  .part-title {
    color: #ffffff;
    margin-bottom: 98px;
  }
  .part2_main {
    width: 1068px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 338px);
    grid-template-rows: 391px;
    grid-column-gap: 37px;
    .part2-item {
      background: #ffffff;
      box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 51px;
      div {
        height: 120px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 39px;
      }
      span {
        font-size: 18px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #333333;
        margin-bottom: 25px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 27px;
      }
    }
    .part2-item.active {
      background: linear-gradient(-24deg, #0c63bb 0%, #6392cd 100%);
      border: 2px solid #ffffff;
      box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
      border-radius: 10px;
      span {
        color: #ffffff;
      }
      p {
        color: #ffffff;
      }
    }
  }
  .p2_mobile {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  /* 第一部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        content: url('../../assets/img/product/serve95/mobile/part1-icon.png');
        width: 4.16rem;
        height: 4.1rem;
        margin-top: 0.18rem;
        margin-right: -0.3rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
        margin-top: -0.62rem;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part2 {
    height: 6.34rem;
    background: url('../../assets/img/product/serve95/mobile/part2-bg.png')
      no-repeat;
    background-size: 100% 100%;
    padding-top: 0.6rem;
    .part-title {
      color: #ffffff;
      margin-bottom: 0.38rem;
    }
    .part2_main {
      width: 6rem;
      margin: 0 auto;
      .part2-item {
        box-sizing: border-box;
        width: 3.38rem;
        margin: 0 auto;
        height: 3.91rem;
        box-shadow: 0px 0.3rem 0.21rem 0rem rgba(196, 197, 199, 0.1);
        border-radius: 0.1rem;
        padding-top: 0.51rem;
        div {
          height: 1.2rem;
          margin-bottom: 0.39rem;
          img {
            max-height: 100%;
          }
        }
        span {
          font-size: 0.18rem;
          margin-bottom: 0.25rem;
        }
        p {
          font-size: 0.16rem;
          line-height: 0.27rem;
        }
      }
      .part2-item.active {
        border: 0.02rem solid #ffffff;
        box-shadow: 0px 0.3rem 0.21rem 0rem rgba(196, 197, 199, 0.1);
        border-radius: 0.1rem;
      }
    }
    .p2_pc {
      display: none;
    }
    .p2_mobile {
      display: block;
    }
  }
}
</style>
<style lang="less">
.serve95 {
  .partAdvantage .pAdvantage-pc .partAdvantage-item p {
    width: 272px;
  }
  .swiper_bg_blue {
    width: 6rem;
  }
}
</style>
