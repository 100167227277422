<template>
  <div class="partSupport">
    <span class="part-title">支撑平台</span>
    <div class="pS_main reveal-bottom2">
      <div class="pS_up">
        <div class="pS_uLine"></div>
        <div
          class="pS_uItem"
          v-for="(item, i) in pSData"
          :key="i"
          @click="pSCLick(i, item)"
        >
          <span v-text="item.title"></span>
        </div>
      </div>
      <div class="pS_down">
        <div class="pS_dItem1">
          <img :src="pSDataItem.img" alt="" />
        </div>
        <div class="pS_dItem2">
          <img
            src="../../assets/img/solution/finance/part4-dTop-img.png"
            alt=""
          />
          <p v-text="pSDataItem.p"></p>
        </div>
      </div>
      <div class="pS_dTwo pS_dTwo_pc">
        <div
          class="pS_dTItem"
          v-for="(item, i) in pSDData"
          :key="i"
          @mousemove="pSDTMove(i, item.imgOver)"
        >
          <div>
            <img :src="item.img" alt="" />
          </div>
          <span v-html="item.span"></span>
        </div>
      </div>
      <div class="pS_dTwo pS_dTwo_mobile">
        <swiper :options="swiperOption">
          <swiper-slide
            class="swiper-item"
            v-for="(item, i) in pSDData"
            :key="i"
          >
            <div class="pS_dTItem" @click="pSDTClick($event, item.imgOver)">
              <div>
                <img :src="item.img" alt="" />
              </div>
              <span v-html="item.span"></span>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination swiper_bg_white" slot="pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pSDataItem: {},
      swiperOption: {
        pagination: {
          el: '.pS_dTwo_mobile .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
    };
  },
  props: ['pSData', 'pSDData'],
  mounted() {
    this.pSCLick(0, this.pSData[0]);
    const that = this;
    $(window).resize(function () {
      that.pSCLick(0, that.pSData[0]);
    });
  },
  methods: {
    pSCLick(i, item) {
      this.pSDataItem = item;
      $('.pS_uItem').removeClass('active');
      $('.pS_uItem').eq(i).addClass('active');
      $('.pS_uLine').width($('.pS_uItem').eq(i).innerWidth());
      $('.pS_uLine')
        .stop()
        .animate({
          left: $('.pS_uItem').eq(i).position().left,
        });
    },
    pSDTMove(i, img) {
      $('.pS_dTwo_pc .pS_dTItem').each((index, item2) => {
        $(item2).find('img').attr('src', this.pSDData[index].img);
      });
      $('.pS_dTItem').removeClass('active');
      $('.pS_dTItem').eq(i).addClass('active');
      $('.pS_dTItem').eq(i).find('img').attr('src', img);
    },
    pSDTClick(e, img) {
      $('.pS_dTwo_mobile .swiper-item').each((index, item2) => {
        $(item2)
          .find('.pS_dTItem')
          .find('img')
          .attr('src', this.pSDData[index].img);
      });
      $('.pS_dTItem').removeClass('active');
      $(e.currentTarget).addClass('active');
      $(e.currentTarget).find('img').attr('src', img);
    },
  },
};
</script>

<style lang="less">
.partSupport {
  width: 100%;
  padding-top: 85px;
  padding-bottom: 93px;
  background: rgb(250, 251, 250);
  .part-title {
    margin-bottom: 48px;
  }
  .pS_main {
    width: 1106px;
    margin: 0 auto;
    .pS_up {
      box-sizing: border-box;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px 60px;
      border-bottom: 1px solid #a1a1a1;
      .pS_uLine {
        width: 136px;
        height: 1px;
        background: #005cb9;
        position: absolute;
        bottom: 0px;
        left: 60px;
      }
      .pS_uItem {
        padding: 27px 10px;
        cursor: pointer;
        span {
          font-size: 24px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #666666;
        }
      }
      .pS_uItem.active {
        span {
          color: #005cb9;
        }
      }
    }
    .pS_down {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 61px;
      .pS_dItem1 {
        width: 489px;
        height: 244px;
        background: #ffffff;
        box-shadow: 17px 9px 17px 1px rgba(228, 230, 233, 0.16);
        display: flex;
        flex-direction: flex;
        justify-content: center;
        align-items: center;
      }
      .pS_dItem2 {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background: #005cb9;
        img {
          position: absolute;
          top: 54px;
          left: 33px;
        }
        p {
          display: block;
          width: 460px;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          text-align: justify;
          line-height: 30px;
          margin-top: 85px;
        }
      }
    }
    .pS_dTwo {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 116px;
      .pS_dTItem {
        box-sizing: border-box;
        width: 354px;
        height: 251px;
        background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
        border-radius: 5px;
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
          height: 106px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-bottom: 23px;
        }
        span {
          font-size: 24px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .pS_dTItem.active {
        background: #fcfcfc;
        border: 3px solid;
        border-image: linear-gradient(
            133deg,
            rgba(49, 110, 188, 0.67),
            rgba(0, 92, 185, 0.67)
          )
          3 3;
        box-shadow: 17px 9px 17px 1px rgba(10, 95, 186, 0.16);
        border-radius: 5px;
        clip-path: inset(0 round 5px);
        span {
          color: #005cb9;
        }
      }
    }
    .pS_dTwo_mobile {
      display: none;
    }
  }
}
@media screen and (max-width: 768px) {
  .partSupport {
    padding-top: 0.45rem;
    padding-bottom: 1.56rem;
    .part-title {
      margin-bottom: 0.52rem;
    }
    .pS_main {
      width: 6.14rem;
      .pS_up {
        padding: 0px 0px;
        .pS_uLine {
          width: 1.45rem;
          left: 0px;
        }
        .pS_uItem {
          padding: 0.21rem 0.1rem;
          span {
            font-size: 0.24rem;
          }
        }
      }
      .pS_down {
        width: 100%;
        margin-top: 0.36rem;
        flex-direction: column;
        .pS_dItem1 {
          width: 100%;
          height: 2.44rem;
          box-shadow: 0.17rem 0.09rem 0.17rem 0.01rem rgba(228, 230, 233, 0.16);
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .pS_dItem2 {
          flex: none;
          width: 100%;
          height: 2.44rem;
          img {
            top: 0.54rem;
            left: 0.33rem;
            width: 0.58rem;
            height: 0.43rem;
          }
          p {
            width: 4.76rem;
            font-size: 0.17rem;
            line-height: 0.3rem;
            margin-top: 0.85rem;
          }
        }
      }
      .pS_dTwo {
        margin-top: 0.73rem;
        .swiper-item {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        .pS_dTItem {
          width: 3.54rem;
          height: 2.51rem;
          border-radius: 0.05rem;
          padding-top: 0.3rem;
          div {
            height: 1.06rem;
            margin-bottom: 0.23rem;
            img {
              max-height: 100%;
            }
          }
          span {
            font-size: 0.24rem;
          }
        }
        .pS_dTItem.active {
          border: 0.03rem solid;
          border-image: linear-gradient(
              133deg,
              rgba(49, 110, 188, 0.67),
              rgba(0, 92, 185, 0.67)
            )
            3 3;
          box-shadow: 0.17rem 0.09rem 0.17rem 0.01rem rgba(10, 95, 186, 0.16);
          border-radius: 0.05rem;
          clip-path: inset(0 round 0.05rem);
          span {
            color: #005cb9;
          }
        }
      }
      .pS_dTwo_pc {
        display: none;
      }
      .pS_dTwo_mobile {
        display: block;
      }
    }
  }
}
</style>
