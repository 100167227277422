<template>
  <div class="message">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>短信平台</span>
          <p>
            百悟短信平台是集企业短信智能路由、智能运营、系统管理、统计查询等功能的综合性企业通讯平台。采用B/S架构为用户提供验证码、通知类信息、告警短信、营销短信的短信发送服务。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/message/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <div class="part2_main">
        <span class="part-title">系统架构</span>
        <img
          class="reveal-bottom2"
          src="../../assets/img/product/message/part2-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part3">
      <div class="part3_main">
        <span class="part-title">短信平台特殊优势</span>
        <div class="p3m-major">
          <div class="p3m-item reveal-bottom">
            <img
              src="../../assets/img/product/message/part3-icon1.png"
              alt=""
            />
            <div>
              <span>并发量</span>
              <p>10000+条/秒，可根据需求平滑扩容。</p>
            </div>
          </div>
          <div class="p3m-item reveal-bottom">
            <img
              src="../../assets/img/product/message/part3-icon2.png"
              alt=""
            />
            <div>
              <span>时效性</span>
              <p>秒级触达，状态回执支持自主拉取和主动推送。</p>
            </div>
          </div>
          <div class="p3m-item reveal-bottom2">
            <img
              src="../../assets/img/product/message/part3-icon3.png"
              alt=""
            />
            <div>
              <span>可靠性</span>
              <p>电信级平台，保证业务的高触达率。</p>
            </div>
          </div>
          <div class="p3m-item reveal-bottom2">
            <img
              src="../../assets/img/product/message/part3-icon4.png"
              alt=""
            />
            <div>
              <span>稳定性</span>
              <p>
                百悟短信平台拥有专线机房、集群式架构、多台服务器互备、运营商多线冗余设计。
              </p>
            </div>
          </div>
          <div class="p3m-item reveal-bottom3">
            <img
              src="../../assets/img/product/message/part3-icon5.png"
              alt=""
            />
            <div>
              <span>安全性</span>
              <p>绑定IP防止平台遭受攻击；</p>
              <p>数据定期备份；</p>
              <p>重要数据严格加密。</p>
            </div>
          </div>
          <div class="p3m-item reveal-bottom3">
            <img
              src="../../assets/img/product/message/part3-icon6.png"
              alt=""
            />
            <div>
              <span>定制化</span>
              <p>
                百悟短信平台支持二次开发，提供客户行业专属的功能定制化服务。
              </p>
            </div>
          </div>
          <div class="p3m-item reveal-bottom4">
            <img
              src="../../assets/img/product/message/part3-icon7.png"
              alt=""
            />
            <div>
              <span>监控告警</span>
              <p>亿级数据多维度监控；</p>
              <p>异常警告通过邮件短信等通知；</p>
              <p>保证短信业务稳定性。</p>
            </div>
          </div>
          <div class="p3m-item reveal-bottom4">
            <img
              src="../../assets/img/product/message/part3-icon8.png"
              alt=""
            />
            <div>
              <span>存储能力</span>
              <p>存储、处理亿级数据。</p>
            </div>
          </div>
        </div>
        <div class="p3m-major">
          <swiper :options="swiperOption">
            <swiper-slide>
              <div class="p3m-item">
                <div>
                  <img
                    src="../../assets/img/product/message/part3-icon1.png"
                    alt=""
                  />
                </div>
                <div>
                  <span>并发量</span>
                  <p>10000+条/秒，可根据需求平滑扩容。</p>
                </div>
              </div>
              <div class="p3m-item">
                <div>
                  <img
                    src="../../assets/img/product/message/part3-icon2.png"
                    alt=""
                  />
                </div>
                <div>
                  <span>时效性</span>
                  <p>秒级触达，状态回执支持自主拉取和主动推送。</p>
                </div>
              </div> </swiper-slide
            >　
            <swiper-slide>
              <div class="p3m-item">
                <div>
                  <img
                    src="../../assets/img/product/message/part3-icon3.png"
                    alt=""
                  />
                </div>
                <div>
                  <span>可靠性</span>
                  <p>电信级平台，保证业务的高触达率。</p>
                </div>
              </div>
              <div class="p3m-item">
                <div>
                  <img
                    src="../../assets/img/product/message/part3-icon4.png"
                    alt=""
                  />
                </div>
                <div>
                  <span>稳定性</span>
                  <p>
                    百悟短信平台拥有专线机房、集群式架构、多台服务器互备、运营商多线冗余设计。
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="p3m-item">
                <div>
                  <img
                    src="../../assets/img/product/message/part3-icon5.png"
                    alt=""
                  />
                </div>
                <div style="width: 2.4rem">
                  <span>安全性</span>
                  <p>绑定IP防止平台遭受攻击；</p>
                  <p>数据定期备份；</p>
                  <p>重要数据严格加密。</p>
                </div>
              </div>
              <div class="p3m-item">
                <div>
                  <img
                    src="../../assets/img/product/message/part3-icon6.png"
                    alt=""
                  />
                </div>
                <div>
                  <span>定制化</span>
                  <p>
                    百悟短信平台支持二次开发，提供客户行业专属的功能定制化服务。
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="p3m-item">
                <div>
                  <img
                    src="../../assets/img/product/message/part3-icon7.png"
                    alt=""
                  />
                </div>
                <div>
                  <span>监控告警</span>
                  <p>亿级数据多维度监控；</p>
                  <p>异常警告通过邮件短信等通知；</p>
                  <p>保证短信业务稳定性。</p>
                </div>
              </div>
              <div class="p3m-item">
                <div>
                  <img
                    src="../../assets/img/product/message/part3-icon8.png"
                    alt=""
                  />
                </div>
                <div>
                  <span>存储能力</span>
                  <p>存储、处理亿级数据。</p>
                </div>
              </div>
            </swiper-slide>
            <div
              class="swiper-pagination swiper_bg_white"
              slot="pagination"
            ></div>
          </swiper>
        </div>
      </div>
    </section>
    <!-- TODO 没图片 -->
    <!-- <section class="part4">
      <div class="part4_main">
        <span class="part-title">案例实战</span>
        <p>某金融客户短信平台案例</p>
        <img
          class="reveal-bottom2"
          src="../../assets/img/product/message/part4-icon.png"
          alt=""
        />
      </div>
    </section> -->
  </div>
</template>

<script>
export default {
  name: 'message',
  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false, // 手动切换之后继续自动轮播
        // },
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
        grabCursor: true, //小手掌抓取滑动
        loop: true,
      },
    };
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
/* 头图样式 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      width: 793px;
      height: 640px;
      margin-right: -185px;
    }
  }
  .part1-title {
    width: 480px;
    height: 280px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 78px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  background-color: rgb(249, 249, 252);
  .part-title {
    margin-bottom: 60px;
  }
  .part2_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    text-align: center;
    padding-top: 78px;
    img {
      width: 1145px;
      height: auto;
      margin-left: -40px;
    }
  }
}
.part3 {
  width: 100%;
  padding-bottom: 20px;
  .part-title {
    margin-bottom: 60px;
  }
  .part3_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 78px;
    .p3m-major {
      width: 100%;
      height: 924px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .p3m-item {
        box-sizing: border-box;
        width: 450px;
        height: 145px;
        display: flex;
        flex-direction: row;
        img {
          width: 197px;
          height: auto;
          margin-right: 29px;
        }
        div {
          box-sizing: border-box;
          flex: 1;
          padding-top: 10px;
        }
        span {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          display: block;
          margin-bottom: 16px;
        }
        p {
          font-size: 16px;
          color: #333333;
          text-align: justify;
          line-height: 28px;
        }
      }
      .p3m-item:nth-of-type(1) p {
        display: block;
        width: 170px;
      }
    }
    .p3m-major:nth-of-type(2) {
      display: none;
    }
  }
}
.part4 {
  box-sizing: border-box;
  width: 100%;
  height: 1268px;
  background-color: rgb(249, 249, 252);
  .part4_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    text-align: center;
    padding-top: 78px;
    span {
      margin-bottom: 4px;
    }
    p {
      font-family: FontExcellent;
      font-size: 22px;
      color: #666666;
      margin-bottom: 102px;
    }
    img {
      width: 1090px;
      height: auto;
    }
  }
}
@media screen and (max-width: 768px) {
  /* 头图样式 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.51rem;
      margin-left: 0.66rem;
      align-items: flex-start;
    }
    .part1-title {
      width: auto;
      height: auto;
      margin-top: 1.54rem;
      span {
        font-size: 0.4rem;
        margin-bottom: 0rem;
        letter-spacing: 1px;
      }
      p,
      a {
        display: none;
      }
    }
    img {
      width: 4.62rem !important;
      height: 3.73rem !important;
      margin-right: 0rem !important;
      margin-top: 0.46rem;
    }
  }
  .part2 {
    height: 4.3rem;
    .part2_main {
      width: 6.3rem;
      height: 100%;
      text-align: center;
      padding-top: 0.34rem;
      .part-title {
        margin-bottom: 0.6rem;
      }
      img {
        width: 100%;
        margin-left: 0rem;
      }
    }
  }
  .part3 {
    height: 6rem;
    padding-bottom: 0rem;
    .part3_main {
      width: 6.2rem;
      height: auto;
      padding-top: 0.42rem;
      .part-title {
        margin-bottom: 0.5rem;
      }
      .p3m-major {
        display: block;
        height: 4rem;
        .swiper-container {
          height: 100%;
          padding-top: 0.25rem;
          .swiper-pagination {
            bottom: 0.16rem;
          }
        }
        .swiper-slide {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .p3m-item {
            box-sizing: border-box;
            width: 2.65rem;
            height: 3.85rem;
            display: flex;
            flex-direction: column;
            div {
              flex: 1;
              padding-top: 0px;
            }
            div:first-child {
              width: 100%;
              height: 1.85rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              img {
                width: auto;
                height: 1.85rem;
                margin-right: 0rem;
                margin-bottom: 0.39rem;
              }
            }
            div:last-child {
              width: 2.4rem;
              span {
                font-size: 0.18rem;
                text-align: center;
                margin-bottom: 0.23rem;
              }
              p {
                display: block;
                font-size: 0.16rem;
                line-height: 0.24rem;
                font-weight: 400;
                width: 2.24rem;
                margin: 0 auto;
                text-align: center;
              }
            }
          }
        }
      }
      .p3m-major:nth-of-type(1) {
        display: none;
      }
      .p3m-major:nth-of-type(2) {
        display: block;
      }
    }
  }
  .part4 {
    height: 7.59rem;
    background: url('../../assets/img/product/message/mobile/part4-bg.png')
      no-repeat center center;
    .part4_main {
      width: 6.1rem;
      height: 100%;
      text-align: center;
      padding-top: 0.45rem;
      span {
        margin-bottom: 0.12rem;
      }
      p {
        font-family: FontExcellent;
        font-size: 0.22rem;
        color: #666666;
        margin-bottom: 0.71rem;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
<style lang="less">
.message {
  .swiper_bg_white {
    width: 6.2rem;
    .swiper-pagination-bullet {
      margin: 0px 0.1rem;
    }
  }
}
</style>
