<template>
  <div class="governmentAndEnterprise">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>政企解决方案</span>
          <p>
            百悟科技为政企类客户提供基干云计算物联网和移动通信技术的政企信息化整体解决方案包含统一的政企通信平台、个性化定制的云服务平台以承载政企客户对安全、运维等多方面的信息化需求实现政企服务的互联网化、智能化和集约化打造高效的智慧政企。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/solution/governmentAndEnterprise/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <PartFlexSwiper
      :pFlexSwiperData="p2Data"
      :pFlexSwiperDatas="p2Datas"
      :pFlexSwiperTitle="p2Title"
    ></PartFlexSwiper>
    <PartCore ref="Part3Core" :pCoreData="p3Data" width="1089px" height="3rem"></PartCore>
    <section class="part4">
      <span class="part-title">可实现的服务</span>
      <div class="p4_main p4_m_pc reveal-bottom2">
        <div class="p4_item" v-for="(item, i) in p4Data" :key="i">
          <div>
            <span v-text="item.span"></span>
            <p v-text="item.p"></p>
          </div>
        </div>
      </div>
      <div class="p4_main p4_m_mobile">
        <swiper :options="swiperOption2">
          <swiper-slide
            class="swiper-item"
            v-for="(item, i) in p4Datas"
            :key="i"
          >
            <div class="p4_item2" v-for="(item2, i2) in item" :key="i2">
              <span v-text="item2.span"></span>
              <p v-text="item2.p"></p>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination swiper_bg_white" slot="pagination"></div>
      </div>
    </section>
    <section class="part5">
      <span class="part-title">解决方案结构</span>
      <img
        class="reveal-bottom2"
        src="../../assets/img/solution/governmentAndEnterprise/part5-icon.png"
        alt=""
      />
    </section>
  </div>
</template>

<script>
import PartFlexSwiper from '@/components/solution/PartFlexSwiper.vue';
import PartCore from '@/components/solution/PartCore.vue';
export default {
  data() {
    return {
      // p2
      swiperOption: {
        pagination: {
          el: '.governmentAndEnterpriseP2-swiper .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      p2Data: [
        {
          img: require('../../assets/img/solution/governmentAndEnterprise/part2-icon1.png'),
          imgOver: require('../../assets/img/solution/governmentAndEnterprise/part2-icon1-over.png'),
          span: '解决业务孤岛',
          p: '大多数政企的信息化建设还是以管理职能为主导的建设模式，较少考虑人的因素和体现以人为本的信息化服务理念，从而形成业务孤岛。',
        },
        {
          img: require('../../assets/img/solution/governmentAndEnterprise/part2-icon2.png'),
          imgOver: require('../../assets/img/solution/governmentAndEnterprise/part2-icon2-over.png'),
          span: '应对业务快速变化',
          p: '传统政企的集成模式随着应用的增长、信息交换量的增加、以及业务流程整合的开展，交换中心有可能成为整体应用的瓶颈，从而造成很多应用的停滞。',
        },
        // {
        //   img: require('../../assets/img/solution/governmentAndEnterprise/part2-icon3.png'),
        //   imgOver: require('../../assets/img/solution/governmentAndEnterprise/part2-icon3-over.png'),
        //   span: '实现数据共享',
        //   p: '历史数据没有处理策略，导致系统运行负担加重，性能严重下降。过分的依赖数据仓库技术工具进行数据搬迁，实时性以及多次搬迁之后造成的一致性也无法保障，数据质量比较差。存在大量基础数据重复采集、数据间关系混乱等现象，数据的权威性得不到保障。',
        // },
        {
          img: require('../../assets/img/solution/governmentAndEnterprise/part2-icon4.png'),
          imgOver: require('../../assets/img/solution/governmentAndEnterprise/part2-icon4-over.png'),
          span: '实时运维管理',
          p: '智慧政企建设缺少统一规划，前期部署因受制于各业务系统，导致部署困难。业务系统集成对开发商的依赖度高，用户自主支配能力弱，由此造成系统得不到及时的更新与维护，可扩展性差，用户满意度低等不良状况。',
        },
      ],
      p2Datas: [],
      p2Title: '行业需求',
      // p3
      p3Data: [
        {
          img: require('../../assets/img/solution/governmentAndEnterprise/part3-icon1.png'),
          img2: require('../../assets/img/solution/governmentAndEnterprise/mobile/part3-icon1.png'),
          imgOver: require('../../assets/img/solution/governmentAndEnterprise/part3-icon1-over.png'),
          span: '统一通信平台',
          p: '统一政企通信平台建立高效的对内/对外信息沟通窗口',
        },
        {
          img: require('../../assets/img/solution/governmentAndEnterprise/part3-icon2.png'),
          img2: require('../../assets/img/solution/governmentAndEnterprise/mobile/part3-icon2.png'),
          imgOver: require('../../assets/img/solution/governmentAndEnterprise/part3-icon2-over.png'),
          span: '政务云',
          p: '业务流程办理和职能服务精简优化、整合',
        },
        {
          img: require('../../assets/img/solution/governmentAndEnterprise/part3-icon3.png'),
          img2: require('../../assets/img/solution/governmentAndEnterprise/mobile/part3-icon3.png'),
          imgOver: require('../../assets/img/solution/governmentAndEnterprise/part3-icon3-over.png'),
          span: '云桌面',
          p: '客户机、智能手机、PAD、PC等多种设备连接使用通过多种网络随时随地访问云桌面',
        },
        // {
        //   img: require('../../assets/img/solution/governmentAndEnterprise/part3-icon4.png'),
        //   img2: require('../../assets/img/solution/governmentAndEnterprise/mobile/part3-icon4.png'),
        //   imgOver: require('../../assets/img/solution/governmentAndEnterprise/part3-icon4-over.png'),
        //   span: '大数据网评系统',
        //   p: '在线咨询、答疑、办公服务管理可视化',
        // },
        {
          img: require('../../assets/img/solution/governmentAndEnterprise/part3-icon5.png'),
          img2: require('../../assets/img/solution/governmentAndEnterprise/mobile/part3-icon5.png'),
          imgOver: require('../../assets/img/solution/governmentAndEnterprise/part3-icon5-over.png'),
          span: '互联网+政务服务',
          p: '传统政务与互联网高度结合实现政务办理和服务互联网化',
        },
        {
          img: require('../../assets/img/solution/governmentAndEnterprise/part3-icon6.png'),
          img2: require('../../assets/img/solution/governmentAndEnterprise/mobile/part3-icon6.png'),
          imgOver: require('../../assets/img/solution/governmentAndEnterprise/part3-icon6-over.png'),
          span: '智慧校园',
          p: '全面的智能感知环境和综合信息服务平台基于角色的个性化定制服务',
        },
      ],
      // p4
      swiperOption2: {
        pagination: {
          el: '.p4_m_mobile .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      p4Data: [
        {
          span: '构建智慧的政企环境',
          p: '结合物联网、云计算、移动互联等技术，打造一个集办公、生活、学习为一体的智慧政企环境。',
        },
        {
          span: '提升服务水平',
          p: '为政企工作人员提供完善的信息服务，使其能够第一时间获取服务信息、人员信息以及资源信息等，助力政企服务活动更好的展开。',
        },
        {
          span: '提升管理水平',
          p: '基于信息化平台实现业务流程优化与管理方法改进，为政企机构开展体质创新提供技术支持。',
        },
        {
          span: '提高决策水平',
          p: '基于数字化政企在对信息进行治理的同时，进行智能化的分析，为科学决策提供技术保障与支撑。',
        },
        {
          span: '提升运维水平',
          p: '完成政企平台业务系统云化建设，提高资源的利用率，完成丰富的咨询服务与办公的功能，同时云化资源的同一运维性可极大降低运维成本和运维难度。',
        },
        {
          span: '提高资源共享效率',
          p: '通过信息化建设将不同的系统平台有效地联系在一起，实现在全校范围内的信息与资源共享，并且能够提高信息传递的速度与效率，最终实现提高政企服务水平与发展效率的目的。',
        },
      ],
      p4Datas: [],
    };
  },
  components: {
    PartFlexSwiper,
    PartCore,
  },
  mounted() {
    // 数据初始化
    this.p2Datas = [
      JSON.parse(JSON.stringify(this.p2Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p2Data.slice(-2))),
    ];
    this.p4Datas = [
      JSON.parse(JSON.stringify(this.p4Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p4Data.slice(2, 4))),
      JSON.parse(JSON.stringify(this.p4Data.slice(-2))),
    ];
    // 状态初始化
    this.p3Start();
  },
  methods: {
    // p3初始化
    p3Start() {
      this.$refs.Part3Core.pCoreMove(0, this.p3Data[0].imgOver);
      this.$refs.Part3Core.pCoreClick(0, this.p3Data[0].img2, this.p3Data[0]);
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 723px;
  position: relative;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > img {
      position: absolute;
      width: 1043px;
      height: 723px;
      z-index: -1;
      top: 0px;
      right: 0px;
    }
  }
  .part1-title {
    width: 538px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 67px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part4 {
  width: 100%;
  padding-top: 93px;
  padding-bottom: 106px;
  background: url('../../assets/img/solution/governmentAndEnterprise/part4-bg.png')
    no-repeat;
  background-size: 100% 100%;
  .part-title {
    color: #005cb9;
    margin-bottom: 90px;
  }
  .p4_main {
    width: 1120px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .p4_item {
      box-sizing: border-box;
      width: 352px;
      height: 311px;
      background: #ffffff;
      border: 1px solid #005cb9;
      box-shadow: 13px 11px 10px 1px rgba(108, 153, 199, 0.06);
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 48px;
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          font-size: 24px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #005cb9;
        }
        p {
          width: 243px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 27px;
          margin-top: 41px;
          text-align: justify;
          display: none;
        }
      }
    }
    .p4_item:hover {
      background: linear-gradient(-29deg, #316ebc 0%, #005cb9 100%);
      border: 2px solid #e2e2e2;
      div {
        span {
          color: #ffffff;
        }
        p {
          display: block;
        }
      }
    }
  }
  .p4_m_mobile {
    display: none;
  }
}
.part5 {
  width: 100%;
  padding-top: 79px;
  padding-bottom: 127px;
  background: url('../../assets/img/solution/governmentAndEnterprise/part5-bg.png')
    no-repeat;
  background-size: 100% 100%;
  .part-title {
    color: #fefefe;
    margin-bottom: 62px;
  }
  img {
    display: block;
    margin: 0 auto;
  }
}
@media screen and (max-width: 768px) {
  /* 第一部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        width: 5.26rem;
        height: 4.28rem;
        top: 0rem;
        right: 0rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
        margin-top: -0.62rem;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part4 {
    padding-top: 0.6rem;
    padding-bottom: 1.28rem;
    background: url('../../assets/img/solution/governmentAndEnterprise/mobile/part4-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 0.6rem;
    }
    .p4_main {
      width: 6.1rem;
      .swiper-item {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 0.1rem;
      }
      .p4_item2 {
        box-sizing: border-box;
        width: 2.76rem;
        height: 2.93rem;
        background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
        border-radius: 0.1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0.4rem;
        span {
          font-size: 21px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 0.15rem;
        }
        p {
          display: block;
          width: 208px;
          font-size: 0.17rem;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.27rem;
          text-align: justify;
        }
      }
    }
    .p4_m_pc {
      display: none;
    }
    .p4_m_mobile {
      display: block;
    }
  }
  .part5 {
    padding-top: 0.54rem;
    padding-bottom: 1.1rem;
    background: url('../../assets/img/solution/governmentAndEnterprise/mobile/part5-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 0.58rem;
    }
    img {
      display: block;
      width: 6.78rem;
      height: 4.25rem;
      margin: 0 auto;
    }
  }
}
</style>
<style lang="less">
@media screen and (max-width: 768px) {
  .governmentAndEnterprise {
    .p4_m_mobile {
      .swiper_bg_white {
        width: 6.1rem;
        margin-top: 0.56rem;
      }
    }
  }
}
</style>
