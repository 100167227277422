<template>
  <div id="mapContainer"></div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  data() {
    return {
      map: null,
      marker: [],
    };
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: '18f6460cd16b698b5f4ffe8a178af122', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map('mapContainer', {
            //设置地图容器id
            viewMode: '3D', //是否为3D地图模式
            zoom: 18, //初始化地图级别
            resizeEnable: true,
            center: [116.482976, 39.99775], //初始化地图中心点位置
          });
          // 创建一个 Marker 实例：
          this.marker = new AMap.Marker({
            position: this.map.getCenter(), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: '百悟科技有限公司',
          });
          // 设置label标签
          // label默认蓝框白底左上角显示，样式className为：amap-marker-label
          this.marker.setLabel({
            offset: new AMap.Pixel(10, 10), //设置文本标注偏移量
            content: "<div class='info'>百悟科技有限公司</div>", //设置文本标注内容
            direction: 'right', //设置文本标注方位
          });

          // 将创建的点标记添加到已有的地图实例：
          this.map.add(this.marker);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  },
};
</script>

<style lang="less">
#mapContainer {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  .amap-icon img {
    width: 25px;
    height: 34px;
  }
  .amap-marker-label {
    border: 0px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
  }
  .info {
    background: white;
    padding: 3px 10px;
  }
  .amap-logo {
    display: none;
    opacity: 0 !important;
  }
  .amap-copyright {
    opacity: 0;
  }
}
</style>
