<template>
  <div class="messageServe">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>短信服务</span>
          <p>
            短信（SMS）发送服务，为企业提供稳定、高效、便捷、安全的通讯能力，整合移动、联通、电信短信资源帮助企业降低通讯成本，实现验证码通知、服务通知、会员通知等发送服务，助力万物互联。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/messageServe/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <!-- TODO 没图片 -->
    <!-- <section class="part2">
      <div class="part2_main">
        <span class="part-title">业务架构功能</span>
        <img
          class="reveal-bottom2"
          src="../../assets/img/product/messageServe/part2-bg.png"
          alt=""
        />
      </div>
    </section> -->
    <section class="part3">
      <img src="../../assets/img/product/messageServe/part3-bg1.png" alt="" />
      <img src="../../assets/img/product/messageServe/part3-bg2.png" alt="" />
      <img src="../../assets/img/product/messageServe/part3-bg3.png" alt="" />
      <img src="../../assets/img/product/messageServe/part3-bg4.png" alt="" />
      <div class="part3_main p3_pc">
        <span class="part-title">短信服务特殊优势</span>
        <div class="p3m-major">
          <div class="p3m-major1 reveal-bottom">
            <div class="p3m-major1-item" v-for="(item, i) in p3Data" :key="i">
              <img :src="item.icon" alt="" />
            </div>
          </div>
          <div class="p3m-major2 reveal-bottom2">
            <div class="p3m-major2-line"></div>
            <div
              class="p3m-major2-item"
              v-for="(item, i) in p3Data"
              :key="i"
              @mouseover="p3Over(i, item.iconOver)"
              @mouseout="p3Out(i, item.icon)"
            ></div>
          </div>
          <div class="p3m-major3 reveal-bottom3">
            <div class="p3m-major3-item" v-for="(item, i) in p3Data" :key="i">
              <span>{{ item.title }}</span>
              <div v-for="(item2, i2) in item.divP" :key="i2">
                <p>{{ item2 }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="part3_main2 p3_mobile">
        <span class="part-title">短信服务特殊优势</span>
        <swiper :options="swiperOption">
          <swiper-slide
            class="swiper-item"
            v-for="(item, i) in p3Datas"
            :key="i"
          >
            <div class="part3-item" v-for="(item2, i2) in item" :key="i2">
              <div>
                <img :src="item2.iconOver" alt="" />
              </div>
              <span>{{ item2.title }}</span>
              <p v-for="(item3, i3) in item2.divP" :key="i3" v-text="item3"></p>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination swiper_bg_blue" slot="pagination"></div>
      </div>
    </section>
    <section class="part4">
      <div class="part4_main">
        <span class="part-title">短信服务场景介绍</span>
        <div class="p4m-major reveal-bottom2 p4_pc">
          <div
            class="p4m-major-item"
            v-for="(item, i) in p4Data"
            :key="i"
            @mouseover="p4Over(i, item.bg)"
            @mouseout="p4Out(i)"
          >
            <img :src="item.icon" alt="" />
            <span>{{ item.title }}</span>
            <ul>
              <li v-for="(item2, i2) in item.ulLi" :key="i2">
                {{ item2 }}
              </li>
            </ul>
          </div>
        </div>
        <div class="p4m-major2 reveal-bottom2 p4_mobile">
          <swiper :options="swiperOption2">
            <swiper-slide
              class="swiper-item"
              v-for="(item, i) in p4Datas"
              :key="i"
            >
              <div
                class="part4-item"
                v-for="(item2, i2) in item"
                :key="i2"
                :style="{
                  background: 'url(' + item2.bg + ') no-repeat',
                  backgroundSize: '100% 100%',
                }"
              >
                <span>{{ item2.title }}</span>
                <ul>
                  <li
                    v-for="(item3, i3) in item2.ulLi"
                    :key="i3"
                    v-text="item3"
                  ></li>
                </ul>
              </div>
            </swiper-slide>
          </swiper>
          <div
            class="swiper-pagination swiper_bg_white"
            slot="pagination"
          ></div>
        </div>
      </div>
    </section>
    <section class="part5">
      <div class="part5_main">
        <span class="part-title">案例墙</span>
        <img
          class="reveal-bottom2"
          src="../../assets/img/product/messageServe/part5-bg.png"
          alt=""
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MessageServe',
  data() {
    return {
      p3Data: [
        {
          icon: require('../../assets/img/product/messageServe/part3-icon1.png'),
          title: '高可用',
          divP: ['5秒到达', '99%以上高到达率', '秒级告警', '智能切换通道'],
          iconOver: require('../../assets/img/product/messageServe/part3-icon1-over.png'),
        },
        {
          icon: require('../../assets/img/product/messageServe/part3-icon2.png'),
          title: '高性能',
          divP: [
            '10000+条/秒',
            '10亿+条/天的处理能力',
            '实时检测',
            '按需灵活扩展',
          ],
          iconOver: require('../../assets/img/product/messageServe/part3-icon2-over.png'),
        },
        {
          icon: require('../../assets/img/product/messageServe/part3-icon3.png'),
          title: '易开发',
          divP: [
            'API简单易用',
            '主流语言Demo语言包',
            '状态报告实时返回',
            '后台功能齐全',
          ],
          iconOver: require('../../assets/img/product/messageServe/part3-icon3-over.png'),
        },
        {
          icon: require('../../assets/img/product/messageServe/part3-icon4.png'),
          title: '资源多样',
          divP: ['支持验证码信息', '会员营销', '服务通知', '会员营销'],
          iconOver: require('../../assets/img/product/messageServe/part3-icon4-over.png'),
        },
      ],
      p3Datas: [],
      // 轮播图参数
      swiperOption: {
        pagination: {
          el: '.part3 .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      p4Data: [
        {
          icon: require('../../assets/img/product/messageServe/part4-icon1.png'),
          title: '验证码短信',
          bg: require('../../assets/img/product/messageServe/part4-item-bg-over1.png'),
          ulLi: ['注册信息', '登录网站', '支付保障', '安全验证'],
        },
        {
          icon: require('../../assets/img/product/messageServe/part4-icon2.png'),
          title: '通知短信',
          bg: require('../../assets/img/product/messageServe/part4-item-bg-over2.png'),
          ulLi: ['订单通知', '支付通知', '告警通知', '快递通知'],
        },
        {
          icon: require('../../assets/img/product/messageServe/part4-icon3.png'),
          title: '营销短信',
          bg: require('../../assets/img/product/messageServe/part4-item-bg-over3.png'),
          ulLi: ['会员营销', '电商大促', '新品推荐', '业务品牌宣传'],
        },
        {
          icon: require('../../assets/img/product/messageServe/part4-icon4.png'),
          title: '告警短信',
          bg: require('../../assets/img/product/messageServe/part4-item-bg-over4.png'),
          ulLi: ['系统异常', '业务异常', '气候异常', '监控异常'],
        },
      ],
      p4Datas: [],
      swiperOption2: {
        pagination: {
          el: '.part4 .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      p4itemBg: require('../../assets/img/product/messageServe/part4-item-bg.png'),
    };
  },
  mounted() {
    this.p3Datas = [
      JSON.parse(JSON.stringify(this.p3Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p3Data.slice(-2))),
    ];
    this.p4Datas = [
      JSON.parse(JSON.stringify(this.p4Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p4Data.slice(-2))),
    ];
  },
  methods: {
    p4Over(i, bg) {
      $('.p4m-major-item').eq(i).stop().animate({ marginTop: '-23px' }, 300);
      $('.p4m-major-item').eq(i).addClass('active');
      $('.p4m-major-item').eq(i).find('img').css('display', 'none');
      $('.p4m-major-item')
        .eq(i)
        .css('background', 'url(' + bg + ') no-repeat');
    },
    p4Out(i) {
      $('.p4m-major-item').eq(i).stop().animate({ marginTop: '0px' }, 300);
      $('.p4m-major-item').eq(i).removeClass('active');
      $('.p4m-major-item').eq(i).find('img').css('display', 'block');
      $('.p4m-major-item')
        .eq(i)
        .css('background', 'url(' + this.p4itemBg + ') no-repeat');
    },
    p3Over(i, iconOver) {
      $('.p3m-major3-item').eq(i).stop().animate({ marginTop: '-16px' }, 300);
      $('.p3m-major1-item').eq(i).addClass('active');
      $('.p3m-major1-item').eq(i).find('img').attr('src', iconOver);
      $('.p3m-major3-item').eq(i).addClass('active');
      $('.part3 > img').eq(i).css('display', 'block');
    },
    p3Out(i, icon) {
      $('.p3m-major3-item').eq(i).stop().animate({ marginTop: '0px' }, 300);
      $('.p3m-major1-item').eq(i).removeClass('active');
      $('.p3m-major1-item').eq(i).find('img').attr('src', icon);
      $('.p3m-major3-item').eq(i).removeClass('active');
      $('.part3 > img').eq(i).css('display', 'none');
    },
  },
};
</script>

<style lang="less" scoped>
/* 头图样式 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      width: 665px;
      height: 707px;
      margin-right: -110px;
      margin-top: 32px;
    }
    .part1-title {
      width: 507px;
      height: 280px;
      span {
        font-family: FontExcellent;
        font-size: 40px;
        color: #333333;
        display: block;
        margin-bottom: 22px;
        letter-spacing: 1px;
      }
      p {
        font-size: 16px;
        color: #666666;
        text-align: justify;
        margin-bottom: 78px;
        line-height: 28px;
      }
      a {
        display: block;
        width: 184px;
        height: 53px;
        background-color: rgb(36, 110, 187);
        border-radius: 7px;
        text-align: center;
        line-height: 53px;
        font-size: 16px;
        color: #f9fbfd;
        letter-spacing: 0.5px;
      }
    }
  }
}
/* @import '../../assets/css/product-shared.css'; */
/* @import '../../assets/css/message-serve.css'; */
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 997px;
  background-color: rgb(249, 249, 252);
  .part2_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    text-align: center;
    padding-top: 78px;
    > span {
      margin-bottom: 43px;
    }
  }
}
.part3 {
  width: 100%;
  position: relative;
  > img {
    position: absolute;
    bottom: -23px;
    z-index: -3;
    display: none;
  }
  > img:nth-of-type(1),
  > img:nth-of-type(3) {
    right: 0;
  }
  > img:nth-of-type(2),
  > img:nth-of-type(4) {
    left: 0;
  }
  .part3_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 78px;
    > span {
      margin-bottom: 79px;
    }
    .p3m-major {
      width: 1100px;
      margin: 0 auto;
      padding-bottom: 90px;
    }
    .p3m-major1 {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      /* padding: 0px 47px; */
      .p3m-major1-item {
        width: 165px;
        height: 165px;
        border-radius: 100%;
        box-shadow: 0 6px 20px 0 rgba(158, 158, 158, 0.12),
          0 6px 20px 0 rgba(158, 158, 158, 0.12);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .p3m-major1-item.active {
        border-radius: 0px;
        box-shadow: 0 0px 0px 0 rgba(158, 158, 158, 0.12),
          0 0px 0px 0 rgba(158, 158, 158, 0.12);
      }
    }
    .p3m-major2 {
      margin-top: 47px;
      position: relative;
      width: 100%;
      height: 26px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .p3m-major2-line {
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #d0d0d0;
        top: 13px;
        left: 0;
        z-index: -1;
      }
      .p3m-major2-item {
        width: 26px;
        height: 26px;
        border-radius: 100%;
        background-color: white;
        box-shadow: 0 6px 20px 0 rgba(158, 158, 158, 0.12),
          0 6px 20px 0 rgba(158, 158, 158, 0.12);
        z-index: 2;
        cursor: pointer;
      }
      .p3m-major2-item.active {
        /* 标准的语法 */
        background-color: rgb(60, 125, 197);
      }
    }
    .p3m-major3 {
      margin-top: 63px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .p3m-major3-item {
        box-sizing: border-box;
        width: 251px;
        height: 275px;
        background-color: white;
        box-shadow: 0 6px 20px 0 rgba(158, 158, 158, 0.06),
          0 6px 20px 0 rgba(158, 158, 158, 0.06);
        border-radius: 17px;
        padding-top: 50px;
        padding-bottom: 55px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        span {
          font-family: FontExcellent;
          font-size: 20px;
          font-weight: 500;
          color: #0f5aa8;
          letter-spacing: 0.5px;
        }
        p {
          font-size: 16px;
          color: #666666;
          letter-spacing: 0.5px;
          line-height: 28px;
        }
      }
      .p3m-major3-item.active {
        /* margin-top: -16px; */
        background-color: rgb(60, 125, 197);
        span {
          color: white;
          font-size: 24px;
        }
        p {
          color: white;
          font-size: 18px;
        }
      }
    }
  }
  .p3_mobile {
    display: none;
  }
}
.part4 {
  box-sizing: border-box;
  width: 100%;
  height: 596px;
  background-color: rgb(250, 251, 253);
  .part4_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    text-align: center;
    padding-top: 78px;
    > span {
      margin-bottom: 68px;
    }
    .p4m-major {
      width: 1158px;
      margin-left: -21px;
      display: flex;
      flex-direction: row;
      .p4m-major-item {
        flex-shrink: 0;
        box-sizing: border-box;
        padding: 102px 38px 104px 25px;
        width: 340px;
        height: 354px;
        background: url('../../assets/img/product/messageServe/part4-item-bg.png')
          no-repeat;
        background-size: 100% 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        margin-left: -65px;
        transform: margin 2s;
        span {
          display: block;
          font-family: FontExcellent;
          font-size: 24px;
          color: #333333;
        }
        ul {
          margin-top: 47px;
          margin-left: 20px;
          display: none;
          li {
            font-size: 18px;
            color: white;
            position: relative;
            line-height: 30px;
            text-align: start;
          }
          li::before {
            content: '';
            display: block;
            position: absolute;
            top: 10px;
            left: -20px;
            width: 7px;
            height: 7px;
            background-color: white;
            border-radius: 100%;
          }
        }
      }
      .p4m-major-item:nth-of-type(1) {
        margin-left: 0px;
        z-index: 4;
      }
      .p4m-major-item:nth-of-type(2) {
        z-index: 3;
      }
      .p4m-major-item:nth-of-type(3) {
        z-index: 2;
      }
      .p4m-major-item:nth-of-type(4) {
        z-index: 1;
      }
      .p4m-major-item:nth-of-type(1) span {
        margin-left: 15px;
        margin-top: 20px;
      }
      .p4m-major-item:nth-of-type(2) img {
        margin-top: -10px;
      }
      .p4m-major-item:nth-of-type(2) span {
        /* margin-left: 15px; */
        margin-top: 10px;
      }
      .p4m-major-item:nth-of-type(3) span {
        margin-left: 15px;
        margin-top: 30px;
      }
      .p4m-major-item:nth-of-type(4) span {
        margin-left: -18px;
        margin-top: 26px;
      }
      .p4m-major-item.active {
        margin-bottom: 0px;
        span {
          margin-top: -20px;
          color: white;
        }
        ul {
          display: block;
        }
      }
      .p4m-major-item:nth-of-type(1).active span {
        margin-left: 0px;
      }
      .p4m-major-item:nth-of-type(2).active span {
        margin-left: 10px;
      }
      .p4m-major-item:nth-of-type(3).active span {
        margin-left: -20px;
      }
      .p4m-major-item:nth-of-type(4).active span {
        margin-left: 10px;
      }
    }
    .p4_mobile {
      display: none;
    }
  }
}
.part5 {
  box-sizing: border-box;
  width: 100%;
  height: 580px;
  background-color: white;
  .part5_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    text-align: center;
    padding-top: 78px;
    > span {
      margin-bottom: 47px;
    }
    img {
      margin-left: -10px;
    }
  }
}
@media screen and (max-width: 768px) {
  .messageServe {
    /* 第一部分 */
    .part1 {
      height: 4.28rem;
      .part1_main {
        width: 6.14rem;
        > img {
          content: url('../../assets/img/product/messageServe/mobile/part1-icon.png');
          width: 3.86rem;
          height: 4.11rem;
          margin-top: 0.17rem;
          margin-right: 0.01rem;
        }
      }
      .part1-title {
        width: auto;
        height: auto;
        span {
          font-size: 0.4rem;
          margin-bottom: 0px;
          margin-top: -0.62rem;
        }
        p,
        a {
          display: none;
        }
      }
    }
    .part2 {
      height: 5.67rem;
      .part2_main {
        width: 100%;
        padding-top: 0.3rem;
        > span {
          margin-bottom: 0.3rem;
        }
        img {
          height: 3.98rem;
        }
      }
    }
    .part3 {
      background: url('../../assets/img/product/messageServe/mobile/part3-bg.png')
        no-repeat;
      background-size: 100% 100%;
      padding-bottom: 1.01rem;
      > img {
        display: none;
      }
      .part3_main2 {
        width: 100%;
        padding-top: 0.42rem;
        .part-title {
          color: #ffffff;
          margin-bottom: 0.58rem;
        }
        .swiper-item {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
        }
        .part3-item {
          box-sizing: border-box;
          width: 2.71rem;
          height: 4.16rem;
          background: #ffffff;
          border-radius: 0.07rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 0.4rem;
          div {
            height: 1.45rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.4rem;
            img {
              max-height: 100%;
            }
          }
          span {
            font-size: 0.18rem;
            font-weight: bold;
            color: #333333;
            margin-bottom: 0.19rem;
          }
          p {
            font-size: 0.16rem;
            font-weight: 400;
            color: #333333;
            line-height: 0.27rem;
          }
        }
      }
      .p3_pc {
        display: none;
      }
      .p3_mobile {
        display: block;
      }
    }
    .part4 {
      height: 5.44rem;
      background: #ffffff;
      .part4_main {
        width: 6.8rem;
        padding-top: 0.4rem;
        > span {
          margin-bottom: 0.3rem;
        }
        .p4m-major2 {
          width: 100%;
          .swiper-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          .part4-item {
            box-sizing: border-box;
            width: 3.4rem;
            height: 3.54rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 0.85rem;
            span {
              font-size: 0.18rem;
              font-weight: bold;
              color: #ffffff;
              margin-bottom: 0.34rem;
            }
            ul {
              width: 1rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              li {
                font-size: 0.16rem;
                font-weight: 400;
                color: #ffffff;
                line-height: 0.27rem;
                position: relative;
              }
              li::after {
                display: block;
                content: '';
                position: absolute;
                top: 0.09rem;
                left: -0.2rem;
                width: 0.09rem;
                height: 0.09rem;
                background: #ffffff;
                border-radius: 50%;
              }
            }
          }
        }
        .p4_pc {
          display: none;
        }
        .p4_mobile {
          display: block;
        }
      }
    }
    .part5 {
      height: 3.24rem;
      .part5_main {
        width: 100%;
        padding-top: 0.1rem;
        > span {
          margin-bottom: 0.3rem;
        }
        img {
          width: 6.72rem;
          margin-left: 0px;
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
.messageServe {
  .swiper_bg_blue {
    width: 7.5rem;
  }
  .swiper_bg_white {
    width: 6.8rem;
    margin-top: 0.2rem;
  }
}
</style>
