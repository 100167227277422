<template>
  <div class="InternationalSms">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>国际短信</span>
          <p>
            国际短信（International
            SMS）是指国内客户的出海企业与其他国家或地区运营商的用户之间发送和接收短消息的业务。不受时间、空间和地点的限制。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/internationalSms/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <span class="part-title">国际验证码短信功能介绍</span>
      <div class="part2_main">
        <p class="reveal-bottom">
          百悟科技为用户提供简单、迅速、低成本的国际短信发送能力。只需接入国际短信网关就能给全球各国手机号发送信息，真正做到一点接入全球覆盖！
        </p>
        <img
          class="reveal-bottom2"
          src="../../assets/img/product/internationalSms/part2-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part3">
      <span class="part-title">国际短信消息架构图</span>
      <img
        class="reveal-bottom2"
        src="../../assets/img/product/internationalSms/part3-icon.png"
        alt=""
      />
    </section>
    <section class="part4">
      <span class="part-title">国际短信特色优势</span>
      <div class="part4_main reveal-bottom2">
        <div
          class="part4-item"
          v-for="(item, index) in part4Data"
          :key="index"
          @mousemove="part4Item(index, item.imgOver)"
          @mouseout="part4Item(index, item.img)"
        >
          <div>
            <img :src="item.img" alt="" />
          </div>
          <span>{{ item.title }}</span>
          <p>{{ item.content }}</p>
        </div>
      </div>
      <div class="part4_main p4-swiper">
        <swiper :options="swiperOption">
          <swiper-slide
            v-for="(item, i) in part4Datas"
            :key="i"
            class="swiper-item"
          >
            <div
              class="part4-item"
              v-for="(item2, i2) in item"
              :key="i2"
              @click="swiperP4Item($event, item2)"
            >
              <div>
                <img :src="item2.img" alt="" />
              </div>
              <span>{{ item2.title }}</span>
              <p>{{ item2.content }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-pagination p4m-pagination swiper_bg_blue"
          slot="pagination"
        ></div>
      </div>
    </section>
    <section class="part5">
      <span class="part-title">国际短信场景应用</span>
      <div class="part5_main">
        <div class="part5-up reveal-bottom2">
          <div
            class="part5-uItem"
            v-for="(item, index) in part5UData"
            :key="index"
            @click="itemChance(index)"
          >
            <div>
              <img :src="item.img" alt="" />
            </div>
            <span>{{ item.span }}</span>
          </div>
        </div>
        <img
          class="reveal-bottom2"
          src="../../assets/img/product/internationalSms/part5-arrow.png"
          alt=""
        />
        <div class="part5-down reveal-bottom2">
          <div
            class="part5-dItem"
          >
            <div class="part5-dILeft">
              <div>
                <span>{{ p5DItem.lSpan }}</span>
                <img :src="p5DItem.lImg" alt="" />
                <p>{{ p5DItem.lP }}</p>
              </div>
            </div>
            <div class="part5-dIRight">
              <h6>场景方案和优势</h6>
              <div v-for="(item, index) in p5DItem.rContent" :key="index">
                <span>{{ item.rSpan }}</span>
                <p v-html="item.rP"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="part6">
      <p>国际短信价格咨询请联系客服</p>
      <img
        src="../../assets/img/product/internationalSms/part6-icon.png"
        alt=""
      />
      <div class="zhiCustomBtn">
        <p>立即联系客服！</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      part4Data: [
        {
          img: require('../../assets/img/product/internationalSms/part4-icon1.png'),
          imgOver: require('../../assets/img/product/internationalSms/part4-icon1-over.png'),
          title: '全球范围覆盖',
          content: '覆盖 200+ 个国家和地区，1000+ 运营商网关，支持多国语言',
        },
        {
          img: require('../../assets/img/product/internationalSms/part4-icon2.png'),
          imgOver: require('../../assets/img/product/internationalSms/part4-icon2-over.png'),
          title: '实时分析报告',
          content: '实时追踪国际短信的回执状态，生成可视化图表分析报告',
        },
        {
          img: require('../../assets/img/product/internationalSms/part4-icon3.png'),
          imgOver: require('../../assets/img/product/internationalSms/part4-icon3-over.png'),
          title: '优质通道布局',
          content: '专用国际短信通道高效响应，实时监控通道运行，低延时高速响应',
        },
        {
          img: require('../../assets/img/product/internationalSms/part4-icon4.png'),
          imgOver: require('../../assets/img/product/internationalSms/part4-icon4-over.png'),
          title: '发送灵活便捷',
          content:
            'API，实现集成发送；也可随时随地登录网页，在线编辑发送。充分满足用户的不同需求，高效、稳定、灵活',
        },
      ],
      part5UData: [
        {
          img: require('../../assets/img/product/internationalSms/part5-uIcon1.png'),
          span: '出海金融',
        },
        {
          img: require('../../assets/img/product/internationalSms/part5-uIcon2.png'),
          span: '跨境电商',
        },
        {
          img: require('../../assets/img/product/internationalSms/part5-uIcon3.png'),
          span: '移动社交',
        },
        {
          img: require('../../assets/img/product/internationalSms/part5-uIcon4.png'),
          span: '在线教育',
        },
      ],
      part5DData: [
        {
          lBg: require('../../assets/img/product/internationalSms/part5-dLBg1.png'),
          rBg: require('../../assets/img/product/internationalSms/part5-dRBg1.png'),
          lImg: require('../../assets/img/product/internationalSms/part5-dLIcon1.png'),
          lSpan: '出海金融全流程通信服务解决方案',
          lP: '为金融、跨国银行等客户提供属地化的一站式通信服务解决方案，助力各类金融机构实现业务的高速增长',
          rContent: [
            {
              rSpan: '推广期',
              rP: '通过短信的形式把产品介绍、注册福利等信息精准发送给用户，引导客户了解并下载注册产品，快速获客、抢占市场。',
            },
            {
              rSpan: '注册期',
              rP: '使用短信方式为用户提供注册、登录以及修改密码所需要的一次性验证码，安全保密，方便快捷。',
            },
            {
              rSpan: '放款期',
              rP: '通过短信的形式，告知贷款人贷款成功、放贷金额、贷款期限、还款日期等信息。',
            },
            {
              rSpan: '催收期',
              rP: 'S0：催收短信<br>S1：通知<br>短信分阶段、多形式催收，给予客户紧迫感，提升还款效率。',
            },
            {
              rSpan: '召回期',
              rP: '针对已经按期还款的优质客户，通过短信的形式，发送贷后关怀以及续贷福利，促成二次合作。',
            },
          ],
        },
        {
          lBg: require('../../assets/img/product/internationalSms/part5-dLBg2.png'),
          rBg: require('../../assets/img/product/internationalSms/part5-dRBg2.png'),
          lImg: require('../../assets/img/product/internationalSms/part5-dLIcon2.png'),
          lSpan:
            '为跨境电商提供从用户拉新到注册登录，再到售后服务的一站式解决方案',
          lP: '百悟科技拥有布局全球的优质通信供应商线路，同时具备高效、灵活的系统研发能力，可帮助跨境电商布局全球市场',
          rContent: [
            {
              rSpan: '用户拉新',
              rP: '策划拉新活动，通过营销短信的方式把电商平台介绍与新用户注册福利等信息精准传递给消费者，引导用户了解并体验跨境电商平台，实现快速获客、抢占海外市场。',
            },
            {
              rSpan: '用户注册与登录',
              rP: '使用短信、邮件等方式为用户提供注册、登录以及修改密码所需要的一次性验证码，可根据不同国家和地区用户的使用习惯提供不一样的验证方式，安全保密，方便快捷，同时提升了用户体验感。',
            },
            {
              rSpan: '新品推荐与用户召回',
              rP: '使用营销短信的方式，对已在平台购买过商品的客户进行二次营销，发送新品推介及复购优惠券，吸引用户复购，培养用户使用习惯。',
            },
            {
              rSpan: '增加用户留存',
              rP: '在用户完善个人信息处设置生日填写选项，在用户生日以及当地重要节日时，利用通知短信为用户送上生日和节日祝福，打造一个有温度的电商平台，提升用户体验感，增加用户留存。',
            },
          ],
        },
        {
          lBg: require('../../assets/img/product/internationalSms/part5-dLBg3.png'),
          rBg: require('../../assets/img/product/internationalSms/part5-dRBg3.png'),
          lImg: require('../../assets/img/product/internationalSms/part5-dLIcon3.png'),
          lSpan: '精准解决移动社交产品拉新难、留存难、转化难的问题',
          lP: '利用云通信产品帮助社交软件快速开拓市场，解决用户信任问题，快速获客，提升留存率和转化率',
          rContent: [
            {
              rSpan: '使用营销短信拓客拉新，提升下载量',
              rP: '通过营销短信的方式把社交软件介绍与新用户注册福利等信息精准发送给用户，引导用户下载并体验，实现快速获客、抢占海外移动社交市场。',
            },
            {
              rSpan: '一次性验证码',
              rP: '使用短信、邮件等方式为用户提供注册、登录以及修改密码、异地登录所需要的一次性验证码，可根据不同国家和地区用户的使用习惯提供不一样的验证方式，安全保密，方便快捷。',
            },
            {
              rSpan: '交易提醒与节日祝福',
              rP: '当用户购买平台会员或者进行账户充值成功后，使用通知短信告知用户交易成功，给予用户安全感。当用户生日或者当地重大节日来临时，使用通知短信送上节日祝福，打造一个有温度的社交平台，提升用户体验感。',
            },
          ],
        },
        {
          lBg: require('../../assets/img/product/internationalSms/part5-dLBg4.png'),
          rBg: require('../../assets/img/product/internationalSms/part5-dRBg4.png'),
          lImg: require('../../assets/img/product/internationalSms/part5-dLIcon4.png'),
          lSpan: '为在线教育提供闭环式、全流程的通信服务',
          lP: '为在线教育平台提供从用户拉新—注册登录—课程咨询—提醒服务—用户召回的全流程服务',
          rContent: [
            {
              rSpan: '用户拉新',
              rP: '通过营销短信的方式把在线教育平台的服务介绍以及新用户注册福利等信息精准传递给消费者，引导消费者下载并体验，快速获客、抢占海外市场。',
            },
            {
              rSpan: '用户注册与登录',
              rP: '使用短信、邮件等方式为用户提供注册、登录以及修改密码所需要的一次性验证码，可根据不同国家和地区用户的使用习惯提供不一样的验证方式，安全保密，方便快捷。',
            },
            {
              rSpan: '提醒服务',
              rP: '当用户购买课程成功后，使用通知短信告知用户交易成功，给予用户安全感；使用语音群呼或通知短信，告知用户上课时间，提醒用户按时上课，为客户提供管家式服务。',
            },
          ],
        },
      ],
      p5DItem: {
      },
      part4Datas: [],
      // 轮播图参数
      swiperOption: {
        pagination: {
          el: '.p4-swiper .p4m-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false, // 手动切换之后继续自动轮播
        // },
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
    };
  },
  mounted() {
    // 刷新页面
    if (location.href.indexOf('#reloaded') == -1) {
      location.href = location.href + '#reloaded';
      location.reload();
    }
    // 初始化
    $('.part5-uItem').eq(0).find('span').css('color', '#1267BC');
    this.part5DData.forEach((item, index) => {
      $('.part5-dItem')
        .eq(index)
        .find('.part5-dILeft')
        .css('background', `url(${item.lBg}) no-repeat`);
      $('.part5-dItem')
        .eq(index)
        .find('.part5-dIRight')
        .css('background', `url(${item.rBg}) no-repeat`);
    });
    if ($(window).width() > 768) {
      $('.part5-dItem').css('display', 'flex');
    } else {
      $('.part5-dItem').css('display', 'block');
    }
    $(window).resize(function () {
      if ($(window).width() > 768) {
        $('.part5-dItem').css('display', 'flex');
      } else {
        $('.part5-dItem').css('display', 'block');
      }
    });
    // 内容初始化
    this.part4Datas = [
      JSON.parse(JSON.stringify(this.part4Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.part4Data.slice(-2))),
    ];
    this.p5DItem = this.part5DData[0]
  },
  methods: {
    part4Item(index, img) {
      $('.part4-item').eq(index).find('img').attr('src', img);
    },
    // 移动端
    swiperP4Item(e, item) {
      $('.p4-swiper .part4-item').each((index, item2) => {
        this.part4Data.find((item3) => {
          if (item3.title == $(item2).children('span').text()) {
            $(item2).find('img').attr('src', item3.img);
          }
        });
      });
      $('.p4-swiper .part4-item').removeClass('active');
      $(e.currentTarget).addClass('active');
      $(e.currentTarget).find('img').attr('src', item.imgOver);
    },
    itemChance(index) {
      this.p5DItem = this.part5DData[index]
      $('.part5-uItem').find('span').css('color', '#333333');
      if ($(window).width() > 768) {
        $('.part5-dItem').css('display', 'flex');
        $('.part5_main > img').animate({ left: 120 + index * 282 + 'px' });
      } else {
        $('.part5-dItem').css('display', 'block');
      }
      $('.part5-uItem').eq(index).find('span').css('color', '#1267BC');
    },
  },
};
</script>

<style lang="less" scoped>
/* 头图样式 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    img {
      position: absolute;
      width: 628px;
      height: 679px;
      z-index: -1;
      top: 1px;
      right: -70px;
    }
  }
  .part1-title {
    width: 526px;
    height: 280px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 78px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 997px;
  background: url('../../assets/img/product/internationalCode/part2-bg.png')
    no-repeat;
  background-size: 100% 100%;
  padding-top: 79px;
  .part-title {
    margin-bottom: 45px;
  }
  .part2_main {
    width: 1111px;
    margin: 0 auto;
    text-align: center;
    p {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      text-align: start;
      line-height: 28px;
      margin-bottom: 76px;
    }
  }
}
.part3 {
  box-sizing: border-box;
  width: 100%;
  height: 719px;
  padding-top: 93px;
  text-align: center;
  .part-title {
    margin-bottom: 59px;
  }
}
.part4 {
  box-sizing: border-box;
  width: 100%;
  height: 768px;
  background: url('../../assets/img/product/internationalCode/part4-bg.png')
    no-repeat;
  background-size: 100% 100%;
  padding-top: 112px;
  .part-title {
    color: white;
    margin-bottom: 92px;
  }
  .part4_main {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .part4-item {
      box-sizing: border-box;
      width: 250px;
      height: 390px;
      background: #ffffff;
      box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 49px;
      div {
        width: 100%;
        height: 80px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 14px;
      }
      span {
        font-size: 18px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
      }
      p {
        display: block;
        width: 172px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        text-align: justify;
        line-height: 25px;
      }
    }
    .part4-item:hover {
      background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
      border: 2px solid #ffffff;
      box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
      span,
      p {
        color: #ffffff;
      }
    }
  }
  .part4_main:nth-of-type(2) {
    display: none;
  }
}
.part5 {
  box-sizing: border-box;
  width: 100%;
  height: 1143px;
  padding-top: 117px;
  .part-title {
    margin-bottom: 94px;
  }
  .part5_main {
    width: 1126px;
    margin: 0 auto;
    position: relative;
    > img {
      position: absolute;
      top: 159px;
      left: 120px;
    }
    .part5-up {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .part5-uItem {
        width: 82px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        div {
          height: 65px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 28px;
        }
        span {
          font-size: 18px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #333333;
          letter-spacing: 1.6px;
        }
      }
      .part5-uItem::after {
        display: block;
        content: '';
        width: 104px;
        height: 1px;
        background: #b6b6b6;
        position: absolute;
        bottom: 10px;
        left: 129px;
      }
      .part5-uItem:last-child::after {
        display: none;
      }
    }
    .part5-down {
      margin-top: 80px;
      width: 150%;
      .part5-dItem {
        width: 100%;
        flex-direction: row;
        display: none;
        .part5-dILeft {
          box-sizing: border-box;
          width: 594px;
          height: 672px;
          background-size: 100% 100% !important;
          div {
            box-sizing: border-box;
            width: 534px;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 44px;
            span {
              display: block;
              width: 425px;
              font-size: 24px;
              font-family: FontExcellent;
              font-weight: 400;
              color: #ffffff;
              text-align: justify;
              margin-bottom: 58px;
            }
            p {
              margin-top: 41px;
              margin-bottom: 25px;
              display: block;
              width: 425px;
              font-size: 16px;
              font-weight: 400;
              color: #ffffff;
              text-align: justify;
              line-height: 24px;
            }
            a {
              display: block;
              width: 425px;
              text-decoration: none;
              font-size: 14px;
              font-family: FontExcellent;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
        .part5-dIRight {
          box-sizing: border-box;
          width: 685px;
          height: 667px;
          z-index: -1;
          margin-top: -5px;
          margin-left: -113px;
          background-size: 100% 100% !important;
          padding-top: 58px;
          padding-left: 93px;
          h6 {
            font-size: 18px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #1267bc;
            margin-bottom: 15px;
          }
          div {
            margin-left: 28px;
            width: 454px;
            position: relative;
            span {
              font-size: 18px;
              font-family: FontExcellent;
              font-weight: 400;
              color: #333333;
              margin-bottom: 5px;
            }
            p {
              font-size: 16px;
              font-weight: 400;
              color: #333333;
              line-height: 24px;
              margin-bottom: 15px;
            }
          }
          div::after {
            display: block;
            content: '';
            width: 7px;
            height: 7px;
            background: #1266bb;
            border-radius: 50%;
            position: absolute;
            top: 8px;
            left: -28px;
          }
        }
      }
    }
  }
}
.part6 {
  box-sizing: border-box;
  width: 100%;
  height: 210px;
  background: url('../../assets/img/product/internationalSms/part6-bg.png')
    no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > p {
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    margin-right: 14px;
  }
  > img {
    margin-right: 18px;
  }
  div {
    width: 216px;
    height: 50px;
    background: #1266bb;
    border: 2px solid #ffffff;
    border-radius: 11px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    p {
      font-size: 18px;
      font-family: FontExcellent;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
@media screen and (max-width: 768px) {
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        width: 4.12rem;
        height: 4.45rem;
        top: -0.18rem;
        right: -0.2rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part2 {
    height: 9.05rem;
    padding-top: 0.32rem;
    .part-title {
      margin-bottom: 0.38rem;
    }
    .part2_main {
      width: 6.2rem;
      p {
        font-size: 0.18rem;
        line-height: 0.28rem;
        margin-bottom: 0.77rem;
      }
      img {
        height: 5.79rem;
      }
    }
  }
  .part3 {
    height: 4.31rem;
    padding-top: 0.36rem;
    .part-title {
      margin-bottom: 0.44rem;
    }
    img {
      height: 2.42rem;
    }
  }
  .part4 {
    height: 6.69rem;
    padding-top: 0.56rem;
    background: url('../../assets/img/product/whatsAppBusiness/mobile/part3-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 0.58rem;
    }
    .part4_main {
      width: 5.68rem;
      .swiper-item {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 0.2rem;
      }
      .part4-item {
        width: 2.5rem;
        height: 3.9rem;
        box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.1);
        border-radius: 0.1rem;
        padding-top: 0.49rem;
        div {
          height: 0.8rem;
          margin-bottom: 0.14rem;
          img {
            height: 0.8rem;
          }
        }
        span {
          font-size: 0.18rem;
          margin-bottom: 0.2rem;
        }
        p {
          width: 1.72rem;
          font-size: 0.16rem;
          line-height: 0.25rem;
        }
      }
      .part4-item:hover {
        background: #ffffff;
        border: 0rem solid #ffffff;
        box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0);
        span,
        p {
          color: #333333;
        }
      }
      .part4-item.active {
        background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
        border: 0.02rem solid #ffffff;
        box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.1);
        span,
        p {
          color: #ffffff;
        }
      }
    }
    .part4_main:nth-of-type(1) {
      display: none;
    }
    .part4_main:nth-of-type(2) {
      display: block;
    }
  }
  .part5 {
    height: 15.78rem;
    padding-top: 0.56rem;
    .part-title {
      margin-bottom: 0.74rem;
    }
    .part5_main {
      width: 6rem;
      margin: 0 auto;
      position: relative;
      > img {
        display: none;
      }
      .part5-up {
        justify-content: space-between;
        .part5-uItem {
          width: 0.82rem;
          div {
            height: 0.65rem;
            margin-bottom: 0.28rem;
            img {
              height: 0.65rem;
            }
          }
          span {
            font-size: 0.18rem;
            letter-spacing: 0.016rem;
          }
        }
        .part5-uItem::after {
          width: 0.7rem;
          height: 1px;
          bottom: 0.1rem;
          left: 0.92rem;
        }
        .part5-uItem:last-child::after {
          display: none;
        }
      }
      .part5-down {
        margin-top: 0.64rem;
        width: 100%;
        .part5-dItem {
          width: 100%;
          flex-direction: column;
          display: none;
          .part5-dILeft {
            width: 5.94rem;
            height: 6.72rem;
            margin-left: 0.32rem;
            div {
              width: 5.34rem;
              padding-top: 0.44rem;
              span {
                width: 4.25rem;
                font-size: 0.24rem;
                margin-bottom: 0.58rem;
              }
              img {
                width: 4.21rem;
              }
              p {
                margin-top: 0.41rem;
                margin-bottom: 0.25rem;
                width: 4.25rem;
                font-size: 0.16rem;
                line-height: 0.24rem;
              }
              a {
                width: 4.25rem;
                font-size: 0.14rem;
              }
            }
          }
          .part5-dIRight {
            width: 6.85rem;
            height: 6.67rem;
            margin-top: -0.43rem;
            margin-left: -0.19rem;
            padding-top: 0.58rem;
            padding-left: 0.93rem;
            h6 {
              font-size: 0.18rem;
              margin-bottom: 0.15rem;
            }
            div {
              margin-left: 0.28rem;
              width: 4.54rem;
              span {
                display: block;
                font-size: 0.18rem;
                margin-bottom: 0.06rem;
              }
              p {
                font-size: 0.16rem;
                line-height: 0.28rem;
                margin-bottom: 0.03rem;
              }
            }
            div::after {
              width: 0.07rem;
              height: 0.07rem;
              top: 0.08rem;
              left: -0.28rem;
            }
          }
        }
      }
    }
  }
  .part6 {
    height: 2.1rem;
    background: url('../../assets/img/product/internationalSms/part6-bg.png')
      no-repeat;
    background-size: 100% 100%;
    margin-top: 0.3rem;
    > p {
      font-size: 0.18rem;
      margin-right: 0.14rem;
    }
    > img {
      width: 0.23rem;
      height: 0.18rem;
      margin-right: 0.18rem;
    }
    div {
      width: 2.16rem;
      height: 0.5rem;
      border: 0.02rem solid #ffffff;
      border-radius: 0.11rem;
      line-height: 0.5rem;
      p {
        font-size: 0.18rem;
      }
    }
  }
}
</style>
<style lang="less">
// 轮播图
// p4
.p4-swiper .swiper_bg_blue {
  width: 5.68rem;
  margin-top: 0.48rem;
}
</style>
