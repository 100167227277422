<template>
  <div class="message5G">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>5G消息</span>
          <p>
            5G消息是5G时代基于GSMA统一标准的基础通信业务，是基于短信原生入口的迭代升级，能够实现消息富媒体化；5G消息以企业chatbot为窗口可完成搜索、发现、交互、支付等一站式业务体验，构建了全新的消息生态。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/5GMessage/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <span class="part-title">5G消息功能介绍</span>
      <div class="part2_up reveal-bottom3">
        <div class="p2-main">
          <p>
            5G消息是5G时代基于GSMA统一标准的基础通信业务，是基于短信原生入口的迭代升级，实现消息富媒体化；是5G商用最快落地的生态应用之一。终端用户无需下载客户端借助MaaP能力即可实现消息即服务的交互化体验。5G行业消息以企业chatbot为窗口可完成搜索、发现、交互、支付等一站式业务体验，构建了全新的消息生态。
          </p>
          <div class="p2-uMain">
            <div class="p2-uItem" v-for="(item, i) in part2UData" :key="i">
              <img :src="item.img" alt="" />
              <p v-text="item.p"></p>
            </div>
          </div>
          <div class="p2-uMain message5GP2-swiper">
            <swiper :options="swiperOption">
              <swiper-slide
                class="swiper-item"
                v-for="(item, i) in part2UDatas"
                :key="i"
              >
                <div class="p2-uItem" v-for="(item2, i2) in item" :key="i2">
                  <img :src="item2.img" alt="" />
                  <p v-text="item2.p"></p>
                </div>
              </swiper-slide>
            </swiper>
            <div
              class="swiper-pagination p2Um-pagination swiper_bg_white"
              slot="pagination"
            ></div>
          </div>
        </div>
      </div>
      <div class="part2_down reveal-bottom3">
        <div class="p2-main">
          <div class="part2-dSide">
            <div
              class="p2-dItem"
              v-for="(item, i) in part2DData.slice(0, 3)"
              :key="i"
              @click="p2DChance($event)"
            >
              <span v-text="item"></span>
            </div>
          </div>
          <div class="part2-dMain message5GP2">
            <img
              v-for="(item, i) in part2DDataImg"
              :key="i"
              :src="item"
              alt=""
            />
          </div>
          <div class="part2-dMain message5GP2-swiper">
            <swiper :options="swiperOption2">
              <swiper-slide
                class="swiper-item"
                v-for="(item, i) in part2DDataImg"
                :key="i"
              >
                <img :src="item" alt="" />
              </swiper-slide>
            </swiper>
            <div
              class="swiper-pagination p2Um-pagination swiper_bg_white"
              slot="pagination"
            ></div>
          </div>
          <div class="part2-dSide">
            <div
              class="p2-dItem"
              v-for="(item, i) in part2DData.slice(-3)"
              :key="i"
              @click="p2DChance($event)"
            >
              <span v-text="item"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="part3">
      <span class="part-title">5G消息架构图</span>
      <img
        class="reveal-bottom3"
        src="../../assets/img/product/5GMessage/part3-icon.png"
        alt=""
      />
    </section>
    <section class="part4">
      <span class="part-title">5G消息特色优势</span>
      <div class="part4_main reveal-bottom3 message5GP4">
        <div
          class="part4-item"
          v-for="(item, i) in part4Data"
          :key="i"
          @mousemove="p4Move(i, item.imgOver, (chance = 1))"
          @mouseout="p4Move(i, item.img, (chance = 2))"
        >
          <div>
            <img :src="item.img" alt="" />
          </div>
          <span v-text="item.span"></span>
          <p v-text="item.p"></p>
        </div>
      </div>
      <div class="part4_main message5GP4-swiper">
        <swiper :options="swiperOption3">
          <swiper-slide
            class="swiper-item"
            v-for="(item, i) in part4Datas"
            :key="i"
          >
            <div
              class="part4-item"
              v-for="(item2, i2) in item"
              :key="i2"
              @click="p4mClick($event, item2)"
            >
              <div>
                <img :src="item2.img" alt="" />
              </div>
              <span v-text="item2.span"></span>
              <p v-text="item2.p"></p>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-pagination p4m-pagination swiper_bg_blue"
          slot="pagination"
        ></div>
      </div>
    </section>
    <section class="part5">
      <div class="part5_main">
        <span class="part-title">5G消息场景应用</span>
        <div class="p5m-major">
          <img
            src="../../assets/img/product/5GMessage/part5-img-bg.png"
            alt=""
          />
          <div class="p5m-major-top reveal-bottom3">
            <div class="p5m-major-topLine"></div>
            <div
              class="p5m-major-topItem"
              v-for="(item, i) in p5data"
              :key="i"
              @click="p5TopAction(i, item.topIconOver)"
            >
              <img :src="item.topIcon" alt="" />
              <span>{{ item.topText }}</span>
            </div>
          </div>
          <div class="p5m-major-bottom reveal-bottom3">
            <div
              class="p5m-major-bottomItem"
              v-for="(item, i) in p5data"
              :key="i"
            >
              <div class="p5m-major-bottomLeft">
                <img
                  v-for="(item2, i2) in item.bottomImg"
                  :key="i2"
                  :src="item2"
                  alt=""
                />
                <div v-if="item.bottomImg.length <= 1" class="mobileImg">
                  <img
                    v-for="(item2, i2) in item.bottomImg"
                    :key="i2"
                    :src="item2"
                    alt=""
                  />
                </div>
                <el-carousel
                  trigger="click"
                  :interval="5000"
                  arrow="never"
                  v-else
                  class="mobileImg"
                >
                  <el-carousel-item
                    v-for="(item2, i2) in item.bottomImg"
                    :key="i2"
                  >
                    <img :src="item2" alt="" />
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="p5m-major-bottomRight">
                <div class="p5m-firt-div">
                  <p v-for="(item2, i2) in item.bottomContent" :key="i2">
                    {{ item2 }}
                  </p>
                </div>
                <div class="p5m-last-div">
                  <div>
                    <span v-for="(item2, i2) in item.bottomSpan" :key="i2">
                      {{ item2 }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="part6">
      <div class="part6_main">
        <p>群发：0.2元/条&nbsp;&nbsp;会话：0.2元/5个会话</p>
        <img src="../../assets/img/product/5GMessage/part6-icon.png" alt="" />
        <span class="zhiCustomBtn">立即联系客服！</span>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      part2UData: [
        {
          img: require('../../assets/img/product/5GMessage/part2-uIcon1.png'),
          p: '视频消息',
        },
        {
          img: require('../../assets/img/product/5GMessage/part2-uIcon2.png'),
          p: '多图文消息',
        },
        {
          img: require('../../assets/img/product/5GMessage/part2-uIcon3.png'),
          p: '位置服务',
        },
        {
          img: require('../../assets/img/product/5GMessage/part2-uIcon4.png'),
          p: '音频消息',
        },
        {
          img: require('../../assets/img/product/5GMessage/part2-uIcon5.png'),
          p: '互动消息',
        },
      ],
      part2UDatas: [],
      part2DData: [
        '文本消息',
        '多卡片消息',
        '消息回落',
        '悬浮菜单',
        '智能交互',
        '文件消息',
      ],
      part2DDataImg: [
        require('../../assets/img/product/5GMessage/part2-dIcon1.png'),
        require('../../assets/img/product/5GMessage/part2-dIcon2.png'),
        require('../../assets/img/product/5GMessage/part2-dIcon3.png'),
        require('../../assets/img/product/5GMessage/part2-dIcon4.png'),
      ],
      part4Data: [
        {
          img: require('../../assets/img/product/5GMessage/part4-icon1.png'),
          imgOver: require('../../assets/img/product/5GMessage/part4-icon1-over.png'),
          span: '全新“跳代”应用体验',
          p: '客户品牌实名图文展示，消息菜单、轮播卡片、实时位置、创建代办等丰富应用场景，同时支持图片、音视频等多媒体消息',
        },
        {
          img: require('../../assets/img/product/5GMessage/part4-icon2.png'),
          imgOver: require('../../assets/img/product/5GMessage/part4-icon2-over.png'),
          span: '信息更安全',
          p: '企业、个人双向实名制端口，革新行业短信体验，建立企业用户之间信任，留存用户',
        },
        {
          img: require('../../assets/img/product/5GMessage/part4-icon3.png'),
          imgOver: require('../../assets/img/product/5GMessage/part4-icon3-over.png'),
          span: '无需下载/订阅',
          p: '5G消息具备短信的安全性和及时性。增加企业新流量入口。实现业务高频交互操作，浅入口的产品特性引流至企业实现业务营收',
        },
        {
          img: require('../../assets/img/product/5GMessage/part4-icon4.png'),
          imgOver: require('../../assets/img/product/5GMessage/part4-icon4-over.png'),
          span: '降低企业会员营销门槛',
          p: '短信链接与内容经过运营商直审才能通过，信任度高，高点击率，低投诉率',
        },
        {
          img: require('../../assets/img/product/5GMessage/part4-icon5.png'),
          imgOver: require('../../assets/img/product/5GMessage/part4-icon5-over.png'),
          span: '效果追踪',
          p: '5G消息平台可对下发的信息进行效果追踪。在企业账户窗口用户可完成业务全流程操作',
        },
        {
          img: require('../../assets/img/product/5GMessage/part4-icon6.png'),
          imgOver: require('../../assets/img/product/5GMessage/part4-icon6-over.png'),
          span: '多能力扩展融合',
          p: '5G消息包含多种通信系统和扩展功能接口，开发性的生态可利用人工智能、云计算等能力，整合 5G 通话、AR/VR、直播等应用，为企业打造 “5G+” 平台',
        },
        {
          img: require('../../assets/img/product/5GMessage/part4-icon7.png'),
          imgOver: require('../../assets/img/product/5GMessage/part4-icon7-over.png'),
          span: 'AI智能审核',
          p: '平台可实现文本、语音、图像、视频智能审核，文本审核准确率可达99%，语音97%，图像70%',
        },
        {
          img: require('../../assets/img/product/5GMessage/part4-icon8.png'),
          imgOver: require('../../assets/img/product/5GMessage/part4-icon8-over.png'),
          span: '多消息融合',
          p: '可实现5G消息、短信、视频短信、APP、公众号、邮箱多信息融合解决方案，可在现有短信平台升级，可对短信内容自动转换成5G消息样式发送',
        },
        {
          img: require('../../assets/img/product/5GMessage/part4-icon9.png'),
          imgOver: require('../../assets/img/product/5GMessage/part4-icon9-over.png'),
          span: '高效互通平台',
          p: '与三大运营商和 RCS 服务器互联互通，每个应用通过手机号码作为统一账户体系，稳定安全高效',
        },
        {
          img: require('../../assets/img/product/5GMessage/part4-icon10.png'),
          imgOver: require('../../assets/img/product/5GMessage/part4-icon10-over.png'),
          span: '一站式解决方案',
          p: '提供可视化 5G 消息制作工具以及技术服务支持，无缝对接三大运营商的 MaaP 平台，简单易成，快速开发',
        },
      ],
      part4Datas: [],
      p5data: [
        {
          topIcon: require('../../assets/img/product/5GMessage/part5-icon1.png'),
          topIconOver: require('../../assets/img/product/5GMessage/part5-icon1-over.png'),
          topText: '宣传通知',
          bottomImg: [
            require('../../assets/img/product/5GMessage/part5-img1-1.png'),
            require('../../assets/img/product/5GMessage/part5-img1-2.png'),
          ],
          bottomContent: [
            '5G 消息强触达能力有效提高企业信息触达率，尤其是双向实名和多种富媒体形式，向用户提供直观易读、生动形象的宣传通知内容，可用于品牌宣传、政务宣传、疫情防控、新闻资讯、企业动态等等。',
          ],
          bottomSpan: ['快递物流', '政企部门', '新媒体'],
        },
        // {
        //   topIcon: require('../../assets/img/product/5GMessage/part5-icon2.png'),
        //   topIconOver: require('../../assets/img/product/5GMessage/part5-icon2-over.png'),
        //   topText: '活动推广',
        //   bottomImg: [
        //     require('../../assets/img/product/5GMessage/part5-img2-1.png'),
        //   ],
        //   bottomContent: [
        //     '可根据企业用户画像与行为智能筛选营销模板，精准实现对用户的触达、唤醒、转化和裂变。提升企业私域流量运营的效率，提高对用户需求的匹配度，增强用户的信任度和参与感。',
        //   ],
        //   bottomSpan: ['游戏行业', '电商行业', '餐饮行业'],
        // },
        {
          topIcon: require('../../assets/img/product/5GMessage/part5-icon3.png'),
          topIconOver: require('../../assets/img/product/5GMessage/part5-icon3-over.png'),
          topText: '账单查询',
          bottomImg: [
            require('../../assets/img/product/5GMessage/part5-img3-1.png'),
            require('../../assets/img/product/5GMessage/part5-img3-2.png'),
          ],
          bottomContent: [
            '5G消息带来银行与用户之间的信息沟通升级。相比于传统行业短信“通知即结束”的状态，5G消息以多元化的消息形态和智能交互能力，帮助银行实现在原生手机消息入口中的服务能力延伸，集成原有APP、H5、小程序等各类渠道和资源，赋能业务节点的全流程闭环，提升导流获客或交易转化率。',
          ],
          bottomSpan: [],
        },
        {
          topIcon: require('../../assets/img/product/5GMessage/part5-icon4.png'),
          topIconOver: require('../../assets/img/product/5GMessage/part5-icon4-over.png'),
          topText: '政务办理',
          bottomImg: [
            require('../../assets/img/product/5GMessage/part5-img4-1.png'),
            require('../../assets/img/product/5GMessage/part5-img4-2.png'),
          ],
          bottomContent: [
            '支持各类线上业务办理场景，比如预约取号、违章缴费、税务办理、话费充值以及水电煤气等。通过 5G 消息即可完成全流程，也可通过跳转到APP/ 网站等方式，进行下单支付。',
          ],
          bottomSpan: ['政务办理', '水电煤气', '话费充值'],
        },
        {
          topIcon: require('../../assets/img/product/5GMessage/part5-icon5.png'),
          topIconOver: require('../../assets/img/product/5GMessage/part5-icon5-over.png'),
          topText: '票务预订',
          bottomImg: [
            require('../../assets/img/product/5GMessage/part5-img5-1.png'),
            require('../../assets/img/product/5GMessage/part5-img5-2.png'),
          ],
          bottomContent: [
            '购买相关票务不需下载/打开相关软件，5G 消息成为各类票务的集中查询预订、查看管理的统一平台，实现更加快速地完成购买机票、火车票、电影票、景区门票以及酒店预订、餐厅定位等需求。',
          ],
          bottomSpan: ['旅游行业', '电影行业', '酒店行业'],
        },
      ],
      // 轮播图参数
      swiperOption: {
        pagination: {
          el: '.p2-uMain .p2Um-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      swiperOption2: {
        pagination: {
          el: '.part2-dMain .p2Um-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      swiperOption3: {
        pagination: {
          el: '.part4_main .p4m-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
    };
  },
  mounted() {
    // 刷新页面
    if (location.href.indexOf('#reloaded') == -1) {
      location.href = location.href + '#reloaded';
      location.reload();
    }
    // 内容初始化
    $('.p2-dItem').eq(0).addClass('active');
    $('.p5m-major-topItem').eq(0).addClass('active');
    $('.p5m-major-topItem')
      .eq(0)
      .find('img')
      .attr('src', this.p5data[0].topIconOver);
    $('.p5m-major-bottomItem').eq(0).css('display', 'flex');
    // 数据初始化
    this.part2UDatas = [
      JSON.parse(JSON.stringify(this.part2UData.slice(0, 2))),
      JSON.parse(JSON.stringify(this.part2UData.slice(2, 4))),
      JSON.parse(JSON.stringify(this.part2UData.slice(-1))),
    ];
    this.part4Datas = [
      JSON.parse(JSON.stringify(this.part4Data.slice(0, 3))),
      JSON.parse(JSON.stringify(this.part4Data.slice(3, 6))),
      JSON.parse(JSON.stringify(this.part4Data.slice(6, 9))),
      JSON.parse(JSON.stringify(this.part4Data.slice(-1))),
    ];
  },
  methods: {
    p2DChance(e) {
      $('.p2-dItem').removeClass('active');
      $(e.currentTarget).addClass('active');
    },
    p4Move(i, img, chance) {
      switch (chance) {
        case 1:
          $('.part4-item').eq(i).addClass('active');
          break;
        case 2:
          $('.part4-item').eq(i).removeClass('active');
          break;
      }
      $('.part4-item').eq(i).find('img').attr('src', img);
    },
    p4mClick(e, item) {
      $('.part4-item').removeClass('active');
      $('.message5GP4-swiper .part4-item').each((index, item2) => {
        this.part4Data.find((item3) => {
          if (item3.p == $(item2).children('p').text()) {
            $(item2).find('img').attr('src', item3.img);
          }
        });
      });
      $(e.currentTarget).addClass('active');
      $(e.currentTarget).find('img').attr('src', item.imgOver);
    },
    p5TopAction(i, iconOver) {
      let p5Obj = {};
      if ($(window).width() > 768) {
        p5Obj.move = 44;
      } else {
        p5Obj.move = 0;
      }
      // 初始化
      for (let index = 0; index < this.p5data.length; index++) {
        $('.p5m-major-topItem')
          .eq(index)
          .find('img')
          .attr('src', this.p5data[index].topIcon);
      }
      $('.p5m-major-bottomItem').css('display', 'none');
      $('.p5m-major-topItem').removeClass('active');
      // 设置移动效果
      $('.p5m-major-topLine')
        .stop()
        .animate(
          {
            left:
              $('.p5m-major-topItem').eq(i).position().left -
              $('.p5m-major-topItem').eq(0).position().left +
              p5Obj.move,
          },
          200
        );
      $('.p5m-major-topItem').eq(i).toggleClass('active');
      $('.p5m-major-topItem').eq(i).find('img').attr('src', iconOver);
      $('.p5m-major-bottomItem').eq(i).css('display', 'flex');
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 723px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      position: absolute;
      width: 654px;
      height: 689px;
      z-index: -1;
      top: 57px;
      right: -113px;
    }
  }
  .part1-title {
    width: 538px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 97px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
/* 第二部分 */
.part2 {
  padding-top: 72px;
  .part-title {
    margin-bottom: 44px;
  }
  .p2-main {
    width: 1200px;
    margin: 0 auto;
  }
  .part2_up {
    width: 100%;
    background: #ffffff;
    margin-bottom: 56px;
    .p2-main {
      > p {
        display: block;
        width: 1111px;
        margin: 0 auto;
        font-size: 18px;
        line-height: 30px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 67px;
      }
      .p2-uMain {
        width: 96%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .p2-uItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            margin-bottom: 7px;
          }
          p {
            display: block;
            width: 152px;
            height: 38px;
            background: #1869bb;
            border-radius: 19px;
            font-size: 16px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 38px;
          }
        }
      }
      .p2-uMain:nth-of-type(2) {
        display: none;
      }
    }
  }
  .part2_down {
    box-sizing: border-box;
    width: 100%;
    height: 818px;
    background: url('../../assets/img/product/5GMessage/part2-bBg.png')
      no-repeat;
    background-size: 100% 100%;
    padding-top: 44px;
    .p2-main {
      width: 810px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .part2-dSide {
        width: 167px;
        height: 319px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .p2-dItem {
          width: 167px;
          height: 41px;
          border-radius: 21px;
          font-size: 18px;
          font-family: FontExcellent;
          font-weight: 400;
          text-align: center;
          line-height: 41px;
          background: #1864ba;
          box-shadow: 2px 5px 9px 0px rgba(62, 62, 62, 0.09);
          color: #ffffff;
        }
        .p2-dItem.active {
          background: #1864ba;
          box-shadow: 2px 5px 9px 0px rgba(62, 62, 62, 0.09);
          color: #ffffff;
        }
      }
      .part2-dMain {
        width: 312px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        img {
          margin-bottom: 18px;
        }
      }
      .message5GP2-swiper {
        display: none;
      }
    }
  }
}
/* 第三部分 */
.part3 {
  width: 100%;
  padding-top: 123px;
  margin-bottom: 66px;
  .part-title {
    margin-bottom: 66px;
  }
  img {
    display: block;
    width: 1147px;
    margin: 0 auto;
  }
}
/* 第四部分 */
.part4 {
  box-sizing: border-box;
  width: 100%;
  height: 1290px;
  background: url('../../assets/img/product/5GMessage/part4-bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 112px;
  .part-title {
    color: #ffffff;
    margin-bottom: 93px;
  }
  .part4_main {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    .part4-item {
      box-sizing: border-box;
      width: 204px;
      height: 400px;
      background: #ffffff;
      box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
      border-radius: 10px;
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 22px;
      > div {
        height: 91px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 18px;
      }
      span {
        font-size: 18px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #333333;
        margin-bottom: 7px;
      }
      p {
        width: 150px;
        font-size: 16px;
        line-height: 27px;
        font-weight: 400;
        color: #666666;
        text-align: justify;
      }
    }
    .part4-item.active {
      background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
      border: 2px solid #ffffff;
      span,
      p {
        color: #ffffff;
      }
    }
  }
  .message5GP4-swiper {
    display: none;
  }
}
/* 第五部分 */
.part5 {
  box-sizing: border-box;
  width: 100%;
  height: 1174px;
  background: linear-gradient(-88deg, #ffffff 0%, #fafbfd 52%, #ffffff 100%);
  .part5_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 133px;
    > span {
      margin-bottom: 103px;
    }
    .p5m-major {
      position: relative;
      width: 1100px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      > img {
        position: absolute;
        top: 325px;
        right: -240px;
        z-index: 1;
        width: 366px;
        height: 409px;
      }
      .p5m-major-top {
        box-sizing: border-box;
        width: 100%;
        height: 56px;
        padding: 0px 44px;
        border-bottom: 1px solid #cecece;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 60px;
        .p5m-major-topLine {
          width: 146px;
          height: 1px;
          position: absolute;
          bottom: 0;
          left: 44px;
          background: #1266bc;
        }
        .p5m-major-topItem {
          // padding: 0px 5px;
          width: 146px;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          img {
            margin-right: 10px;
          }
          span {
            font-size: 18px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #333333;
          }
        }
        .p5m-major-topItem.active span {
          color: #1267bc;
        }
      }
      .p5m-major-bottom {
        width: 1112px;
        height: 597px;
        z-index: 2;
        .p5m-major-bottomItem {
          box-sizing: border-box;
          width: 1112px;
          height: 597px;
          padding-top: 76px;
          padding-left: 42px;
          padding-right: 62px;
          background: #ffffff;
          box-shadow: 35px 38px 40px 0px rgba(134, 134, 134, 0.04);
          border-radius: 15px;
          display: none;
          flex-direction: row;
          justify-content: space-between;
          .p5m-major-bottomLeft {
            width: 490px;
            height: 480px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            img {
              width: 230px;
            }
            .mobileImg {
              display: none;
            }
          }
          .p5m-major-bottomRight {
            box-sizing: border-box;
            width: 490px;
            height: 430px;
            padding-top: 63px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .p5m-firt-div {
              display: flex;
              flex-direction: column;
              > span {
                display: block;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                margin-bottom: 64px;
                letter-spacing: -0.2px;
                margin-left: -25px;
              }
              p {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                line-height: 26px;
                margin-bottom: 4px;
                position: relative;
              }
            }
            .p5m-last-div {
              width: 100%;
              display: flex;
              flex-direction: column;
              div {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 105px;
              }
              span {
                display: block;
                width: 146px;
                height: 46px;
                background: linear-gradient(267deg, #0c63bb 0%, #6392cd 100%);
                border-radius: 7px;
                text-align: center;
                line-height: 46px;
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
              }
              > a {
                font-size: 14px;
                font-family: FontExcellent;
                font-weight: 400;
                color: #3e7ec6;
                margin-left: 8px;
                :hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
.p5m-major-topItem {
  cursor: pointer;
}
/* 第六部分 */
.part6 {
  width: 100%;
  height: 210px;
  background: url('../../assets/img/product/5GMessage/part6-bg.png') no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .part6_main {
    display: flex;
    flex-direction: row;
    align-items: center;
    p,
    span {
      font-family: FontExcellent;
      font-weight: 400;
      color: #ffffff;
    }
    p {
      font-size: 18px;
      margin-right: 22px;
    }
    span {
      display: block;
      width: 216px;
      height: 50px;
      background: #1266bb;
      border: 2px solid #ffffff;
      border-radius: 11px;
      font-size: 18px;
      text-align: center;
      line-height: 50px;
      margin-left: 21px;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 768px) {
  /* 第一部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        width: 3.68rem;
        height: 3.87rem;
        top: 0.12rem;
        right: -0.09rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
        margin-top: -0.62rem;
      }
      p,
      a {
        display: none;
      }
    }
  }
  /* 第二部分 */
  .part2 {
    padding-top: 0.34rem;
    .part-title {
      margin-bottom: 0.42rem;
    }
    .p2-main {
      width: 6.5rem;
    }
    .part2_up {
      margin-bottom: 0px;
      .p2-main {
        > p {
          width: 6.15rem;
          font-size: 0.18rem;
          line-height: 0.3rem;
          margin-bottom: 0.11rem;
        }
        .p2-uMain {
          width: 76%;
          padding-bottom: 0.95rem;
          .swiper-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          .swiper-item:last-child {
            justify-content: center;
          }
          .p2-uItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              width: 2.28rem;
              margin-bottom: 0.07rem;
            }
            p {
              width: 1.52rem;
              height: 0.38rem;
              border-radius: 0.19rem;
              font-size: 0.16rem;
              line-height: 0.38rem;
            }
          }
        }
        .p2-uMain:nth-of-type(1) {
          display: none;
        }
        .p2-uMain:nth-of-type(2) {
          display: block;
        }
      }
    }
    .part2_down {
      height: 4.91rem;
      background: url('../../assets/img/product/5GMessage/mobile/part2-bBg.png')
        no-repeat;
      background-size: 100% 100%;
      padding-top: 0.45rem;
      .p2-main {
        width: 5.8rem;
        .part2-dSide {
          width: 1.67rem;
          height: 3.05rem;
          .p2-dItem {
            width: 1.67rem;
            height: 0.41rem;
            box-shadow: 0.02rem 0.05rem 0.09rem 0px rgba(62, 62, 62, 0.09);
            border-radius: 0.21rem;
            font-size: 0.18rem;
            line-height: 0.41rem;
          }
          .p2-dItem.active {
            box-shadow: 0.02rem 0.05rem 0.09rem 0rem rgba(62, 62, 62, 0.09);
          }
        }
        .part2-dMain {
          width: 2.46rem;
          img {
            display: block;
            width: 1.5rem;
            margin: 0 auto;
            margin-bottom: 0px;
          }
        }
        .message5GP2 {
          display: none;
        }
        .message5GP2-swiper {
          display: block;
        }
      }
    }
  }
  /* 第三部分 */
  .part3 {
    width: 100%;
    padding-top: 0.59rem;
    margin-bottom: 0.39rem;
    .part-title {
      margin-bottom: 0.25rem;
    }
    img {
      display: block;
      width: 6.87rem;
      margin: 0 auto;
    }
  }
  /* 第四部分 */
  .part4 {
    height: 6.88rem;
    background: url('../../assets/img/product/5GMessage/mobile/part4-bg.png')
      no-repeat;
    background-size: 100% 100%;
    padding-top: 0.58rem;
    .part-title {
      margin-bottom: 0.49rem;
    }
    .part4_main {
      width: 6.57rem;
      .swiper-item {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 0.1rem;
      }
      .swiper-item:last-child {
        justify-content: center;
      }
      .part4-item {
        width: 2.04rem;
        height: 4rem;
        box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.1);
        border-radius: 0.1rem;
        margin-bottom: 0px;
        padding-top: 0.22rem;
        > div {
          height: 0.91rem;
          margin-bottom: 0.18rem;
          img {
            height: 100%;
          }
        }
        span {
          font-size: 0.18rem;
          margin-bottom: 0.07rem;
        }
        p {
          width: 1.5rem;
          font-size: 0.16rem;
          line-height: 0.27rem;
        }
      }
      .part4-item.active {
        background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
        border: 0.02rem solid #ffffff;
        span,
        p {
          color: #ffffff;
        }
      }
    }
    .message5GP4 {
      display: none;
    }
    .message5GP4-swiper {
      display: block;
    }
  }
  /* 第五部分 */
  .part5 {
    height: 7.11rem;
    .part5_main {
      width: 6.5rem;
      padding-top: 0.63rem;
      > span {
        margin-bottom: 0.3rem;
      }
      .p5m-major {
        width: 100%;
        > img {
          display: none;
        }
        .p5m-major-top {
          width: 100%;
          height: 0.9rem;
          padding: 0px 0rem;
          border-bottom: 1px solid #cecece;
          margin-bottom: 0.42rem;
          .p5m-major-topLine {
            width: 0.8rem;
            left: 0rem;
          }
          .p5m-major-topItem {
            padding: 0px 0px;
            width: 0.8rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              height: 0.3rem;
              margin-right: 0px;
              margin-bottom: 0.15rem;
            }
            span {
              font-size: 0.18rem;
            }
          }
          .p5m-major-topItem.active span {
            color: #1267bc;
          }
        }
        .p5m-major-bottom {
          width: 6.42rem;
          height: 4.43rem;
          z-index: 2;
          .p5m-major-bottomItem {
            width: 100%;
            height: 100%;
            padding-top: 0.4rem;
            padding-left: 0.24rem;
            padding-right: 0.32rem;
            background: #ffffff;
            box-shadow: 0.35rem 0.38rem 0.4rem 0rem rgba(134, 134, 134, 0.04);
            border-radius: 0.15rem;
            .p5m-major-bottomLeft {
              width: 1.72rem;
              height: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
              margin-right: 0.42rem;
              img {
                width: 1.72rem !important;
              }
              > img {
                display: none;
              }
              .mobileImg {
                display: block;
                width: 100%;
              }
            }
            .p5m-major-bottomRight {
              flex: 1;
              box-sizing: border-box;
              width: auto;
              height: 3.6rem;
              padding-top: 0.18rem;
              .p5m-firt-div {
                padding-left: 0rem;
                display: flex;
                flex-direction: column;
                p {
                  font-size: 0.16rem;
                  line-height: 0.24rem;
                  margin-bottom: 0.04rem;
                }
                p::after {
                  display: none;
                }
              }
              .p5m-last-div {
                width: 100%;
                display: flex;
                flex-direction: column;
                div {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  margin-bottom: 0.77rem;
                }
                span {
                  display: block;
                  width: 1.2rem;
                  height: 0.46rem;
                  background: linear-gradient(267deg, #0c63bb 0%, #6392cd 100%);
                  border-radius: 0.07rem;
                  line-height: 0.46rem;
                  font-size: 0.16rem;
                }
                > a {
                  font-size: 0.14rem;
                  margin-left: 0.08rem;
                }
              }
            }
          }
        }
      }
    }
  }
  /* 第六部分 */
  .part6 {
    height: 2.1rem;
    background: url('../../assets/img/product/5GMessage/mobile/part6-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part6_main {
      p {
        font-size: 0.18rem;
        margin-right: 0.22rem;
      }
      span {
        width: 2.16rem;
        height: 0.5rem;
        border: 0.02rem solid #ffffff;
        border-radius: 0.11rem;
        font-size: 0.18rem;
        line-height: 0.5rem;
        margin-left: 0.21rem;
      }
    }
  }
}
</style>
<style lang="less">
.message5GP2-swiper .swiper_bg_white {
  width: calc(66% - 0.01rem);
  margin-top: 0.52rem;
}
.part2_down .message5GP2-swiper .swiper_bg_white {
  width: 2.46rem;
  margin-top: 0.65rem;
}
.message5GP4-swiper .swiper_bg_blue {
  width: 6.57rem;
  margin-top: 0.52rem;
}
// 第三个轮播图
.p5m-major-bottomLeft {
  .el-carousel__container {
    height: 4.03rem;
  }
  .el-carousel__indicator--horizontal {
    padding: 0.08rem;
  }
  .el-carousel__indicators--horizontal {
    bottom: 0.32rem;
    left: 54%;
  }
  .el-carousel__button {
    width: 0.14rem;
    height: 0.14rem;
    background: #46515f;
    border-radius: 50%;
    opacity: 1;
  }
  .el-carousel__indicator.is-active button {
    background: #1864ba;
  }
}
</style>
