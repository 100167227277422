<template>
  <div class="honest">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>诚聘英才</span>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/join/join/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part-jump reveal-bottom2">
      <div class="pJ_main">
        <div class="pJ_line"></div>
        <ul>
          <li
            v-for="(item, i) in pJump"
            :key="i"
            @click="pJClick($event, i, item.address)"
          >
            <a href="#" v-text="item.span"></a>
          </li>
        </ul>
      </div>
    </section>
    <section class="part2 reveal-bottom2">
      <span class="part-title">百悟一悟 九万里风鹏正举</span>
      <p>
        “天地之所以长且久者，以其不自生故能长生。”百悟科技是由一支年轻、激情、有活力、有创造力的团队构成，在努力成为先进的企业通信科技服务企业的梦想追逐中，我们一路寻行逐队，愿我们的携手能筑造更大的成就，愿我们的舞台能给你更多的精彩！
      </p>
    </section>
    <section class="part3">
      <span class="part-title">职业发展</span>
      <div class="part3_main p3_main-1 reveal-bottom2">
        <div
          class="p3_item"
          v-for="(item, i) in p3Data"
          :key="i"
          @mousemove="p3Move(i, item.imgOver, (chance = 1))"
          @mouseout="p3Move(i, item.img, (chance = 2))"
        >
          <div>
            <img :src="item.img" alt="" />
          </div>
          <span>{{ item.span }}</span>
          <p>{{ item.p }}</p>
        </div>
      </div>
      <div class="part3_main p3_main-2 honestP3-swiper">
        <swiper :options="swiperOption">
          <swiper-slide
            v-for="(item, i) in p3Data"
            :key="i"
            class="swiper-item"
          >
            <div class="p3_item" @click="p3MClick($event, item)">
              <div>
                <img :src="item.img" alt="" />
              </div>
              <span>{{ item.span }}</span>
              <p>{{ item.p }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-pagination p3-pagination swiper_bg_blue"
          slot="pagination"
        ></div>
      </div>
    </section>
    <section class="part4">
      <span class="part-title">百悟员工风采</span>
      <div class="part4_main reveal-bottom2">
        <div class="p4_left">
          <div
            class="p4_lItem"
            v-for="(item, i) in p4LData"
            :key="i"
            @click="p4Click(i)"
          >
            <p>{{ item }}</p>
          </div>
        </div>
        <div class="p4_right p4_right_swiper">
          <div class="p4_rItem" v-for="(item, i) in p4RData" :key="i">
            <el-carousel
              trigger="click"
              :interval="3000"
              arrow="naver"
              ref="p4m2Carousel"
            >
              <el-carousel-item
                class="part4-item"
                v-for="(item2, i2) in item"
                :key="i2"
              >
                <img :src="item2" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </section>
    <section class="part5">
      <span class="part-title">招贤纳士</span>
      <div class="part5_main reveal-bottom2">
        <div class="p5_up">
          <ul>
            <li>职位名称</li>
            <li>招聘人数</li>
            <li>工作地点</li>
          </ul>
        </div>
        <div class="p5_up p5_up-bg">
          <ul>
            <li>大客户经理</li>
            <li>5</li>
            <li>北京</li>
          </ul>
        </div>
        <div class="p5_down">
          <div>
            <span>岗位职责：</span>
            <p>1、负责全国范围内企业客户拓展。</p>
            <p>2、主动寻找目标客户，并建立良好的客户关系。</p>
            <p>3、能按时完成公司制定的销售目标。</p>
            <p>4、完成上级下达的相关任务。</p>
          </div>
          <div>
            <span>岗位要求：</span>
            <p>1、专科及以上学历，专业不限；</p>
            <p>2、具备良好的沟通能力和职业修养。</p>
            <p>3、积极热情，勇于接受挑战，能够承受高强度的工作和压力。</p>
            <p>4、具有良好的品质道德及团队合作精神。</p>
            <p>5、熟练计算机基本操作。</p>
            <p>
              6、有通讯增值服务行业、金融行业以及企业服务等相关行业工作经验者优先。
            </p>
          </div>
        </div>
      </div>
      <div class="part5_down reveal-bottom2">
        <img src="../../assets/img/join/join/part5-dIcon.png" alt="" />
        <p>投递邮箱：HR@BAIWUTONG.COM</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pJump: [
        {
          span: '职业发展',
          address: '.part3',
        },
        {
          span: '百悟员工风采',
          address: '.part4',
        },
        {
          span: '招聘岗位',
          address: '.part5',
        },
      ],
      p3Data: [
        {
          img: require('../../assets/img/join/join/part3-icon1.png'),
          imgOver: require('../../assets/img/join/join/part3-icon1-over.png'),
          span: '完善的职业培训',
          p: '百悟为员工提供完善的培训体系，包括一对一导师制、常规培训、专业培训、技能培训等，帮助员工不断地提升职业技能水平和专业知识水平，以实现员工与企业的共同成长。',
        },
        {
          img: require('../../assets/img/join/join/part3-icon2.png'),
          imgOver: require('../../assets/img/join/join/part3-icon2-over.png'),
          span: '健全的晋升制度',
          p: '百悟实行扁平化的内部管理和透明化的职业晋升机制，以人工智能自然而然自动运转捕捉，不断优化各种算法分析和统计每一个员工的每一个月工作功劳，以功劳作为员工晋升的重要标准，保障每一个员工获得与能力对等的发展。',
        },
        {
          img: require('../../assets/img/join/join/part3-icon3.png'),
          imgOver: require('../../assets/img/join/join/part3-icon3-over.png'),
          span: '全面的职业发展渠道',
          p: '百悟拥有企业通信多条产品线，并不断以企业为客户孵化内部各领域创新产品，以实行人人都是企业CEO、人人都是利润合伙人的机制，在平台土壤孵化和员工自我奋斗创新中，实现没有天花板的互利发展。',
        },
      ],
      p4LData: [
        '创意活动，有你更精彩',
        '闲暇之际，来场酣畅淋漓的运动',
        '风景与美食，随心出发',
      ],
      p4RData: [
        [
          require('../../assets/img/join/join/part4-rIcon1-1.png'),
          require('../../assets/img/join/join/part4-rIcon1-2.png'),
          require('../../assets/img/join/join/part4-rIcon1-3.png'),
        ],
        [
          require('../../assets/img/join/join/part4-rIcon2-1.png'),
          require('../../assets/img/join/join/part4-rIcon2-2.png'),
          require('../../assets/img/join/join/part4-rIcon2-3.png'),
        ],
        [
          require('../../assets/img/join/join/part4-rIcon3-1.png'),
          require('../../assets/img/join/join/part4-rIcon3-2.png'),
          require('../../assets/img/join/join/part4-rIcon3-3.png'),
          require('../../assets/img/join/join/part4-rIcon3-4.png'),
        ],
      ],
      swiperOption: {
        pagination: {
          el: '.honestP3-swiper .p3-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
    };
  },
  mounted() {
    // 初始化样式
    // 点击块
    $('.pJ_main li').eq(0).addClass('active');
    // p4
    $('.p4_lItem').eq(0).addClass('active');
    $('.p4_rItem').eq(0).addClass('active');
  },
  methods: {
    // 点击块
    pJClick(e, i, address) {
      $('.pJ_main li').removeClass('active');
      $(e.currentTarget).addClass('active');
      $('.pJ_line').animate({ left: 237 * i + 37 + 'px' }, 100);
      $('html,body').animate(
        { scrollTop: $(address).offset().top + 'px' },
        500
      );
    },
    p4Click(i) {
      $('.p4_lItem').removeClass('active');
      $('.p4_rItem').removeClass('active');
      $('.p4_lItem').eq(i).addClass('active');
      $('.p4_rItem').eq(i).addClass('active');
    },
    p3Move(i, img, chance) {
      switch (chance) {
        case 1:
          $('.p3_item').eq(i).addClass('active');
          break;
        case 2:
          $('.p3_item').eq(i).removeClass('active');
          break;
      }
      $('.p3_item').eq(i).find('img').attr('src', img);
    },
    p3MClick(e, item) {
      $('.p3_item').removeClass('active');
      $('.p3_main-2 .p3_item').each((index, item2) => {
        this.p3Data.find((item3) => {
          if (item3.span == $(item2).children('span').text()) {
            $(item2).find('img').attr('src', item3.img);
          }
        });
      });
      $(e.currentTarget).addClass('active');
      $(e.currentTarget).find('img').attr('src', item.imgOver);
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.honest {
  /* 第一部分 */
  .part1 {
    box-sizing: border-box;
    width: 100%;
    height: 369px;
    background: linear-gradient(-77deg, #ffffff 0%, #f2f6fa 100%);
    .part1_main {
      box-sizing: border-box;
      width: 1110px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .part1-title {
        span {
          font-family: FontExcellent;
          font-size: 40px;
          color: #333333;
          display: block;
          letter-spacing: 1px;
          margin-top: -54px;
        }
      }
      > img {
        position: absolute;
        width: 598px;
        height: 369px;
        bottom: 0;
        right: -34px;
      }
    }
  }
  // 跳转模块
  .part-jump {
    box-sizing: border-box;
    width: 100%;
    height: 53px;
    background: #ffffff;
    box-shadow: 0px 15px 24px 0px rgba(55, 63, 71, 0.03);
    .pJ_main {
      box-sizing: border-box;
      width: 711px;
      margin: 0 auto;
      height: 100%;
      position: relative;
      .pJ_line {
        position: absolute;
        bottom: 0px;
        left: 37px;
        width: 163px;
        height: 1px;
        background: #0b62bb;
      }
      ul {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        li {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: relative;
          cursor: pointer;
          a {
            font-size: 24px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #333333;
            line-height: 100%;
          }
        }
        li.active {
          a {
            color: #0b62bb;
          }
        }
        li::after {
          display: block;
          content: '';
          position: absolute;
          top: 10px;
          right: 0;
          width: 1px;
          height: 33px;
          background: #bcbcbc;
        }
        li:last-child::after {
          display: none;
        }
      }
    }
  }
  .part2 {
    width: 100%;
    padding-top: 43px;
    padding-bottom: 48px;
    .part-title {
      margin-bottom: 38px;
    }
    > p {
      display: block;
      width: 1111px;
      margin: 0 auto;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 31px;
      text-align: justify;
    }
  }
  .part3 {
    width: 100%;
    padding-top: 68px;
    background: url('../../assets/img/join/join/part2-bg.png') no-repeat;
    background-size: 100% 100%;
    padding-bottom: 91px;
    .part-title {
      color: white;
      margin-bottom: 66px;
    }
    .part3_main {
      width: 1110px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .p3_item {
        box-sizing: border-box;
        width: 338px;
        height: 435px;
        background: #fafafa;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 37px;
        div {
          width: 100%;
          height: 98px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-bottom: 14px;
        }
        span {
          font-size: 24px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #333333;
          margin-bottom: 16px;
        }
        p {
          display: block;
          width: 264px;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          line-height: 28px;
          text-align: justify;
        }
      }
      .p3_item.active {
        background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
        border: 2px solid #ffffff;
        span,
        p {
          color: #ffffff;
        }
      }
    }
    .p3_main-2 {
      display: none;
    }
  }
  .part4 {
    width: 100%;
    padding-top: 77px;
    padding-bottom: 89px;
    .part-title {
      margin-bottom: 92px;
    }
    .part4_main {
      width: 1110px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .p4_left {
        width: 540px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .p4_lItem {
          box-sizing: border-box;
          width: 539px;
          height: 118px;
          background: rgba(11, 98, 187, 0.06);
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-left: 45px;
          cursor: pointer;
          p {
            font-size: 24px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #0b62bb;
          }
        }
        .p4_lItem.active {
          background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
          p {
            color: #ffffff;
          }
        }
      }
      .p4_right {
        width: 540px;
        height: 367px;
        .p4_rItem {
          width: 100%;
          height: 100%;
          display: none;
        }
        .p4_rItem.active {
          display: block;
        }
      }
    }
  }
  .part5 {
    width: 100%;
    padding-top: 58px;
    padding-bottom: 53px;
    background: #f1f6fb;
    .part-title {
      margin-bottom: 47px;
    }
    .part5_main {
      width: 1110px;
      margin: 0 auto;
      background: #ffffff;
      border-radius: 3px;
      margin-bottom: 37px;
      .p5_up {
        box-sizing: border-box;
        width: 100%;
        height: 94px;
        padding-left: 72px;
        ul {
          width: 831px;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          li {
            min-width: 72px;
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            text-align: center;
          }
        }
      }
      .p5_up-bg {
        height: 84px;
        background: #f1f6fb;
        li {
          font-weight: 400 !important;
        }
        li:nth-of-type(2) {
          margin-left: -18px;
        }
      }
      .p5_down {
        box-sizing: border-box;
        width: 100%;
        padding-left: 72px;
        padding-top: 31px;
        padding-bottom: 39px;
        div {
          padding-bottom: 31px;
        }
        span {
          display: block;
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          margin-bottom: 9px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          line-height: 31px;
        }
      }
    }
    .part5_down {
      width: 1110px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      img {
        margin-right: 15px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .honest {
    /* 第一部分 */
    .part1 {
      height: 4.28rem;
      background: linear-gradient(64deg, #ffffff 0%, #f2f6fa 100%);
      .part1_main {
        width: 6.14rem;
        > img {
          content: url('../../assets/img/join/join/mobile/part1-icon.png');
          width: 7.5rem;
          height: 4.28rem;
          bottom: 0px;
          right: -0.68rem;
        }
      }
      .part1-title {
        span {
          font-size: 0.4rem !important;
          margin-top: 0rem !important;
        }
      }
    }
    .part-jump {
      display: none;
    }
    .part2 {
      padding-top: 0.51rem;
      padding-bottom: 0.74rem;
      .part-title {
        margin-bottom: 0.38rem;
      }
      > p {
        width: 6.06rem;
        font-size: 0.19rem;
        line-height: 0.33rem;
      }
    }
    .part3 {
      padding-top: 0.48rem;
      background: url('../../assets/img/join/join/mobile/part2-bg.png')
        no-repeat;
      background-size: 100% 100%;
      padding-bottom: 0.81rem;
      .part-title {
        margin-bottom: 0.31rem;
      }
      .part3_main {
        width: 5.5rem;
        .p3_item {
          width: 3.38rem;
          margin: 0 auto;
          height: 4.4rem;
          border-radius: 0.07rem;
          padding-top: 0.33rem;
          div {
            height: 0.98rem;
            margin-bottom: 0.15rem;
            img {
              height: 0.98rem;
            }
          }
          span {
            font-size: 0.25rem;
            margin-bottom: 0.13rem;
          }
          p {
            width: 2.7rem;
            font-size: 0.19rem;
            line-height: 0.3rem;
          }
        }
        .p3_item.active {
          background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
          border: 0.02rem solid #ffffff;
          span,
          p {
            color: #ffffff;
          }
        }
      }
      .p3_main-1 {
        display: none;
      }
      .p3_main-2 {
        display: block;
      }
    }
    .part4 {
      width: 100%;
      padding-top: 0.42rem;
      padding-bottom: 0.66rem;
      .part-title {
        margin-bottom: 0.49rem;
      }
      .part4_main {
        width: 6.2rem;
        .p4_left {
          width: 3.06rem;
          .p4_lItem {
            width: 3.06rem;
            height: 0.6rem;
            background: rgba(41, 115, 210, 0.05);
            padding-left: 0.12rem;
            p {
              font-size: 0.21rem;
            }
          }
          .p4_lItem.active {
            background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
            p {
              color: #ffffff;
            }
          }
        }
        .p4_right {
          width: 3.06rem;
          height: 2.02rem;
          .p4_rItem {
            img {
              width: 100%;
            }
          }
        }
      }
    }
    .part5 {
      padding-top: 0.32rem;
      padding-bottom: 0.41rem;
      .part-title {
        margin-bottom: 0.28rem;
      }
      .part5_main {
        width: 6.2rem;
        border-radius: 0.03rem;
        margin-bottom: 0.34rem;
        .p5_up {
          height: 0.93rem;
          padding-left: 0.35rem;
          ul {
            width: 5.5rem;
            li {
              min-width: 0.84rem;
              font-size: 0.21rem;
            }
          }
        }
        .p5_up-bg {
          height: 0.68rem;
          li:nth-of-type(2) {
            margin-left: -0.21rem;
          }
        }
        .p5_down {
          padding-left: 0.35rem;
          padding-top: 0.31rem;
          padding-bottom: 0.14rem;
          div {
            padding-bottom: 0.29rem;
          }
          span {
            font-size: 0.21rem;
            margin-bottom: 0.19rem;
          }
          p {
            display: block;
            width: 5.36rem;
            font-size: 0.19rem;
            line-height: 0.33rem;
          }
        }
      }
      .part5_down {
        width: 6.2rem;
        img {
          width: 0.26rem;
          height: 0.19rem;
          margin-right: 0.12rem;
        }
        p {
          font-size: 0.17rem;
        }
      }
    }
  }
}
</style>
<style lang="less">
.p4_right_swiper {
  .el-carousel__container {
    height: 367px;
  }
  .el-carousel__button {
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    background: #ffffff;
    border-radius: 50%;
    opacity: 1;
  }
  .el-carousel__indicator.is-active button {
    border: 1px solid #ffffff;
    background: linear-gradient(0deg, #6c97ce 0%, #005cb9 100%);
  }
}
@media screen and (max-width: 768px) {
  .honestP3-swiper {
    .swiper_bg_blue {
      width: 5.5rem;
      margin-top: 0.37rem;
    }
  }
  .p4_right_swiper {
    .el-carousel__container {
      height: 2.02rem;
    }
    .el-carousel__button {
      width: 0.14rem;
      height: 0.14rem;
    }
  }
}
</style>
