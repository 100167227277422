<template>
  <div class="number95">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>95码号</span>
          <p>
            95码号是直属于工信部的特殊码号，起初主要用于银行、保险等特殊单位，后工信部根据需求，为知名品牌企业开通95*类别号码，具有全国唯一性，没有区域、城市限制，三网合一的全国统一热线。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/number95/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <div class="part2_main">
        <span class="part-title">95码号功能介绍</span>
        <div class="p2m-major">
          <div class="p2m-major-left">
            <div class="p2m-major-item reveal-bottom">
              <img
                src="../../assets/img/product/number95/part2-icon1.png"
                alt=""
              />
              <div>
                <span>跨界支持</span>
                <p>内聚电话和短信功能，彰显多价值衍生功能。</p>
              </div>
            </div>
            <div class="p2m-major-item reveal-bottom2">
              <img
                src="../../assets/img/product/number95/part2-icon2.png"
                alt=""
              />
              <div>
                <span>统一号显</span>
                <p>支持全国不加区号直接呼入，外呼时直接外显95。</p>
              </div>
            </div>
            <div class="p2m-major-item reveal-bottom3">
              <img
                src="../../assets/img/product/number95/part2-icon3.png"
                alt=""
              />
              <div>
                <span>码号扩展</span>
                <p>950/1/2/4/7可扩展，进行运营级码号业务。</p>
              </div>
            </div>
          </div>
          <img
            class="reveal-bottom2"
            src="../../assets/img/product/number95/part2-right-bg.png"
            alt=""
          />
        </div>
      </div>
    </section>
    <section class="part3">
      <div class="part3_main">
        <span class="part-title">95码号特殊优势</span>
        <div class="p3m-major">
          <div class="p3m-major-item reveal-bottom">
            <img
              src="../../assets/img/product/number95/part3-icon1.png"
              alt=""
            />
            <p>
              专业码号申请团队，百悟科技有多年码号申请经验，实时政策跟进，及时调整申请策略。
            </p>
          </div>
          <div class="p3m-major-item reveal-bottom2">
            <img
              src="../../assets/img/product/number95/part3-icon2.png"
              alt=""
            />
            <p>
              百悟与工信部合作经验丰富，可针对客户意向码号申请提供增值服务。
            </p>
          </div>
          <div class="p3m-major-item reveal-bottom3">
            <img
              src="../../assets/img/product/number95/part3-icon3.png"
              alt=""
            />
            <p>
              码号的申请与获取，百悟结合不同客户的实际情况，提供多种码号申请方案供选择。
            </p>
          </div>
          <div class="p3m-major-item reveal-bottom4">
            <img
              src="../../assets/img/product/number95/part3-icon4.png"
              alt=""
            />
            <p>
              客户只需配合提供对应资料，无需操心繁琐的申请流程，无需投入人力物力。
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Numver95',
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
/* 头图样式 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      width: 720px;
      height: 800px;
      margin-right: -153px;
      margin-top: 11px;
    }
    .part1-title {
      width: 480px;
      height: 280px;
      span {
        font-family: FontExcellent;
        font-size: 40px;
        color: #333333;
        display: block;
        margin-bottom: 22px;
        letter-spacing: 1px;
      }
      p {
        font-size: 16px;
        color: #666666;
        text-align: justify;
        margin-bottom: 78px;
        line-height: 28px;
      }
      a {
        display: block;
        width: 184px;
        height: 53px;
        background-color: rgb(36, 110, 187);
        border-radius: 7px;
        text-align: center;
        line-height: 53px;
        font-size: 16px;
        color: #f9fbfd;
        letter-spacing: 0.5px;
      }
    }
  }
}
/* @import '../../assets/css/product-shared.css';
@import '../../assets/css/number95.css'; */
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 792px;
  .part2_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 78px;
    > span {
      margin-bottom: 94px;
    }
    .p2m-major {
      width: 1100px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      > img {
        width: 441px;
        height: 470px;
        margin-top: -14px;
      }
      .p2m-major-left {
        width: 519px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .p2m-major-item {
        box-sizing: border-box;
        width: 100%;
        height: 134px;
        display: flex;
        flex-direction: row;
        padding: 26px 0px;
        span {
          display: block;
          font-family: FontExcellent;
          font-size: 20px;
          color: #333333;
          margin-bottom: 7px;
        }
        p {
          font-size: 16px;
          color: #666666;
        }
      }
      .p2m-major-item:nth-of-type(1) {
        background: url('../../assets/img/product/number95/part2-item-bg.png')
          no-repeat;
        background-size: 100% 100%;
        padding-left: 25px;
      }
      .p2m-major-item:nth-of-type(1) img {
        width: 62px;
        height: 55px;
        margin-right: 24px;
        margin-top: 5px;
      }
      .p2m-major-item:nth-of-type(2) {
        padding-left: 26px;
      }
      .p2m-major-item:nth-of-type(2) img {
        width: 65px;
        height: 63px;
        margin-right: 23px;
      }
      .p2m-major-item:nth-of-type(3) {
        padding-left: 32px;
      }
      .p2m-major-item:nth-of-type(3) img {
        width: 58px;
        height: 57px;
        margin-right: 26px;
        margin-top: 5px;
      }
    }
  }
}
.part3 {
  box-sizing: border-box;
  width: 100%;
  height: 1100px;
  background: rgb(250, 250, 253);
  .part3_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    padding-top: 78px;
    > span {
      margin-bottom: 76px;
    }
    .p3m-major {
      width: 964px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .p3m-major-item {
        width: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 65px;
        p {
          display: block;
          width: 284px;
          font-size: 16px;
          color: 666666;
          letter-spacing: 0.5px;
          justify-content: center;
        }
      }
      .p3m-major-item:nth-of-type(1) img {
        width: 322px;
        height: 220px;
        margin-bottom: 39px;
      }
      .p3m-major-item:nth-of-type(2) img {
        width: 318px;
        height: 225px;
        margin-top: -28px;
        margin-bottom: 62px;
      }
      .p3m-major-item:nth-of-type(3) img {
        width: 345px;
        height: 225px;
        margin-bottom: 32px;
      }
      .p3m-major-item:nth-of-type(4) img {
        width: 333px;
        height: 251px;
        margin-top: -28px;
        margin-bottom: 34px;
      }
    }
  }
}
</style>
