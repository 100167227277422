<template>
  <div class="HideConnect">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>隐私通话</span>
          <p>
            百悟隐私通话，隐藏真实号码，可选择双方号码或一方号码不公开，有效保护客户信息安全，提升号码接通率。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/hideConnect/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <span class="part-title">隐私通话功能介绍</span>
      <div class="part2_main">
        <div
          class="part2-item"
          v-for="(item, index) in part2Data"
          :key="index"
          @mousemove="part2ItemMove(item.imgOver, index)"
          @mouseout="part2ItemOut(item.img, index)"
        >
          <div>
            <img :src="item.img" alt="" />
          </div>
          <p>{{ item.p }}</p>
        </div>
      </div>
      <div class="part2_main p2_swiper">
        <swiper :options="swiperOption">
          <swiper-slide
            v-for="(item, i) in part2Data"
            :key="i"
            class="swiper-item"
          >
            <div class="part2-item" @click="swiperP2Item($event, item)">
              <div>
                <img :src="item.img" alt="" />
              </div>
              <p>{{ item.p }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-pagination p2m-pagination swiper_bg_blue"
          slot="pagination"
        ></div>
      </div>
    </section>
    <section class="part3">
      <span class="part-title">隐私通话特殊优势</span>
      <div class="part3-main p3-pc">
        <img src="../../assets/img/product/serve95/part3-bg.png" alt="" />
        <img src="../../assets/img/product/serve95/part3-bg-icon1.png" alt="" />
        <img src="../../assets/img/product/serve95/part3-bg-icon2.png" alt="" />
        <div class="p3m-among reveal-null">
          <div class="p3m-circle">
            <img
              src="../../assets/img/product/serve95/part3-amongIn-icon.png"
              alt=""
            />
            <span>特殊优势</span>
          </div>
          <img
            class="reveal-null3"
            src="../../assets/img/product/serve95/part3-among-icon.png"
            alt=""
          />
          <img
            class="reveal-null4"
            src="../../assets/img/product/serve95/part3-among-icon.png"
            alt=""
          />
          <img
            class="reveal-null4"
            src="../../assets/img/product/serve95/part3-among-icon.png"
            alt=""
          />
          <img
            class="reveal-null3"
            src="../../assets/img/product/serve95/part3-among-icon.png"
            alt=""
          />
        </div>
        <div class="part3-item reveal-null3">
          <span>号码资费丰富</span>
          <p>
            百悟科技拥有丰富的本地小号码资源，通话双方看到本地手机号码，可极大提升接通率。
          </p>
        </div>
        <div class="part3-item reveal-null4">
          <span>业务模式灵活</span>
          <p>
            支持AX、AXB
            、AXYB、CTD、AXB组拨等多种业务模式，灵活服务客户业务需求。
          </p>
        </div>
        <div class="part3-item reveal-null4">
          <span>小号实施经验丰富</span>
          <p>
            百悟科技作为滴滴出行等互联网平台信赖的服务商拥有丰富经验，可快速应对处理突发问题。
          </p>
        </div>
        <div class="part3-item reveal-null3">
          <span>平台稳定性高</span>
          <p>
            全国唯一日呼叫量超过千万级的安全隐私号平台，网络稳定充分保障高并发通话。
          </p>
        </div>
      </div>
      <div class="part3-main p3-swiper">
        <swiper :options="swiperOption2">
          <swiper-slide
            v-for="(item, i) in part3Datas"
            :key="i"
            class="swiper-item"
          >
            <div class="part3-item" v-for="(item2, i2) in item" :key="i2">
              <span>{{ item2.span }}</span>
              <p>{{ item2.p }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-pagination p3m-pagination swiper_bg_white"
          slot="pagination"
        ></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      part2Data: [
        {
          p: '构建多方位信息保护体系，避免企业文件、数据泄漏，夯实信息安全基础，筑牢信息安全防线。',
          img: require('../../assets/img/product/hideConnect/part2-icon1.png'),
          imgOver: require('../../assets/img/product/hideConnect/part2-icon1-over.png'),
        },
        {
          p: '构建多体系保护企业客户的呼叫安全，顾及客户之所念之所想，让企业无为而为茁壮发。',
          img: require('../../assets/img/product/hideConnect/part2-icon2.png'),
          imgOver: require('../../assets/img/product/hideConnect/part2-icon2-over.png'),
        },
        {
          p: '构建保护个人、家人和朋友的安全呼叫体系，让爱你的人更安全更平安。',
          img: require('../../assets/img/product/hideConnect/part2-icon3.png'),
          imgOver: require('../../assets/img/product/hideConnect/part2-icon3-over.png'),
        },
      ],
      part3Data: [
        {
          span: '号码资费丰富',
          p: '百悟科技拥有丰富的本地小号码资源，通话双方看到本地手机号码，可极大提升接通率。',
        },
        {
          span: '业务模式灵活',
          p: '支持AX、AXB、AXYB、CTD、AXB组拨等多种业务模式，灵活服务客户业务需求。',
        },
        {
          span: '小号实施经验丰富',
          p: '百悟科技作为滴滴出行等互联网平台最大的服务商拥有丰富经验，可快速应对处理突发问题。',
        },
        {
          span: '平台稳定性高',
          p: '全国唯一日呼叫量超过千万级的安全隐私号平台，网络稳定充分保障高并发通话。',
        },
      ],
      part3Datas: [],
      // 轮播图参数
      swiperOption: {
        pagination: {
          el: '.p2_swiper .p2m-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false, // 手动切换之后继续自动轮播
        // },
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      swiperOption2: {
        pagination: {
          el: '.p3-swiper .p3m-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
    };
  },
  mounted() {
    $('.part2-item').eq(0).addClass('reveal-bottom');
    $('.part2-item').eq(1).addClass('reveal-bottom2');
    $('.part2-item').eq(2).addClass('reveal-bottom3');
    // 内容初始化
    this.part3Datas = [
      JSON.parse(JSON.stringify(this.part3Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.part3Data.slice(-2))),
    ];
  },
  methods: {
    part2ItemMove(img, index) {
      $('.part2-item').removeClass('active');
      $('.part2-item').eq(index).addClass('active');
      $('.part2-item').eq(index).find('img').attr('src', img);
    },
    part2ItemOut(img, index) {
      $('.part2-item').removeClass('active');
      $('.part2-item').eq(index).find('img').attr('src', img);
    },
    // 移动端
    swiperP2Item(e, item) {
      $('.p2_swiper .part2-item').each((index, item2) => {
        this.part2Data.find((item3) => {
          if (item3.p == $(item2).children('p').text()) {
            $(item2).find('img').attr('src', item3.img);
          }
        });
      });
      $('.p2_swiper .part2-item').removeClass('active');
      $(e.currentTarget).addClass('active');
      $(e.currentTarget).find('img').attr('src', item.imgOver);
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      width: 781px;
      height: 588px;
      margin-right: -194px;
      margin-top: -62px;
      z-index: -1;
    }
  }
  .part1-title {
    width: 480px;
    height: 280px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 78px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
/* 第二部分 */
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 761px;
  background: url('../../assets/img/product/serve95/part2-bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 81px;
  .part-title {
    color: #ffffff;
    margin-bottom: 98px;
  }
  .part2_main {
    width: 1068px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 338px);
    grid-template-rows: 391px;
    grid-column-gap: 37px;
    .part2-item {
      background: #ffffff;
      box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 51px;
      div {
        height: 128px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 50px;
      }
      p {
        display: block;
        width: 268px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 27px;
        text-align: justify;
      }
    }
    .part2-item.active {
      background: linear-gradient(-24deg, #0c63bb 0%, #6392cd 100%);
      border: 2px solid #ffffff;
      box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
      border-radius: 10px;
      span {
        color: #ffffff;
      }
      p {
        color: #ffffff;
      }
    }
  }
  .part2_main:nth-of-type(2) {
    display: none;
  }
}
/* 第三部分 */
.part3 {
  box-sizing: border-box;
  width: 100%;
  height: 964px;
  padding-top: 135px;
  .part-title {
    margin-bottom: 81px;
  }
  .p3-pc {
    width: 1154px;
    margin: 0 auto;
    position: relative;
    > img:nth-of-type(1) {
      width: 1450px;
      position: absolute;
      top: -500px;
      left: -150px;
      z-index: -1;
    }
    > img:nth-of-type(2) {
      position: absolute;
      top: -130px;
      right: -60px;
      z-index: 1;
    }
    > img:nth-of-type(3) {
      position: absolute;
      top: 430px;
      left: -170px;
      z-index: -2;
    }
    .p3m-among {
      box-sizing: border-box;
      width: 312px;
      margin: 0 auto;
      height: 309px;
      background: url('../../assets/img/product/serve95/part3-among-bg.png')
        no-repeat;
      background-size: 100% 100%;
      padding-top: 62px;
      position: relative;
      .p3m-circle {
        box-sizing: border-box;
        width: 186px;
        margin: 0 auto;
        height: 186px;
        background: linear-gradient(260deg, #316ebc 0%, #005cb9 100%);
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 31px;
        img {
          margin-bottom: 12px;
        }
        span {
          font-size: 24px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #ffffff;
        }
      }
      > img {
        position: absolute;
      }
      > img:nth-last-of-type(4) {
        top: calc(50% - 6.5px);
        left: -6.5px;
      }
      > img:nth-last-of-type(3) {
        bottom: 28px;
        left: 53px;
      }
      > img:nth-last-of-type(2) {
        bottom: 28px;
        right: 51px;
      }
      > img:nth-last-of-type(1) {
        top: calc(50% - 6.5px);
        right: -6.5px;
      }
    }
    .part3-item {
      width: 270px;
      height: 170px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      span {
        display: block;
        width: 244px;
        height: 59px;
        background: linear-gradient(-3deg, #0c63bb 0%, #6392cd 100%);
        border-radius: 28px;
        font-size: 20px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #fbfbfd;
        text-align: center;
        line-height: 59px;
        margin-bottom: 27px;
        position: relative;
      }
      span::after {
        display: block;
        content: '';
        width: 140px;
        height: 1px;
        background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
        position: absolute;
        top: 30px;
        left: 100%;
      }
      p {
        display: block;
        width: 264px;
        font-size: 16px;
        text-align: justify;
        font-weight: 400;
        color: #666666;
      }
    }
    .part3-item:nth-last-of-type(4) {
      top: 124px;
      left: 3px;
    }
    .part3-item:nth-last-of-type(3) {
      top: 395px;
      left: 93px;
    }
    .part3-item:nth-last-of-type(3) span::after {
      width: 160px;
      top: -49px;
      left: 85%;
      transform: rotate(141deg);
    }
    .part3-item:nth-last-of-type(2) {
      top: 395px;
      left: 786px;
    }
    .part3-item:nth-last-of-type(2) span::after {
      width: 160px;
      top: -49px;
      left: -50%;
      transform: rotate(39deg);
    }
    .part3-item:nth-last-of-type(1) {
      top: 124px;
      left: 882px;
    }
    .part3-item:nth-last-of-type(1) span::after {
      top: 30px;
      left: -57%;
    }
  }
  .part3-main:nth-of-type(2) {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  /* 第一部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        width: 4.84rem;
        height: 3.68rem;
        margin-right: -0.67rem;
        margin-top: 0rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0rem;
        margin-top: -0.61rem;
      }
      p,
      a {
        display: none;
      }
    }
  }
  /* 第二部分 */
  .part2 {
    height: 6.11rem;
    background: url('../../assets/img/product/hideConnect/mobile/part2-bg.png')
      no-repeat;
    background-size: 100% 100%;
    padding-top: 0.32rem;
    .part-title {
      margin-bottom: 0.4rem;
    }
    .part2_main {
      width: 6.5rem;
      .part2-item {
        box-sizing: border-box;
        width: 3.38rem;
        margin: 0 auto;
        height: 3.91rem;
        box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.1);
        border-radius: 0.1rem;
        padding-top: 0.58rem;
        div {
          height: 1.12rem;
          margin-bottom: 0.49rem;
          img {
            height: 1.12rem;
          }
        }
        p {
          width: 2.68rem;
          height: 0.73rem;
          font-size: 0.16rem;
          line-height: 0.27rem;
        }
      }
      .part2-item.active {
        border: 0.02rem solid #ffffff;
        box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.1);
        border-radius: 0.1rem;
      }
    }
    .part2_main:nth-of-type(1) {
      display: none;
    }
    .part2_main:nth-of-type(2) {
      display: block;
    }
  }
  /* 第三部分 */
  .part3 {
    height: 5.68rem;
    padding-top: 0.6rem;
    background: url('../../assets/img/product/hideConnect/mobile/part3-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 0.59rem;
    }
    .p3-swiper {
      width: 6.56rem;
      margin: 0 auto;
      height: auto;
      background: none;
      padding-top: 0px;
      margin-top: 0px;
      .swiper-item {
        box-sizing: border-box;
        width: 100%;
        padding: 0 0.35rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .part3-item {
          box-sizing: border-box;
          width: 2.76rem;
          height: 2.93rem;
          background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
          border-radius: 0.1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 0.37rem;
          position: initial;
          span {
            font-size: 0.18rem;
            font-family: FontExcellent;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 0.3rem;
          }
          p {
            display: block;
            width: 2rem;
            font-size: 0.16rem;
            line-height: 0.28rem;
            font-weight: 400;
            color: #ffffff;
            text-align: justify;
          }
        }
      }
    }
    .part3-main:nth-of-type(1) {
      display: none;
    }
    .part3-main:nth-of-type(2) {
      display: block;
    }
  }
}
</style>
<style lang="less">
// 轮播图
// p2
.HideConnect {
  .swiper_bg_blue {
    width: 6.5rem;
    margin-top: 0.36rem;
  }
}
</style>
