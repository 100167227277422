<template>
  <div class="voiceCode">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>语音验证码</span>
          <p>
            通过软件识别网站验证码，在电脑上通过TTS语音播放给用户听到的一种方式。另外一种则是现在比较少有的通过语音电话直接呼到用户手机，实现电话语音播报的方式。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/voiceCode/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <span class="part-title">语音验证码应用场景</span>
      <div class="part2_main">
        <div class="part2-item reveal-bottom">
          <div
            class="min-item"
            v-for="(item, index) in part2Data.slice(0, 3)"
            :key="index"
            @click="minItemOn(item)"
          >
            <span>{{ item.span }}</span>
          </div>
        </div>
        <div class="part2-itemTwo">
          <div
            class="min-itemTwo"
            v-for="(item, index) in part2Data"
            :key="index"
            @click="minItemOn(item)"
          >
            <span>{{ item.span }}</span>
          </div>
        </div>
        <div class="item-img reveal-bottom2">
          <img
            src="../../assets/img/product/voiceCode/part2-main-icon.png"
            alt=""
          />
          <div>
            <img
              src="../../assets/img/product/voiceCode/part2-icon.png"
              alt=""
            />
            <p>{{ part2Input }}</p>
          </div>
        </div>
        <div class="part2-item reveal-bottom3">
          <div
            class="min-item"
            v-for="(item, index) in part2Data.slice(3, 6)"
            :key="index"
            @click="minItemOn(item)"
          >
            <span>{{ item.span }}</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      part2Data: [
        {
          index: 0,
          span: '用户注册',
          p: '尊敬的用户，感谢您注册本网站，您的验证码是：5012，请在5分钟内输入。',
        },
        {
          index: 1,
          span: '网络订单通知',
          p: '尊敬的用户，您在我们网站成功下单。订单号：XXX，收获验证码为：4019。请保管好您的验证码，以便验证。',
        },
        {
          index: 2,
          span: '手机下载短信优惠券',
          p: '尊敬的用户，您的优惠券验证码为：4066，请尽快登录领取。',
        },
        {
          index: 3,
          span: 'APP应用手机验证',
          p: '尊敬的用户，您的证码为：7065，如非本人操作请忽略。',
        },
        {
          index: 4,
          span: '在线支付验证',
          p: '您本次在XX网付款的验证码：8888，任何人索取验证码均为诈骗，请勿泄露！此验证码会在3分钟后失效。',
        },
        {
          index: 5,
          span: '物流提醒手机验证',
          p: '尊敬的XX客户，您的商品准备上门服务，为了保证您的货品安全，收货验证码;5566，非本人收货请将此码转给收货人并在收货后奖此码提供给送货人员，感谢您对XX的支持！',
        },
      ],
      part2Input:
        '尊敬的用户，感谢您注册本网站，您的验证码是：5012，请在5分钟内输入。',
    };
  },
  mounted() {
    $('.min-item').eq(0).addClass('active');
    $('.min-itemTwo').eq(0).addClass('active');
  },
  methods: {
    minItemOn(item) {
      this.part2Input = item.p;
      $('.min-item').removeClass('active');
      $('.min-itemTwo').removeClass('active');
      $('.min-item').eq(item.index).addClass('active');
      $('.min-itemTwo').eq(item.index).addClass('active');
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      width: 498px;
      height: 589px;
      margin-right: 4px;
      margin-top: 32px;
      z-index: -1;
    }
  }
  .part1-title {
    width: 480px;
    height: 280px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 78px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
// 第二部分
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 958px;
  background: url('../../assets/img/product/voiceCode/part2-bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 47px;
  .part-title {
    margin-bottom: 115px;
  }
  .part2_main {
    width: 1109px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    .part2-item {
      width: 272px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 144px;
      .min-item {
        width: 272px;
        height: 81px;
        background: #ffffff;
        box-shadow: 0px 16px 7px 0px rgba(16, 72, 133, 0.02);
        border-radius: 28px;
        font-size: 18px;
        font-weight: 400;
        color: #666666;
        text-align: center;
        line-height: 81px;
        margin-bottom: 90px;
        cursor: pointer;
      }
      .min-item.active {
        background: linear-gradient(141deg, #6c97ce 0%, #005cb9 100%);
        box-shadow: 0px 16px 7px 0px rgba(16, 72, 133, 0.02);
        border-radius: 28px;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
      }
    }
    .part2-item:first-child {
      margin-right: 110px;
    }
    .part2-item:last-child {
      margin-left: 123px;
    }
    .item-img {
      width: 332px;
      position: relative;
      div {
        box-sizing: border-box;
        width: 251px;
        background: linear-gradient(-50deg, #6c97ce 0%, #005cb9 100%);
        box-shadow: 0px 16px 7px 0px rgba(16, 72, 133, 0.02);
        opacity: 0.96;
        border-radius: 12px;
        position: absolute;
        top: 170px;
        left: 130px;
        display: flex;
        flex-direction: row;
        padding: 15px 24px 15px 16px;
        img {
          width: 20px;
          height: 29px;
          margin-right: 10px;
          margin-top: 5px;
        }
        p {
          display: block;
          width: 180px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          line-height: 18px;
          text-align: justify;
        }
      }
    }
    .part2-itemTwo {
      display: none;
    }
  }
}
@media screen and (max-width: 768px) {
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        width: 3.55rem;
        height: 4.2rem;
        margin-right: -0.59rem;
        margin-top: 0rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0rem;
        margin-top: -0.8rem;
      }
      p,
      a {
        display: none;
      }
    }
  }
  // 第二部分
  .part2 {
    height: 8.51rem;
    background: url('../../assets/img/product/voiceCode/part2-bg.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 0.29rem;
    .part-title {
      margin-bottom: 0.66rem;
    }
    .part2_main {
      width: 6.3rem;
      justify-content: space-between;
      .part2-itemTwo {
        width: 2.72rem;
        padding-top: 0.14rem;
        display: block;
        .min-itemTwo {
          width: 2.72rem;
          height: 0.81rem;
          background: #ffffff;
          box-shadow: 0rem 0.16rem 0.07rem 0rem rgba(16, 72, 133, 0.02);
          border-radius: 0.28rem;
          font-size: 0.18rem;
          font-weight: 400;
          color: #666666;
          text-align: center;
          line-height: 0.81rem;
          margin-bottom: 0.22rem;
          cursor: pointer;
        }
        .min-itemTwo.active {
          background: linear-gradient(141deg, #6c97ce 0%, #005cb9 100%);
          box-shadow: 0rem 0.16rem 0.07rem 0rem rgba(16, 72, 133, 0.02);
          border-radius: 0.28rem;
          font-size: 0.18rem;
          font-weight: bold;
          color: #ffffff;
        }
      }
      .item-img {
        width: 3.24rem;
        position: relative;
        > img {
          width: 100%;
        }
        div {
          box-sizing: border-box;
          width: 2.51rem;
          background: linear-gradient(-50deg, #6c97ce 0%, #005cb9 100%);
          box-shadow: 0rem 0.16rem 0.07rem 0rem rgba(16, 72, 133, 0.02);
          opacity: 0.96;
          border-radius: 0.12rem;
          position: absolute;
          top: 1.54rem;
          left: 0.48rem;
          display: flex;
          flex-direction: row;
          padding: 0.15rem 0.24rem 0.15rem 0.16rem;
          img {
            width: 0.2rem;
            height: 0.29rem;
            margin-right: 0.1rem;
            margin-top: 0.05rem;
          }
          p {
            display: block;
            width: 1.8rem;
            font-size: 0.12rem;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.18rem;
            text-align: justify;
          }
        }
      }
      .part2-item {
        display: none;
      }
    }
  }
}
</style>
