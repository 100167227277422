<template>
  <div class="callPlatform">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>固话外呼平台</span>
          <p>
            中继线固话外呼平台，汇聚全国的通信码号资源，将运营商的语音、码号等云化和API化<br />相当于一个全国的通信网+一个能力云化的平台
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/product/fixedCall/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <span class="part-title">固化外呼平台功能介绍</span>
      <div class="part2_main">
        <div class="part2-left reveal-left">
          <div
            class="part2-item"
            v-for="(item, index) in part2Data"
            :key="index"
            @click="onP2Item(item, index)"
          >
            <p>{{ item.p }}</p>
          </div>
        </div>
        <img class="reveal-right" :src="part2Bg" alt="" />
      </div>
      <div class="part2_main fixed-p2-swiper">
        <swiper :options="swiperOption">
          <swiper-slide
            v-for="(item, i) in part2Datas"
            :key="i"
            class="swiper-item"
          >
            <div class="part2-item" v-for="(item2, i2) in item" :key="i2">
              <div>
                <img :src="item2.img" alt="" />
              </div>
              <p>{{ item2.p }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-pagination p2m-pagination swiper_bg_blue"
          slot="pagination"
        ></div>
      </div>
    </section>
    <section class="part3">
      <span class="part-title">固化外呼平台特殊优势</span>
      <div class="part3_main">
        <div class="p3-container reveal-null">
          <img
            src="../../assets/img/product/serve95/part3-amongIn-icon.png"
            alt=""
          />
          <span>特殊优势</span>
        </div>
        <div class="p3-item reveal-bottom"><span>高效直达</span></div>
        <div class="p3-item reveal-bottom2">
          <span>对外统一<br />号码</span>
        </div>
        <div class="p3-item reveal-bottom3">
          <span>一点接入<br />全国组网</span>
        </div>
        <div class="p3-item reveal-bottom4"><span>承载网对接</span></div>
        <div class="p3-item reveal-bottom3"><span>IVR语音</span></div>
        <div class="p3-item reveal-bottom2"><span>智能排队</span></div>
        <div class="p3-item reveal-bottom"><span>稳定保障</span></div>
      </div>
      <div class="part3_main p3-swiper">
        <swiper :options="swiperOption2">
          <swiper-slide
            v-for="(item, i) in part3Datas"
            :key="i"
            class="swiper-item"
          >
            <div class="part3-item" v-for="(item2, i2) in item" :key="i2">
              <span>{{ item2.span }}</span>
              <p>{{ item2.p }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-pagination p3m-pagination swiper_bg_white"
          slot="pagination"
        ></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      part2Data: [
        {
          p: '百悟语音中心基地，分南北两大语音基地——北京语音中心基地和广州语音中心基地，基地具有支撑10000并发处理能力。',
          img: require('../../assets/img/product/fixedCall/part2-icon1.png'),
        },
        {
          p: '核心设备SBC 采用HA方式进行容灾，单台设备异常，可以实现设备自动切换到另一台设备，不会影响语音业务的正常运行。',
          img: require('../../assets/img/product/fixedCall/part2-icon2.png'),
        },
        {
          p: '百悟语音外呼平台可支持SIP对接，优先使用公网接入（上线周期短），如有特殊要求也可以拉专线接入（不推荐）。',
          img: require('../../assets/img/product/fixedCall/part2-icon3.png'),
        },
        {
          p: '百悟语音外呼平台提供如下功能：话务整合、话单查询、录音查询、黑名单过滤、报表查.',
          img: require('../../assets/img/product/fixedCall/part2-icon4.png'),
        },
      ],
      part2Bg: require('../../assets/img/product/fixedCall/part2-icon1.png'),
      part2Datas: [],
      part3Data: [
        {
          span: '高效直达',
          p: '国内运营商三网接入、优质资源通道，通话清晰稳定，拨号无延时，直达客户沟通触点',
        },
        {
          span: '对外统一号码',
          p: '每一个通道一个号码，一个号码里包含多条线路对外显示一个统一的号码，大大提升企业形象',
        },
        {
          span: '一点接入全国组网',
          p: '客户与平台对接后，无需再去各地运营商开传统中继电路即可实现本地化通话',
        },
        {
          span: '承载网对接',
          p: '平台与全国集团固定网络通过运营商专用承载网实现对接',
        },
        {
          span: 'IVR语音',
          p: '电话接入之后，可以听到简单的IVR语音，弥补交换机不足',
        },
        {
          span: '智能排队',
          p: '用户呼入时，系统可根据线路的忙闲状态。按照规则进行跳转和分配，接入不同的座席',
        },
        {
          span: '稳定保障',
          p: '网络故障排查达到运营商骨于网级别保障',
        },
      ],
      part3Datas: [],
      // 轮播图参数
      swiperOption: {
        pagination: {
          el: '.fixed-p2-swiper .p2m-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false, // 手动切换之后继续自动轮播
        // },
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      swiperOption2: {
        pagination: {
          el: '.p3-swiper .p3m-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false, // 手动切换之后继续自动轮播
        // },
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
    };
  },
  mounted() {
    $('.part2-item').eq(0).addClass('active');
    // 内容初始化
    this.part2Datas = [
      JSON.parse(JSON.stringify(this.part2Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.part2Data.slice(-2))),
    ];
    // 内容初始化
    this.part3Datas = [
      JSON.parse(JSON.stringify(this.part3Data.slice(0, 3))),
      JSON.parse(JSON.stringify(this.part3Data.slice(3, 6))),
      JSON.parse(JSON.stringify(this.part3Data.slice(-1))),
    ];
  },
  methods: {
    onP2Item(item, index) {
      $('.part2-item').removeClass('active');
      $('.part2-item').eq(index).addClass('active');
      this.part2Bg = item.img;
    },
  },
};
</script>

<style lang="less" scoped>
/* 头图样式 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 715px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      width: 681px;
      height: 612px;
      margin-right: -115px;
      margin-top: -40px;
      z-index: -1;
    }
  }
  .part1-title {
    width: 480px;
    height: 280px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 78px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part2 {
  box-sizing: border-box;
  width: 100%;
  height: 852px;
  padding-top: 77px;
  .part-title {
    margin-bottom: 130px;
  }
  .part2_main {
    width: 1348px;
    margin: 0 auto;
    padding-left: 300px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .part2-left {
      display: grid;
      grid-template-columns: 346px 346px;
      grid-template-rows: 180px 180px;
      column-gap: 32px;
      row-gap: 52px;
      margin-right: 74px;
      .part2-item {
        background: #ffffff;
        box-shadow: 11px 4px 27px 0px rgba(122, 130, 148, 0.03);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        p {
          display: block;
          width: 288px;
          text-align: justify;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
        }
      }
      .part2-item.active {
        background: #1b6abb;
        p {
          color: #ffffff;
        }
      }
    }
    > img {
      margin-bottom: 22px;
    }
  }
  .part2_main:nth-of-type(2) {
    display: none;
  }
}
.part3 {
  width: 100%;
  height: 1195px;
  background: linear-gradient(-88deg, #ffffff 0%, #fafbfd 52%, #ffffff 100%);
  padding-top: 123px;
  .part-title {
    margin-bottom: 60px;
  }
  .part3_main {
    box-sizing: border-box;
    width: 1003px;
    height: 1003px;
    margin: 0 auto;
    background: url('../../assets/img/product/fixedCall/part3-bg.png') no-repeat
      center center;
    padding-top: 485px;
    position: relative;
    margin-top: -199px;
    .p3-container {
      box-sizing: border-box;
      width: 186px;
      margin-left: 392px;
      height: 186px;
      background: linear-gradient(260deg, #316ebc 0%, #005cb9 100%);
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-family: FontExcellent;
      font-weight: 400;
      color: #ffffff;
      padding-top: 28px;
      img {
        margin-bottom: 3px;
      }
    }
    .p3-item {
      width: 133px;
      height: 133px;
      background: linear-gradient(260deg, #316ebc 0%, #005cb9 100%);
      border-radius: 50%;
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      span {
        font-size: 24px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
        text-align: center;
        position: relative;
      }
      span::after {
        display: block;
        content: '国内运营商三网接入、优质资源通道，通话清晰稳定，拨号无延时，直达客户沟通触点';
        width: 226px;
        text-align: center;
        position: absolute;
        top: 96px;
        left: -62px;
        font-size: 16px;
        font-family: none;
        font-weight: 400;
        color: #666666;
      }
    }
    .p3-item:nth-last-of-type(7) {
      top: 30%;
      left: -50px;
    }
    .p3-item:nth-last-of-type(6) {
      top: 60%;
      left: -35px;
    }
    .p3-item:nth-last-of-type(6) span::after {
      content: '每一个通道一个号码，一个号码\A里包含多条线路\A对外显示一个统一的号码，大大\A提升企业形象';
      white-space: pre;
      top: 108px;
    }
    .p3-item:nth-last-of-type(5) {
      top: 82%;
      left: 130px;
    }
    .p3-item:nth-last-of-type(5) span::after {
      content: '客户与平台对接后，无需再去各\A地运营商开传统\A中继电路即可实现本地化通话';
      white-space: pre;
      top: 108px;
    }
    .p3-item:nth-last-of-type(4) {
      top: 93%;
      left: 420px;
    }
    .p3-item:nth-last-of-type(4) span::after {
      content: '平台与全国集团固定网络通过运营商专用承载网实现对接';
    }
    .p3-item:nth-last-of-type(3) {
      top: 82%;
      right: 122px;
    }
    .p3-item:nth-last-of-type(3) span::after {
      content: '电话接入之后，可以听到简单的IVR语音，弥补交换机不足';
    }
    .p3-item:nth-last-of-type(2) {
      top: 60%;
      right: -35px;
    }
    .p3-item:nth-last-of-type(2) span::after {
      content: '用户呼入时，系统可根据线路的忙闲状态。按照规则进行跳转和分配，接入不同的座席';
    }
    .p3-item:nth-last-of-type(1) {
      top: 30%;
      right: -50px;
    }
    .p3-item:nth-last-of-type(1) span::after {
      content: '网络故障排查达到运营商骨于网级别保障';
    }
  }
  .part3_main:nth-of-type(2) {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        width: 4.37rem;
        height: 4.01rem;
        margin-right: -0.69rem;
        margin-top: 0.01rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
        margin-top: -0.63rem;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part2 {
    height: 6.1rem;
    padding-top: 0.31rem;
    background: url('../../assets/img/product/fixedCall/mobile/part2-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      color: #ffffff;
      margin-bottom: 0.45rem;
    }
    .part2_main {
      width: 5.68rem;
      padding-left: 0rem;
      .swiper-item {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0rem 0.2rem;
      }
      .part2-item {
        box-sizing: border-box;
        width: 2.5rem;
        height: 4rem;
        background-color: white;
        box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.1);
        border-radius: 0.1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0.41rem;
        div {
          height: 1.33rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 0.15rem;
          img {
            height: 1.33rem;
          }
        }
        p {
          width: 1.73rem;
          font-size: 0.16rem;
          line-height: 0.28rem;
          text-align: justify;
        }
      }
      .part2-item.active {
        background: #1b6abb;
        p {
          color: #ffffff;
        }
      }
    }
    .part2_main:nth-of-type(1) {
      display: none;
    }
    .part2_main:nth-of-type(2) {
      display: block;
    }
  }
  .part3 {
    height: 5.13rem;
    background: url('../../assets/img/product/fixedCall/mobile/part3-bg.png')
      no-repeat;
    background-size: 100% 100%;
    padding-top: 0.6rem;
    .part-title {
      margin-bottom: 0.61rem;
    }
    .part3_main {
      width: 6.56rem;
      height: auto;
      background: none;
      padding-top: 0px;
      margin-top: 0px;
      .swiper-item {
        box-sizing: border-box;
        width: 100%;
        padding: 0 0.2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .part3-item {
          box-sizing: border-box;
          width: 1.98rem;
          height: 2.93rem;
          background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
          border-radius: 0.1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 0.35rem;
          span {
            font-size: 0.24rem;
            font-family: FontExcellent;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 0.3rem;
          }
          p {
            display: block;
            width: 1.23rem;
            font-size: 0.16rem;
            font-weight: 400;
            color: #ffffff;
            text-align: justify;
          }
        }
      }
      .swiper-item:nth-of-type(3) {
        justify-content: center;
      }
    }
    .part3_main:nth-of-type(1) {
      display: none;
    }
    .part3_main:nth-of-type(2) {
      display: block;
    }
  }
}
</style>
<style lang="less">
// 轮播图
// p2
.fixed-p2-swiper .swiper_bg_blue {
  width: 5.68rem;
  margin-top: 0.43rem;
}
// p3
.p3-swiper .swiper_bg_white {
  width: 6.56rem;
  margin-top: 0.55rem;
}
</style>
