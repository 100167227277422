<template>
  <section class="partAdvantage">
    <span
      v-if="pAdvantageTitle"
      class="part-title"
      v-text="pAdvantageTitle"
    ></span>
    <span v-else class="part-title">解决方案优势</span>
    <div class="partAdvantage-main pAdvantage-pc">
      <img src="../../assets/img/product/serve95/part3-bg.png" alt="" />
      <img src="../../assets/img/product/serve95/part3-bg-icon1.png" alt="" />
      <img src="../../assets/img/product/serve95/part3-bg-icon2.png" alt="" />
      <div class="pAdvantageM-among reveal-null">
        <div class="pAdvantageM-circle">
          <img
            src="../../assets/img/product/serve95/part3-amongIn-icon.png"
            alt=""
          />
          <span>解决方案优势</span>
        </div>
        <img
          class="reveal-null3"
          src="../../assets/img/product/serve95/part3-among-icon.png"
          alt=""
        />
        <img
          class="reveal-null4"
          src="../../assets/img/product/serve95/part3-among-icon.png"
          alt=""
        />
        <img
          class="reveal-null4"
          src="../../assets/img/product/serve95/part3-among-icon.png"
          alt=""
        />
        <img
          class="reveal-null3"
          src="../../assets/img/product/serve95/part3-among-icon.png"
          alt=""
        />
      </div>
      <div
        class="partAdvantage-item"
        v-for="(item, i) in pAdvantageData"
        :key="i"
      >
        <span v-text="item.span"></span>
        <p v-text="item.p"></p>
      </div>
    </div>
    <div class="partAdvantage-main pAdvantage-mobile">
      <swiper :options="swiperOption2">
        <swiper-slide
          v-for="(item, i) in pAdvantageDatas"
          :key="i"
          class="swiper-item"
        >
          <div class="partAdvantage-item" v-for="(item2, i2) in item" :key="i2">
            <span>{{ item2.span }}</span>
            <p>{{ item2.p }}</p>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination swiper_bg_white" slot="pagination"></div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      swiperOption2: {
        pagination: {
          el: '.pAdvantage-mobile .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
    };
  },
  props: ['pAdvantageData', 'pAdvantageDatas', 'pAdvantageTitle'],
  mounted() {
    $('.partAdvantage-item').eq(0).addClass('reveal-null3');
    $('.partAdvantage-item').eq(3).addClass('reveal-null3');
    $('.partAdvantage-item').eq(1).addClass('reveal-null4');
    $('.partAdvantage-item').eq(2).addClass('reveal-null4');
  },
};
</script>

<style lang="less" scoped>
.partAdvantage {
  box-sizing: border-box;
  width: 100%;
  height: 964px;
  padding-top: 87px;
  background: linear-gradient(-88deg, #ffffff 0%, #fafbfd 52%, #ffffff 100%);
  overflow: hidden;
  .part-title {
    margin-bottom: 81px;
  }
  .pAdvantage-pc {
    width: 1154px;
    margin: 0 auto;
    position: relative;
    > img:nth-of-type(1) {
      width: 1450px;
      position: absolute;
      top: -500px;
      left: -150px;
      z-index: 0;
    }
    > img:nth-of-type(2) {
      position: absolute;
      top: -130px;
      right: -60px;
      z-index: 0;
    }
    > img:nth-of-type(3) {
      position: absolute;
      top: 430px;
      left: -170px;
      z-index: 0;
    }
    .pAdvantageM-among {
      box-sizing: border-box;
      width: 312px;
      margin: 0 auto;
      height: 309px;
      background: url('../../assets/img/product/serve95/part3-among-bg.png')
        no-repeat;
      background-size: 100% 100%;
      padding-top: 62px;
      position: relative;
      .pAdvantageM-circle {
        box-sizing: border-box;
        width: 186px;
        margin: 0 auto;
        height: 186px;
        background: linear-gradient(260deg, #316ebc 0%, #005cb9 100%);
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 31px;
        img {
          margin-bottom: 2px;
        }
        span {
          font-size: 24px;
          font-family: FontExcellent;
          font-weight: 400;
          color: #ffffff;
        }
      }
      > img {
        position: absolute;
      }
      > img:nth-last-of-type(4) {
        top: calc(50% - 6.5px);
        left: -6.5px;
      }
      > img:nth-last-of-type(3) {
        bottom: 28px;
        left: 53px;
      }
      > img:nth-last-of-type(2) {
        bottom: 28px;
        right: 51px;
      }
      > img:nth-last-of-type(1) {
        top: calc(50% - 6.5px);
        right: -6.5px;
      }
    }
    .partAdvantage-item {
      width: 270px;
      height: 170px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      span {
        display: block;
        width: 244px;
        height: 59px;
        background: linear-gradient(-3deg, #0c63bb 0%, #6392cd 100%);
        border-radius: 28px;
        font-size: 20px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #fbfbfd;
        text-align: center;
        line-height: 59px;
        margin-bottom: 27px;
        position: relative;
      }
      span::after {
        display: block;
        content: '';
        width: 140px;
        height: 1px;
        background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
        position: absolute;
        top: 30px;
        left: 100%;
      }
      p {
        display: block;
        width: 285px;
        font-size: 16px;
        text-align: justify;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
    }
    .partAdvantage-item:nth-last-of-type(4) {
      top: 124px;
      left: 3px;
    }
    .partAdvantage-item:nth-last-of-type(3) {
      top: 395px;
      left: 93px;
    }
    .partAdvantage-item:nth-last-of-type(3) span::after {
      width: 160px;
      top: -49px;
      left: 85%;
      transform: rotate(141deg);
    }
    .partAdvantage-item:nth-last-of-type(2) {
      top: 395px;
      left: 786px;
    }
    .partAdvantage-item:nth-last-of-type(2) span::after {
      width: 160px;
      top: -49px;
      left: -50%;
      transform: rotate(39deg);
    }
    .partAdvantage-item:nth-last-of-type(1) {
      top: 124px;
      left: 882px;
    }
    .partAdvantage-item:nth-last-of-type(1) span::after {
      top: 30px;
      left: -57%;
    }
  }
  .partAdvantage-main:nth-of-type(2) {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .partAdvantage {
    height: 5.91rem;
    padding-top: 0.6rem;
    background: url('../../assets/img/solution/mobileInternet/mobile/part4-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 0.59rem;
    }
    .pAdvantage-mobile {
      width: 6.56rem;
      margin: 0 auto;
      height: auto;
      background: none;
      padding-top: 0px;
      margin-top: 0px;
      .swiper-item {
        box-sizing: border-box;
        width: 100%;
        padding: 0 0.35rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .partAdvantage-item {
          box-sizing: border-box;
          width: 2.76rem;
          height: 3.25rem;
          background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
          border-radius: 0.1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 0.4rem;
          position: initial;
          span {
            font-size: 0.21rem;
            font-family: FontExcellent;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 0.19rem;
          }
          p {
            display: block;
            width: 2.07rem;
            font-size: 0.17rem;
            line-height: 0.28rem;
            font-weight: 400;
            color: #ffffff;
            text-align: justify;
          }
        }
      }
    }
    .partAdvantage-main:nth-of-type(1) {
      display: none;
    }
    .partAdvantage-main:nth-of-type(2) {
      display: block;
    }
  }
}
</style>
<style lang="less">
.pAdvantage-mobile .swiper-pagination {
  width: 6.56rem;
  margin-top: 0.42rem;
}
</style>
