var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"partCore"},[_c('span',{staticClass:"part-title"},[_vm._v("核心业务场景")]),_c('div',{staticClass:"pCore_main pCore_m_pc reveal-bottom2",style:({ width: _vm.width })},_vm._l((_vm.pCoreData),function(item,i){return _c('div',{key:i,staticClass:"pCore_item",style:({
        background: 'url(' + item.img + ') no-repeat',
        backgroundSize: '100% 100%',
      }),on:{"mousemove":function($event){return _vm.pCoreMove(i, item.imgOver)}}},[_c('span',{domProps:{"textContent":_vm._s(item.span)}}),_c('p',{domProps:{"textContent":_vm._s(item.p)}})])}),0),_c('div',{staticClass:"pCore_main pCore_m_mobile"},[_c('div',{staticClass:"pCore_m_left",style:({
        background: 'url(' + _vm.pCoreDataItem.imgOver + ') no-repeat',
        height: _vm.height,
        paddingTop: _vm.height == '3rem' ? '1.8rem' : '2.3rem',
      })},[_c('span',{domProps:{"textContent":_vm._s(_vm.pCoreDataItem.span)}}),_c('p',{domProps:{"textContent":_vm._s(_vm.pCoreDataItem.p)}})]),_c('div',{staticClass:"pCore_m_right"},_vm._l((_vm.pCoreData),function(item,i){return _c('div',{key:i,staticClass:"pCore_item2",on:{"click":function($event){return _vm.pCoreClick(i, item.img2, item)}}},[_c('span',{domProps:{"textContent":_vm._s(item.span)}})])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }