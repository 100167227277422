import { render, staticRenderFns } from "./MessageServe.vue?vue&type=template&id=3ea5e11c&scoped=true&"
import script from "./MessageServe.vue?vue&type=script&lang=js&"
export * from "./MessageServe.vue?vue&type=script&lang=js&"
import style0 from "./MessageServe.vue?vue&type=style&index=0&id=3ea5e11c&lang=less&scoped=true&"
import style1 from "./MessageServe.vue?vue&type=style&index=1&id=3ea5e11c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ea5e11c",
  null
  
)

export default component.exports