import { render, staticRenderFns } from "./VoiceCode.vue?vue&type=template&id=df036134&scoped=true&"
import script from "./VoiceCode.vue?vue&type=script&lang=js&"
export * from "./VoiceCode.vue?vue&type=script&lang=js&"
import style0 from "./VoiceCode.vue?vue&type=style&index=0&id=df036134&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df036134",
  null
  
)

export default component.exports